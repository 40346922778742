import React, { Component } from 'react';
import icons from './img/icons';

import './scss/combobox.scss';

export class ComboBox extends Component
{
    state = {
        collapsed: true
    }

    render()  {
        let {options, value, onChange} = this.props;

        return <div className="ComboBox">
            <div className={"ComboBox__field" + (!this.state.collapsed ? " extended" : "")}
                onClick={()=> this.setState({collapsed: false})}>
                <img alt="sortIcon" height="16px" src={icons.sort}></img>
                <div className="value">
                    <span>{ value.Label }</span>
                    <img alt="collapseIcon" src={icons.downArrow}></img>
                </div>
            </div>

            { (!this.state.collapsed) &&
            <div>
                <div className="ComboBox__close"
                onClick={()=> this.setState({collapsed: true})}></div>
                <div className="ComboBox__list">
                    {
                        options.map(o => 
                            <div className={"ComboBox__list__item" + (value.Key === o.Key ? " active" : "")}
                            onClick={() => 
                                {
                                    onChange(o);
                                    this.setState({collapsed: true})
                                }}>{o.Label}</div>
                        )
                    }
                </div>
            </div>
            }
        </div>
    }
}