import * as constants from '../constants';
import * as Api from '../../api';
import * as selectors from '../../store/selectors';
import {trackError, preloadStarted, preloadFinished} from './app';


const fetchShapesStarted = () => ({
  type: constants.FETCH_SHAPES_STARTED,
});
const fetchShapesSuccess = (shapes) => ({
  type: constants.FETCH_SHAPES_SUCCESS,
  payload: shapes
});
export const fetchShapes = () => (dispatch, getState) => {
  if(selectors.isFetchingShapes(getState())===true) {
    return;
  }
  dispatch(preloadStarted());
  dispatch(fetchShapesStarted());
  let lang = selectors.getCurrentLanguageCode(getState());
  return Api.getShapes(lang)
  .then((r) => {
    dispatch(preloadFinished());
    return dispatch(fetchShapesSuccess(r));
  })
  .catch(err => dispatch(trackError(err)));
}

