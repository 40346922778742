import * as constants from '../constants';
import * as selectors from '../selectors';

export const favouriteStoreAdvice = (name) => (dispatch, getState) => {
  let advice = selectors.getCurrentAdvice(getState());
  let machineId = selectors.getCurrentMachineId(getState());
  const machine = selectors.getMachine(getState(), machineId);
  let units = selectors.getCurrentUnits(getState());
  
  return dispatch({
    type: constants.FAVOURITE_STORE_ADVICE,
    payload: {advice: { ...advice, name }, machine, units}
  });
};

export const favouriteLoadAdvice = (id) => (dispatch, getState) => {
  let units = selectors.getCurrentUnits(getState());

  let advice = selectors.getFavouriteAdvice(getState(), id, units);
  const machine = selectors.getMachine(getState(), advice.machine);

  if(!machine) {
    dispatch({
      type: constants.FETCH_MACHINE_SUCCESS,
      payload: advice.machineHistory
    });
  }

  return dispatch({
    type: constants.FAVOURITE_LOAD_ADVICE,
    payload: advice
  });
};

export const favouriteRemoveAdvice = (id) => (dispatch) => {
  return dispatch({
    type: constants.FAVOURITE_REMOVE_ADVICE,
    payload: id
  });
};
