import * as constants from '../constants';

const initial = {
  id: null,
  machine: null,
  step: 5,

  workingWidth: 0,
  applicationRate: 0,
  drivingSpeed: 0,
  applicationType: 'NoTopDress',
  validField: false,

  shapeId: null,
  granuleSizeMinus2: 0,
  granuleSizeB23: 0,
  granuleSizeB34: 0,
  granuleSize4: 0,
  adviceDensity: 0,

  applications: null,
  widerSearchCriteriaUsed: false,
  fineDosage: null,
  kitUsed: null,
  applicationId: null,
  vanesetId: null,

  advicePossible: null,
  sharedAdvice: true,
  result: null,
  resultTableX: 0,
  resultTableY: 0,
  resultPdf: null,
  warningTranslationKey: null,
  adviceDrivingSpeed: null,
};


const sharedAdvice = (state = initial, action) => {
  switch (action.type) {
    case constants.FETCH_SHARED_ADVICE_STARTED: {
      return {
        ...state,
        ...action.payload
      }
    }
    case constants.FETCH_SHARED_ADVICE_SUCCESS: {
      let {Advice, AdvicePossible, WarningTranslationKey, AdvicedDrivingSpeed} = action.payload.result;
      let resultTableX = 0;
      let resultTableY = 0;
      let id = new Date().getTime();

      if(AdvicePossible === false) {
        return {
          ...state,
          advicePossible: false,
          warningTranslationKey: WarningTranslationKey,
          adviceDrivingSpeed: AdvicedDrivingSpeed,
        }
      }
      if(Advice) {
        if(Advice.AdvicePossible===false) {
          return Object.assign({}, state, {
            advicePossible: false
          });
        }
        // eslint-disable-next-line no-labels
        outer_loop: {
          const table = Advice.Table;
          if(table) {
            for (let y = 0; y < table.length; y++) {
              for (let x = 0; table[y] && x < table[y].length; x++) {
                if (table[y][x] && table[y][x].Active) {
                  resultTableX = x;
                  resultTableY = y;
                  // eslint-disable-next-line no-labels
                  break outer_loop;
                }
              }
            }
          }
        }
      }
      return Object.assign({}, state, {
        result: Advice && {...Advice},
        baseResultTableX: resultTableX,
        baseResultTableY: resultTableY,
        resultTableX,
        resultTableY,
        id,
        advicePossible: true,
        resultPdf: null
      });
    }
    case constants.FETCH_SHARED_APPLICATIONS_SUCCESS: {
      let VaneSets = null;
      if ('VaneSets' in action.payload) {
        VaneSets = action.payload.VaneSets.map(
          (vs) => {
            if (!('Applications' in vs)) {
              return vs;
            }
            let bm = [];
            let rest = [];
            vs.Applications.forEach((ite) => {
              if (ite.IsBestMatch === true) {
                bm.push(ite)
              } else {
                rest.push(ite)
              }
            })
            vs.Applications = [...bm, ...rest];
            return vs;
          }
        );
      }
      return {
        ...state,
        widerSearchCriteriaUsed: action.payload.WiderSearchCriteriaUsed,
        applications: VaneSets,
        id: null
      };
    }
    case constants.SET_SHARED_TABLE_POSITION: {
      const {x, y} = action.payload;

      return {
        ...state,
        resultTableX: x,
        resultTableY: y,
      };
    }
    case constants.CLEAR_SHARED_RESULT_STATE: {
      return initial;
    }
    default:
      return state;
  }
};

export default sharedAdvice;
