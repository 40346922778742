import React, { Component } from 'react';
import * as selectors from '../store/selectors';
import { fetchVideos } from '../store/actions/videos';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { fetchSpreaderVideos } from '../store/actions/spreadervideos';
import VideoPlayerModal from './videoplayermodal';
import './scss/videolist.scss';
import { secondsToHms } from '../utils';
import icons from './img/icons';

class VideoItem extends Component {
  state = {
    duration: 0
  };

  render() {
    const {id, onClick, url, thumb, desc, width, height, t} = this.props;

    return <div
      className={"video-item"}
      
      onClick={() => onClick && onClick(id, url)}
      
      ga-on="click"
      ga-event-category="Video"
      ga-event-action="play"
      ga-event-label={id}
      style={{
        /* for thumbnails...*/ 

        position: "relative"
      }}
    >
      <div style={{
        transform: "translate(-50%, -50%)",
        transformOrigin: "center",
        position: "absolute",
        left: "50%",
        top: "calc(50% - 11px)",
        fontSize: 16,
        color: "#FFFFFF",
        width: 16,
        height: 16,
      }}>
        <img src={icons.play} alt={""} />
      </div>
      {
        (this.state.duration > 0) &&
        <div style={{
          padding: "2px 6px",
          position: "absolute",
          right: 5,
          top: "62%",
          fontSize: 12,
          fontWeight: 600,
          color: "#FFFF",
          borderRadius: 4,
          backgroundColor: "#000000",
          height: 20,
        }}>
          {secondsToHms(this.state.duration)}
        </div>
      }

      <img src={thumb} className={"video-item-thumb"}
        style={{
          width: width
        }}>
      </img>
      
      <p style={{
        color: "#333338",
        fontSize: 12,
        fontWeight: "bold",
        letterSpacing: 0,
        marginTop: ".35em",
        maxWidth: "100%",
        overflowWrap: "break-word",
      }}>
        {t(desc)}
      </p>
    </div>
  }
}

VideoItem.propTypes = {
  id: PropTypes.string,
  url: PropTypes.string,
  desc: PropTypes.string,
  thumbnail: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.any,
  onClick: PropTypes.func,
  t: PropTypes.any,
};

class VideoList extends Component {
  state = {
    isModalOpen: false,
    selectedVideoUrl: null
  };

  componentDidMount() {
    if(this.props.spreaderId) {
      this.props.fetchSpreaderVideos(this.props.spreaderId);
    } else {
      this.props.fetchVideos();
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.videos === null && this.props.videos !== null) {
      if(this.props.spreaderId) {
        this.props.fetchSpreaderVideos(this.props.spreaderId);
      } else {
        this.props.fetchVideos();
      }
    }
  }

  onCloseModal() {
    this.setState({
      isModalOpen: false,
      selectedVideoUrl: null
    })
  }

  render() {
    const {t} = this.props;
    const {isModalOpen, selectedVideoUrl, title} = this.state;
    const videos = this.props.spreaderId
      ? this.props.spreaderVideos
      : this.props.videos;

    let visible = videos && videos.length > 0;

    return <div style={{
      height: '210px',
      visibility: visible ? 'visible' : 'hidden',
      display: visible ? 'block' : 'none'
    }}>  
      <div
          style={{
            color: "#333338",
            fontWeight: "bold",
            fontSize: 16,
            marginBottom: 8,
          }}
        >
          {t('app_instructional_videos')}
      </div>  
      <div
        className={"video-list"}
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          overflowY: 'hidden',
        }}
      >
      {
        isModalOpen && <VideoPlayerModal
          title={title}
          onClose={this.onCloseModal.bind(this)}
          url={selectedVideoUrl}
        />
      }
      {
        videos && videos.map((item, index) => {
          return <div
            className={"video-list__entry"}
            key={item.Key + index}
            style={{ marginLeft: index === 0 ? 30 : 0, paddingRight: 15 }}
          >
            <VideoItem
              id={item.Key}
              url={item.Url}
              thumb={item.Thumbnail}
              desc={item.Title}
              t={t}
              width={"210px"}
              height={"120px"}

              ga-on="click"
              ga-event-category="Video"
              ga-event-action="play"
              ga-event-label={item.Key}

              onClick={(id, url) => {
                this.setState({
                  isModalOpen: true,
                  selectedVideoUrl: url,
                  title: item.Title,
                })
              }}
            />
          </div>
        })
      }
      </div>    
    </div>;
  }
}

VideoList.propTypes = {
  spreaderId: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    t: selectors.getTranslations(state),
    videos: selectors.getVideos(state),
    spreaderVideos: selectors.getSpreaderVideos(state),
  };
};

const actions = {
  fetchVideos,
  fetchSpreaderVideos
};

VideoList = connect(mapStateToProps, actions)(VideoList);
export default VideoList;
