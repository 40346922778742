import React, {Component} from 'react';
import {connect} from 'react-redux';

import ExpendableCard from './expandableCard';
import AdviceResultItem from './adviceresultitem';

import * as selectors from '../store/selectors';

import './scss/resultboundary.scss';


class ResultBoundary extends Component {

    static YIELD = "Yield";
    static ECO = "Eco";
    static H2O = "H2O";

    state = {
        boxStrategy: ResultBoundary.YIELD,
        plateStrategy: ResultBoundary.ECO
    }

    
    constructor(props) {
        super(props);
        this.contentRef = React.createRef();
    }


    setBoxStrategy(boxStrategy) {
        this.setState({boxStrategy});
    }

    setPlateStrategy(plateStrategy) {
        this.setState({plateStrategy});
    }

    isBoxStrategy(strategy)
    {
        return this.state.boxStrategy === strategy;
    }
    
    isPlateStrategy(strategy)
    {
        return this.state.plateStrategy === strategy;
    }

    hasTrimflow(result, machine){
        return machine.HasTrimFlow && (result.YieldTrimFlowSetting || result.EcoTrimFlowSetting || result.H2OTrimFlowSetting);
    }

    hasPlate(result, machine){
        return machine.HasBorderSpreadingPlate && (result.EcoBorderPlateSetting || result.H2OBorderPlateSetting);
    }

    getContentWidth() {
        if(this.contentRef.current)
            return this.contentRef.current.getBoundingClientRect().width;

        return 0;
    }

    getTiming(timing, offset) {
        var char = timing.charCodeAt(0);
        char = char + parseInt(offset, 0);
        return String.fromCharCode(char);
    }

    getCurrentBoxPosition(strategy) {
        return (this.getPosition(strategy) - this.getPosition(this.state.boxStrategy));
    }

    getCurrentPlatePosition(strategy) {
        return (this.getPosition(strategy) - this.getPosition(this.state.plateStrategy));
    }

    getPosition(strategy) {
        switch(strategy) {
            case ResultBoundary.YIELD:
                return 0;
                
            case ResultBoundary.ECO:
                return 1;

            case ResultBoundary.H2O:
                return 2;

            default:
                return 0;
        }
    }

    render() {
        let {result, machine, t, icons, table, tablePos} = this.props;

        var timing = table[tablePos.y][tablePos.x].Timing;

        return <div>
            {(this.hasTrimflow(result, machine)) &&
            <ExpendableCard header={t('result-panel-toborder-header')} gaLabel={"ToBorderSettings"}>
                <div>
                    <div className="border-strategy-header">
                        {(result.YieldTrimFlowSetting) && <div
                            className={"border-strategy-item " + (this.isBoxStrategy(ResultBoundary.YIELD) ? "active" : "inactive")}
                            onClick={() => {this.setBoxStrategy(ResultBoundary.YIELD)}}
                            
                            ga-on="click"
                            ga-event-category="toBorder"
                            ga-event-action="pickStrategy"
                            ga-event-label="yield">
                            <img className="icon" src={icons.Yield} alt="" />
                            <span className="border-strategy-text dark-small-title">{t("results-boundary-yield")}</span>
                        </div>}
                        {(result.EcoTrimFlowSetting) && <div
                            className={"border-strategy-item " + (this.isBoxStrategy(ResultBoundary.ECO) ? "active" : "inactive")}
                            onClick={() => {this.setBoxStrategy(ResultBoundary.ECO)}}
                            
                            ga-on="click"
                            ga-event-category="toBorder"
                            ga-event-action="pickBorderStrategy"
                            ga-event-label="eco">
                            <img className="icon" src={icons.Eco} alt="" />
                            <span className="border-strategy-text dark-small-title">{t("results-boundary-eco")}</span>
                        </div>}
                        {(result.H2OTrimFlowSetting) && <div
                            className={"border-strategy-item " + (this.isBoxStrategy(ResultBoundary.H2O) ? "active" : "inactive")}
                            onClick={() => {this.setBoxStrategy(ResultBoundary.H2O)}}
                            
                            ga-on="click"
                            ga-event-category="toBorder"
                            ga-event-action="pickBorderStrategy"
                            ga-event-label="h2o">
                            <img className="icon" src={icons.H2O} alt="" />
                            <span className="border-strategy-text dark-small-title">{t("results-boundary-h2o")}</span>
                        </div>}
                    </div>

                    <div ref={this.contentRef} className="border-settings-container">
                        <div className="border-settings-movable" style={{
                            width: (3 * (this.getContentWidth()+40)) +"px",
                            transform: "translate("+(this.getCurrentBoxPosition(ResultBoundary.YIELD) * (this.getContentWidth()))+ "px, 0px)"
                        }}>
                            
                            {(result.YieldTrimFlowSetting) && 
                            <div style={{
                                width: (this.getContentWidth()) + "px"
                            }}>
                                <AdviceResultItem
                                    header={t('results-advice-ptorpm-title')}
                                    value={result.YieldTrimFlowSetting.PTOSpeed}
                                    ico={result.Images.PtoRpm}
                                    units={"rpm"}
                                />
                                <AdviceResultItem
                                    header={t('results-advice-discrpm-title')}
                                    value={result.YieldTrimFlowSetting.DiscSpeed}
                                    ico={result.Images.DiscRpm}
                                    units={"rpm"}
                                />
                                <AdviceResultItem
                                    header={t('results-advice-toborder-title')}
                                    value={result.YieldTrimFlowSetting.Setting}
                                    ico={result.Images.ToBorderSetting}
                                />                        
                            </div>
                        }                            
                            {(result.EcoTrimFlowSetting) && 
                            <div style={{
                                width: (this.getContentWidth()) + "px"
                            }}>
                            <AdviceResultItem
                                header={t('results-advice-ptorpm-title')}
                                value={result.EcoTrimFlowSetting.PTOSpeed}
                                ico={result.Images.PtoRpm}
                                units={"rpm"}
                            />
                            <AdviceResultItem
                                header={t('results-advice-discrpm-title')}
                                value={result.EcoTrimFlowSetting.DiscSpeed}
                                ico={result.Images.DiscRpm}
                                units={"rpm"}
                            />
                            <AdviceResultItem
                                header={t('results-advice-toborder-title')}
                                value={result.EcoTrimFlowSetting.Setting}
                                ico={result.Images.ToBorderSetting}
                            />                          
                            </div>
    }

                            
                            {(result.H2OTrimFlowSetting) && 
                            <div style={{
                                width: (this.getContentWidth()) + "px"
                            }}>
                            <AdviceResultItem
                                header={t('results-advice-ptorpm-title')}
                                value={result.H2OTrimFlowSetting.PTOSpeed}
                                ico={result.Images.PtoRpm}
                                units={"rpm"}
                            />
                            <AdviceResultItem
                                header={t('results-advice-discrpm-title')}
                                value={result.H2OTrimFlowSetting.DiscSpeed}
                                ico={result.Images.DiscRpm}
                                units={"rpm"}
                            />
                            <AdviceResultItem
                                header={t('results-advice-toborder-title')}
                                value={result.H2OTrimFlowSetting.Setting}
                                ico={result.Images.ToBorderSetting}
                            />  
                            
                            <AdviceResultItem
                                header={t('results-overall-application-rate-title')}
                                value={result.H2OTrimFlowSetting.RateCorrectionPercentage}
                                ico={result.Images.ApplicationRate}
                                units={t["unit_rate"]}
                            />
                            <AdviceResultItem
                                header={t('results-advice-timing-title')}
                                value={this.getTiming(timing, result.H2OTrimFlowSetting.TimingCorrectionSteps)}
                                ico={result.Images.Timing}
                            />                            
                            </div>   
                            }    
                        </div>
                    </div>
                </div>
            </ExpendableCard>
            }
            
            {(this.hasPlate(result, machine)) && /* BORDER SPREADING PLATE FROM HERE ON */
            <ExpendableCard header={t('result-panel-fromborder-header')} gaLabel='FromBorderSettings'>
            <div>
                <div className="border-strategy-header">
                    {(result.EcoBorderPlateSetting) && <div
                        className={"border-strategy-item " + (this.isPlateStrategy(ResultBoundary.ECO) ? "active" : "inactive")}
                        onClick={() => {this.setPlateStrategy(ResultBoundary.ECO)}}
                            
                        ga-on="click"
                        ga-event-category="fromBorder"
                        ga-event-action="pickBorderStrategy"
                        ga-event-label="eco">
                        <img className="icon" src={icons.Eco} alt="" />
                        <span className="border-strategy-text dark-small-title">{t("results-boundary-eco")}</span>
                    </div>}
                    {(result.H2OBorderPlateSetting) && <div
                        className={"border-strategy-item " + (this.isPlateStrategy(ResultBoundary.H2O) ? "active" : "inactive")}
                        onClick={() => {this.setPlateStrategy(ResultBoundary.H2O)}}
                            
                        ga-on="click"
                        ga-event-category="fromBorder"
                        ga-event-action="pickBorderStrategy"
                        ga-event-label="h2o">
                        <img className="icon" src={icons.H2O} alt="" />
                        <span className="border-strategy-text dark-small-title">{t("results-boundary-h2o")}</span>
                    </div>}
                </div>

                <div ref={this.contentRef} className="border-settings-container">
                    <div className="border-settings-movable" style={{
                        width: (3 * (this.getContentWidth()+40)) +"px",
                        transform: "translate("+(this.getCurrentPlatePosition(ResultBoundary.ECO) * (this.getContentWidth()))+ "px, 0px)"
                    }}>
                        
                    {(result.EcoBorderPlateSetting) && 
                        <div style={{
                            width: (this.getContentWidth()) + "px"
                        }}>
                            <AdviceResultItem
                                header={t('results-advice-ptorpm-title')}
                                value={result.EcoBorderPlateSetting.PTOSpeed}
                                ico={result.Images.PtoRpm}
                                units={"rpm"}
                            />
                            <AdviceResultItem
                                header={t('results-advice-discrpm-title')}
                                value={result.EcoBorderPlateSetting.DiscSpeed}
                                ico={result.Images.DiscRpm}
                                units={"rpm"}
                            />
                            <AdviceResultItem
                                header={t('results-advice-fromborder-title')}
                                value={result.EcoBorderPlateSetting.Setting}
                                ico={result.Images.FromBorderSetting}
                            />                        
                        </div>

                    }

                    {(result.H2OBorderPlateSetting) && 
                        <div style={{
                            width: (this.getContentWidth()) + "px"
                        }}>
                            <AdviceResultItem
                                header={t('results-advice-ptorpm-title')}
                                value={result.H2OBorderPlateSetting.PTOSpeed}
                                ico={result.Images.PtoRpm}
                                units={"rpm"}
                            />
                            <AdviceResultItem
                                header={t('results-advice-discrpm-title')}
                                value={result.H2OBorderPlateSetting.DiscSpeed}
                                ico={result.Images.DiscRpm}
                                units={"rpm"}
                            />
                            <AdviceResultItem
                                header={t('results-advice-fromborder-title')}
                                value={result.H2OBorderPlateSetting.Setting}
                                ico={result.Images.FromBorderSetting}
                            />                        
                        </div>
                    }
                    </div>
                </div>
            </div>
        </ExpendableCard>
    }
        </div>
    }
}

const mapStateToProps = (state) => {
return {
    t: selectors.getTranslations(state),
    table: selectors.getCurrentTable(state),
    tablePos: selectors.getCurrentTablePos(state),
    icons: selectors.getIcons(state),
};
};

const actions = {
};
  
ResultBoundary = connect(mapStateToProps, actions)(ResultBoundary);

export default ResultBoundary;