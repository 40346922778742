import React, {Component} from 'react';
import {connect} from 'react-redux';

import {showHelp} from '../store/actions/app';
import {updateField, validateField} from '../store/actions/currentadvice';
import {showModal} from '../store/actions/app';
import * as selectors from '../store/selectors';

import InputRange from '../components/inputrange';
import OptionSelector from '../components/optionselector';
import Advicebar from '../components/advicebar';

import * as constants from '../store/constants';

import './scss/fieldsettings.scss';

class FieldSettings extends Component {

  componentDidMount() {
    const {
      rateLimits, speedLimits, defaultDrivingSpeed,
      units, defaultWorkingWidth, defaultApplicationRate
    } = this.props;
    const applicationRate = defaultApplicationRate ? defaultApplicationRate : units === 'Metric' ? 200 : 200;
    this.handleChange('DrivingSpeed', defaultDrivingSpeed);
    this.handleChange('WorkingWidth', defaultWorkingWidth);
    this.handleChange('ApplicationRate', applicationRate);
    this.normalizeValue('ApplicationRate', rateLimits);
    this.normalizeValue('DrivingSpeed', speedLimits);
  }

  handleChange(f, v) {
    const {machineId, updateField} = this.props;
    updateField(f, v, machineId);
  }

  onSumbit() {
    let isValid = true;
    Object.keys(this.refs).forEach((ite) => {
      if(this.refs[ite].isValid() === false) {
        isValid = false;
      }
    });
    if(isValid === false) {
      return;
    }
    if(this.props.validField) {
      this.goNext();
    } else {
      this.props.validateField();
    }
  }

  componentWillReceiveProps(props) {
    if(props.validField === true && this.props.validField === false) {
      this.goNext();
    }
  }

  goNext() {
    let {match, history} = this.props;
    history.push(`/${match.params.brand}/advice/3/`);
  }

  normalizeValue(slug, {min, max}) {
    let {currentField} = this.props;
    let value = currentField[slug];
    if(value < min) {
      this.handleChange(slug, min);
    }
    if(value > max) {
      this.handleChange(slug, max);
    }
  }

  render() {
    const {
      currentField, workingLimits, rateLimits, speedLimits, showHelp,
      hasSpout, t, adviceBarHidden, toggleHideAdviceBar, icons,
    } = this.props;

    return <div className='container fieldsettings'>

      <h2 className='advice__title'>
        {t('page_title_field_settings')}
        <a
          className='advice__title__help'
          onClick={() => showHelp(constants.HELP_PAGES.FIELD)}
        >
          <svg viewBox="0 0 30 30">
            <use xlinkHref="#help"/>
          </svg>
        </a>
      </h2>

      <div className='fieldsettings__fields'>
        <InputRange
          ref="i1"
          maxLength={5}
          icon={icons.WorkingWidth}
          label={t('fieldsettings_working_width')}
          help={t('unit_distance')}
          min={workingLimits.min}
          max={workingLimits.max}
          errorMsg={`${t('fieldsettings_invalid_workingwidth')}
            ${workingLimits.min} - ${workingLimits.max}`}
          defaultValue={currentField.WorkingWidth}
          onChange={(v)=> this.handleChange('WorkingWidth', v)}
          toggleHideAdviceBar={toggleHideAdviceBar}
        />

        <InputRange
          ref="i2"
          maxLength={6}
          icon={icons.ApplicationRate}
          label={t('fieldsettings_rate')}
          help={t('unit_rate')}
          min={rateLimits.min}
          max={rateLimits.max}
          errorMsg={`${t('fieldsettings_invalid_rate')}
            ${rateLimits.min} - ${rateLimits.max}`}
          defaultValue={currentField.ApplicationRate}
          onChange={(v)=> this.handleChange('ApplicationRate', v)}
        />

        <InputRange
          ref="i3"
          maxLength={4}
          icon={icons.DrivingSpeed}
          label={t('fieldsettings_speed')}
          help={t('unit_speed')}
          min={speedLimits.min}
          max={speedLimits.max}
          errorMsg={`${t('fieldsettings_invalid_speed')}
            ${speedLimits.min} - ${speedLimits.max}`}
          defaultValue={currentField.DrivingSpeed}
          onChange={(v)=> this.handleChange('DrivingSpeed', v)}
          toggleHideAdviceBar={toggleHideAdviceBar}
        />
      </div>
      {
        (hasSpout === false) &&
        <div className="inputrange">
          <label
            className="inputrange__label"
          >{t('fieldsettings_application')}</label>
          <div style={{ height: 44 }}>
            <OptionSelector
              value={currentField.applicationType}
              onChange={(v) => this.handleChange('applicationType', v)}
              options={[
                {
                  value: 'NoTopDress',
                  name: t('fieldsettings_application_basic')
                },
                {
                  value: 'LowTopDress',
                  name: t('fieldsettings_application_small_topdress_metric')
                },
                {
                  value: 'HighTopDress',
                  name: t('fieldsettings_application_large_topdress_metric')
                },
              ]}
            />
          </div>
        </div>
      }
      <Advicebar currentStep={2} onNext={()=> this.onSumbit()} nextEnabled={true} hidden={adviceBarHidden}/>
    </div>;
  }
}

const mapStateToProps = (state) => {
  let machineId = selectors.getCurrentMachineId(state);
  return {
    machineId,
    currentField: selectors.getCurrentField(state),
    defaultWorkingWidth: selectors.getDefaultWorkingWidth(state, machineId),
    defaultDrivingSpeed: selectors.getDefaultDrivingSpeed(state, machineId),
    defaultApplicationRate: selectors.getDefaultApplicationRate(state, machineId),
    workingLimits: selectors.getWorkingLimits(state, machineId),
    speedLimits: selectors.getSpeedLimits(state),
    rateLimits: selectors.getRateLimits(state),
    validField: selectors.isCurrentFieldValid(state),
    hasSpout: selectors.machineHasSpout(state, machineId),
    t: selectors.getTranslations(state),
    units: selectors.getCurrentUnits(state),
    icons: selectors.getIcons(state)
  };
};
const actions = {
  updateField,
  showModal,
  validateField,
  showHelp,
};

FieldSettings = connect(mapStateToProps, actions)(FieldSettings);

export default FieldSettings;

