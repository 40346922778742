import 'core-js/es7/object';
import React, {Component} from 'react';
import {compose} from 'redux';
import { connect } from 'react-redux';

import {Switch, Route, Redirect} from 'react-router';
import { withRouter} from 'react-router-dom';
import { StickyContainer } from 'react-sticky';

import * as constants from '../store/constants';
import {
  initializeApp, checkApiVersion, hideModal, acceptDisclaimer, showHelp,
  hideHelp, closeAppPopup } from '../store/actions/app';
import {activateLanguage} from '../store/actions/locales';
import * as selectors from '../store/selectors';

import Advice from './advice';
import Autosetapp from './autosetapp';
import OtherApps from './otherapps';
import About from './about';
import Home from './home';
import Contact from './contact';
import SharedAdvice from './sharedAdvice';

import Modal from '../components/modal';
import Preloading from '../components/preloading';
import Disclaimer from '../components/disclaimer';
import Header from '../components/header';
import Help from '../components/help';
import DownloadAppPopup from '../components/downloadApp';
import Faq from './faq';
import TranslationsEditor from './translationsEditor';

import './scss/app.scss';

class App extends Component {

  componentDidMount() {
    let {brand} = this.props.match.params;
    this.initializeApp(brand);
  }

  componentWillReceiveProps({match:{params}}) {
    if(params.brand!==this.props.match.params.brand) {
      this.props.initializeApp(params.brand);
    }
  }

  initializeApp(brand) {
    if(this.props.initialized===true) {
      this.props.checkApiVersion();
      return;
    }
    if(constants.BRANDS.includes(brand)) {
      this.props.initializeApp(brand);
    } else {
      this.props.history.push(`/${constants.BRANDS[0]}`);
    }

  }


  render() {
    const {initialized, loading, match, brand, modalMessage, t, hideModal,
      languages, currentLanguage, activateLanguage, disclaimerAccepted, platform,
      acceptDisclaimer, showHelp, hideHelp, helpVisible, closeAppPopup,
      appPopupClosed, helpSection, webview
    } = this.props;

    const isAndroidDevice = platform === constants.PLATFORM_ANDROID;
    const isIOSDevice = platform === constants.PLATFORM_IOS;

    if(initialized===false) {
      return <Preloading />
    }
    return <StickyContainer>
      <div className={brand}>
        {(loading === true) && <Preloading />}
        {(disclaimerAccepted === false) &&
        <Disclaimer t={t} acceptDisclaimer={acceptDisclaimer} />}

        {(disclaimerAccepted === true) && <div>
          {(isAndroidDevice || isIOSDevice) && !webview && !appPopupClosed &&
            <DownloadAppPopup
              closeAppPopup={closeAppPopup}
            />
          }
          <Header
            languages={languages} currentLanguage={currentLanguage} t={t}
            platform={platform} activateLanguage={activateLanguage}
            brand={brand} showHelp={showHelp}
          />
        </div>}

        {(disclaimerAccepted === true) &&
        <Switch>
          <Route path={`${match.path}/home/`} component={Home} />
          <Route path={`${match.path}/autosetapp/`} component={Autosetapp} />
          <Route path={`${match.path}/otherapps/`} component={OtherApps} />
          <Route path={`${match.path}/about/`} component={About} />
          <Route path={`${match.path}/contact/`} component={Contact} />
          <Route path={`${match.path}/advice/:step?`} component={Advice} />
          <Route path={`${match.path}/shared`} component={SharedAdvice} />
          <Route path={`${match.path}/translations`} component={TranslationsEditor} />
          <Route path={`${match.path}/faq`} component={Faq} />
          <Route render={() => (<Redirect to={`/${brand}/home/`} />)} />
        </Switch>}

        {modalMessage &&
        <Modal closeTxt={t('button_close')} onClose={hideModal}>
          <h2>{t(modalMessage.title)}</h2>
          <p dangerouslySetInnerHTML={{ __html: t(modalMessage.text) }}></p>
        </Modal>}

        {(helpVisible === true) &&
        <Help hideHelp={hideHelp} helpSection={helpSection} />}
      </div>
    </StickyContainer>;
  }
}

const mapStateToProps = (state) => ({
  initialized: selectors.isInitialized(state),
  loading: selectors.isLoading(state),
  brand: selectors.getBrand(state),
  t: selectors.getTranslations(state),
  modalMessage: selectors.getModalMessage(state),
  languages: selectors.getAllLanguages(state),
  currentLanguage: selectors.getCurrentLanguage(state),
  disclaimerAccepted: selectors.isDisclaimerAccepted(state),
  appPopupClosed: selectors.isAppPopupClosed(state),
  helpVisible: selectors.isHelpVisible(state),
  platform: selectors.getPlatform(state),
  webview: selectors.getWebview(state),
  appDownloadInfo: selectors.getAppDownloadInfo(state),
  helpSection: selectors.getHelpSection(state),
})
const actions = {
  initializeApp,
  checkApiVersion,
  hideModal,
  activateLanguage,
  acceptDisclaimer,
  closeAppPopup,
  showHelp,
  hideHelp,
};
App = compose(
  withRouter,
  connect(mapStateToProps, actions)
)(App);
export default App;