import React from 'react';
import icons from './img/icons';
import './scss/buttonscontrol.scss';
import AdviceResultItem from './adviceresultitem';


const ButtonsControl = ({
  header,
  title = "",
  value,
  units,
  onIncrease,
  ico,
  onDecrease,
  decreaseDisabled,
  increaseDisabled,
  reset,
}) =>
  <div className={'flex justify-between width-full'}>
    <AdviceResultItem header={header} value={value} units={units} ico={ico }/>
    <div className="flex flex-center">
      <button
        className={`buttonscontrol ${decreaseDisabled ? 'buttonscontrol--disabled' : ''} mr-10`}
        onClick={onDecrease}
      >
        <span className={`buttonscontrol__text`}>{`-${title.trim()}`}</span>
      </button>
      <button
        className={`buttonscontrol ${increaseDisabled ? ' buttonscontrol--disabled': ''} mr-10`}
        onClick={onIncrease}
      >
        <span className={`buttonscontrol__text`}>{`+${title.trim()}`}</span>
      </button>
      <div
        className={`flex flex-center buttonscontrol__reload`}
        onClick={() => {reset && reset()}}
      >
        <img src={icons.reload} alt="" width={18}/>
      </div>
    </div>
  </div>

export default ButtonsControl;
