import * as constants from '../constants';
import * as Api from '../../api';
import {trackError, preloadStarted, preloadFinished} from './app';
import {getCurrentUnits, getBrand} from '../selectors';

const fetchMachinesStarted = () => ({
  type: constants.FETCH_MACHINES_STARTED,
});
const fetchMachinesSuccess = (machines) => ({
  type: constants.FETCH_MACHINES_SUCCESS,
  payload: machines
});

const fetchMachineStarted = () => ({
  type: constants.FETCH_MACHINE_STARTED,
});
const fetchMachineSuccess = (machine) => ({
  type: constants.FETCH_MACHINE_SUCCESS,
  payload: machine
});

const fetchMachineBySerialStarted = (serialNumber) => ({
  type: constants.FETCH_MACHINE_BY_SERIAL_STARTED,
  payload: serialNumber
});

const fetchMachineBySerialSuccess = (machine) => ({
  type: constants.FETCH_MACHINE_BY_SERIAL_SUCCESS,
  payload: machine
});

export const clearMachineSearch = () => ({
  type:constants.CLEAR_MACHINE_SEARCH
});

export const activateMachine = (machine) => ({
  type: constants.MACHINE_SELECTED,
  payload: machine
});

export const fetchMachines = () => (dispatch, getState) => {
  dispatch(preloadStarted());
  dispatch(fetchMachinesStarted());
  const state = getState();
  const brand = getBrand(state);
  const units = getCurrentUnits(state);
  return Api.getMachines(brand, units)
    .then((r) => {
      dispatch(preloadFinished());
      return dispatch(fetchMachinesSuccess(r));
    })
    .catch(err => dispatch(trackError(err))
  );
};

export const fetchMachine = (id) => async (dispatch, getState) => {
  dispatch(preloadStarted());
  dispatch(fetchMachineStarted());
  const unit = getCurrentUnits(getState());
  try {
    const r = await Api.getMachine(id, unit);
    dispatch(preloadFinished());
    return dispatch(fetchMachineSuccess(r));
  }
  catch (err) {
    return dispatch(trackError(err));
  }
};

export const fetchMachineBySerialNumber = (serialNumber) => async (dispatch) => {
  //dispatch(preloadStarted());
  dispatch(fetchMachineBySerialStarted(serialNumber));
  try {
    const r = await Api.getMachineBySerialNumber(serialNumber);
    dispatch(preloadFinished());
    return dispatch(fetchMachineBySerialSuccess(r));
  }
  catch (err) {
    return dispatch(trackError(err));
  }
};
