import React, {Component} from 'react';

import './scss/graphicbar.scss';

class GraphicBar extends Component {

  state = {height: null}

  componentDidMount() {
    let {height} = this.refs.container.getBoundingClientRect();
    this.setState({height: Math.round(height)});
  }

  render() {
    let {v1, v2, v3, v4} = this.props;
    const {height} = this.state;
    const valueList = [v1, v2, v3, v4];
    const tickGap = Math.floor((height / 10) * (1/5));
    const tickHeight = Math.ceil((height / 10) - tickGap);
    const tickOverallHeight = tickHeight + tickGap;
    const borderRadius = 2 * Math.ceil(height / 77);

    return <div className='graphic'>
      <div
        ref='container'
        style={{
          height: "100%",
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: 'transparent',
        }}
      >
        {
          Array.apply(null, new Array(4)).map((v, i) => {
              let fraction = valueList[i];

              return <div
                key={'b' + i}
                style={{
                  width: "23%",
                  marginLeft: 0,
                  position: "relative",
                  height: "100%",
                }}
              >
                {
                  Array.apply(null, new Array(10)).map((v, j) => {
                      const top = height - (tickOverallHeight * j) - tickHeight;
                      const bottom = tickOverallHeight * j + tickGap / 2;

                      return <div
                        key={'bc' + j}
                        style={{
                          position: "absolute",
                          left: 0,
                          right: 0,
                          top,
                          bottom,
                          borderRadius,
                          backgroundColor: "#333338",
                          opacity: 0.05,
                        }}
                      />
                    }
                  )
                }
                {
                  Array.apply(null, new Array(10)).map((v, j) => {
                      const x = fraction <= 0 ? 0 : fraction >= 10 ? 1 : fraction / 10;
                      const top = height - (tickOverallHeight * j) - (tickHeight * x);
                      const bottom = tickOverallHeight * j + tickGap / 2;

                      if (valueList[i] <= 100) {
                        fraction -= 10;
                      }

                      return <div
                        key={'bc' + j}
                        style={{
                          position: "absolute",
                          left: 0,
                          right: 0,
                          top,
                          bottom,
                          borderRadius,
                          backgroundColor:
                            valueList[i] > 100
                              ? '#d31822'
                              : '#6ED59F'
                        }}
                      />
                    }
                  )
                }
              </div>
            }
          )
        }
      </div>
    </div>
  }
}

export default GraphicBar;
