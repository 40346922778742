import React from 'react';

import './scss/preloading.scss';

const Preloading = () =>
  <div className='preloading'>
    <svg viewBox="0 0 128 128"><use xlinkHref="#preload" /></svg>
  </div>

export default Preloading;
