

export const getAllLanguages = (state) =>
state.locales.languages;

export const getCurrentLanguage = (state) =>
  state.locales.current;

export const getCurrentLanguageCode = (state) =>
  state.locales.current.code;

export const getCurrentUnits = (state) =>
  state.locales.current.units;

export const getTranslations = (state) => (original) => {
  let {translations} = state.locales;
  if(original in translations) {
    return translations[original];
  }
  return original;
}
export const getRawTranslations = (state) =>
  state.locales.rawTranslations;

export const getLegalLinks = (state) => 
  state.locales.legalLinks;