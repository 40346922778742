import * as constants from '../constants';

const otherapps = (state = null, action) => {
  switch (action.type) {
    default: {
      return state;
    }
    case constants.FETCH_OTHERAPPS_SUCCESS: {
      let apps = action.payload.slice();
      return apps;
    }
    case constants.API_OUT_OF_DATE:
    case constants.ACTIVATE_LANGUAGE: {
      return null;
    }
  }
}
export default otherapps;
