import React, {Component} from 'react';

import './scss/modal.scss';


export default class Modal extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  closeModal() {
    this.props.onClose();
  }
  render() {
    let {closeTxt, hideButton} = this.props;
    return <div className='modal'>
      <div className='modal__overlay'></div>
      <div className='modal__box'>
        <a
          onClick={()=> this.closeModal()}
          className='modal__cross'
        >
          <svg viewBox="0 0 19.43 20.04">
            <use xlinkHref="#cross" />
          </svg>
        </a>
        {this.props.children}
        {(hideButton!==true) &&
          <button
            onClick={()=> this.closeModal()}
            className='btn-primary'
          >{closeTxt}</button>}
      </div>
    </div>
  }
}
