import React, {Component} from 'react';
import PropTypes from 'prop-types';

import localIcons from './img/icons'

import './scss/expendablecard.scss';


class ExpendableCard extends Component {
    intervalTimer = {};
    updates = 0;
    state = {
        isExpanded: false,
        height: 0,
        updates: 0
    };

    
    constructor(props) {
        super(props);
        this.state.isExpanded = props.expanded;
        this.contentRef = React.createRef();
    }

    updateHeight() {
        if(this.contentRef.current)
        {
            let height = this.contentRef.current.getBoundingClientRect().height;
            if(height !== this.state.height)
            {
                this.setState({
                    ...this.state,
                    height: height
                  })
            }
        }
        this.updates = this.updates + 1;
        if(this.updates > 15)
            clearInterval(this.intervalTimer);
    }

    componentDidMount() {
        this.updateHeight();

        this.intervalTimer = setInterval(() => this.updateHeight(), 500);
    }

    componentWillUnmount() {
      clearInterval(this.intervalTimer);
    }


    render() {
        return <div className={'expendable-card'}>
            <div className={'header ' + (this.state.isExpanded ? 'card-expanded' : 'card-collapsed')}
                
                ga-on="click"
                ga-event-category="AdviceCard"
                ga-event-action={"toggled " + this.props.gaLabel}
                ga-event-label={this.props.gaLabel}

                onClick= {() => {
                    this.setState({
                      ...this.state,
                      isExpanded: !this.state.isExpanded
                    })}
                }>
                <div className='header-title'> {this.props.header} </div>
                <img
                    style={{
                    borderRadius: '50%',
                    width: 12,
                    height: 12,
                    textAlign: 'right',
                    transform: `${this.state.isExpanded ? "rotate(180deg)" : ""}`,
                    transition: "all .75s"
                    }}
                    width={12}
                    height={12}
                    src={localIcons.downArrow}
                    alt=""
                />
            </div>

            <div className={'content-expander'} style={{height: (this.state.isExpanded ? this.state.height : 0)}}>
                <div className={'content-container'} ref={this.contentRef}>
                    {this.props.children}
                </div>
            </div>
        </div>
    }
}

ExpendableCard.propTypes = {
    gaLabel: PropTypes.string,
    header: PropTypes.string,
    expanded: PropTypes.bool,
};
  
ExpendableCard.defaultProps = {
    gaLabel: "",
    header: "None",
    expanded: false,
};


export default ExpendableCard;