
export const getHistory = (state) =>
  state.history;

export const getHistoryAdvice = (state, id) => {
  let history = getHistory(state);
  let matched = history.filter((ite)=> ite.id === id)
  return (matched.length===1) ? matched[0] : null;
}

//Returns a list of all the saved favourites.
export const getFavourites = (state, units) =>
  state.favourites.filter((item) => item.units === units);

//Returns the favourited advice with a specific id.
export const getFavouriteAdvice = (state, id, units) => {
  let history = getFavourites(state, units);
  let matched = history.filter((item)=> item.id === id)
  return (matched.length===1) ? matched[0] : null;
}
