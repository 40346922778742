import React from 'react';
import './scss/shapes.scss';
import {findIndex} from '../utils';
import alert_icon from './img/icons/alert_icon.png';

const Shapes = ({shapes, shapeRanges, shapeId, activeShape, width, t}) => {



  const updateSelection = (id, outOfRange) => {
    if(!outOfRange)
    {
      if (shapeId) {
        if (findIndex(shapeId, id) === -1) {
          activeShape(shapeId.concat([id]));
        } else {
          activeShape(shapeId.filter(item => item !== id));
        }
      } else {
        activeShape([id]);
      }
    }
  };
  
  let checkForRanges = shapeRanges !== null;

  return (
    <ul className="shapes">
      {
        shapes.filter(s => !checkForRanges || shapeRanges.find(r => r.ShapeId === s.Id)).map((ite, i) => {
          const isActive = findIndex((shapeId || []), ite.Id) !== -1;
          let temp = null;
          let outOfRange = false;

          if(shapeRanges)
          {
            const range = shapeRanges.find(r => r.ShapeId === ite.Id);
            if(range.MinimumWidth > Number(width))
            {
              temp = t('results-overall-workingwidth-title') + " < " + (range.MinimumWidth + 0.5).toFixed(0) + " " + t('unit_distance');
              outOfRange = true;
            }
            else if(range.MaximumWidth < Number(width))
            {
              temp = t('results-overall-workingwidth-title') + " > " + (range.MaximumWidth - 0.5).toFixed(0) + " " + t('unit_distance');
              outOfRange = true;
            }
          }

          return <li
            key={'f' + i}
            onClick={() => updateSelection(ite.Id, outOfRange)}
            className={`shapes__item ${isActive ? ' shapes__item--active' : ''}  ${outOfRange ? ' shapes__item--outOfRange' : ''}`}
          >
            <div className={`shapes__item__colls`}>
            <div
              style={{backgroundImage: `url(${ite.ImageURL})` }}
              className={"shape__image"}
              src={ite.ImageURL} alt={ite.Name}
            />
            <div className={"shapes__item__container"}
            >
              <div
                style={{
                  display: isActive ? "block" : "none",
                  top: 15,
                  right: -10,
                  userSelect: "none",
                }}
                className={'shapes__item__tick' + (isActive ? ' shapes__item__tick--active' : '')}
              >
                <svg viewBox="0 0 12 9">
                  <use xlinkHref="#tick" />
                </svg>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    color: '#333338',
                    fontSize: 14,
                    fontWeight: 'bold'
                  }}
                >
                  {ite.Key}
                </div>
                <div
                  className={"shapes__item__description"}
                >
                  {ite.Name}
                </div>
              </div>
            </div>
            </div>
            {
            outOfRange && <div
              className={"shapes__item__warning"}>
                <img src={alert_icon}></img>
              <p>{temp}</p>
            </div>
            }
          </li>
        })
      }
    </ul>
  );
};


export default Shapes;
