import React, { Component } from 'react';
import Modal from './modal';
import * as selectors from '../store/selectors';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ReactPlayer from 'react-player';

class VideoPlayerModal extends Component {
  render() {
    const { onClose, t, url, title } = this.props;

    return (
      <Modal hideButton={true} onClose={onClose} closeTxt={t('button_close')}>
        <div style={{margin: "10px 0px", width: "100%"}} className="my-class">
          <div
            style={{
              color: "#333338",
              fontSize: 16,
              fontWeight: "bold",
              marginBottom: 16,
            }}
          >
            {t(title)}
          </div>
          <ReactPlayer controls={true} playsInline={true} playing={true} url={url} width={"100%"} height={"auto"}>
          </ReactPlayer>
        </div>
      </Modal>
    );
  }
}

VideoPlayerModal.propTypes = {
  url: PropTypes.string,
  onClose: PropTypes.func,
};

const mapStateToProps = (state) => ({
  t: selectors.getTranslations(state),
});

VideoPlayerModal = connect(mapStateToProps)(VideoPlayerModal);

export default VideoPlayerModal;
