import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {fetchApplications, activeApplication, isFineDosage,
  activeShapeAll} from '../store/actions/currentadvice';
import {showModal} from '../store/actions/app';
import * as selectors from '../store/selectors';

import Modal from '../components/modal';
import Advicebar from '../components/advicebar';
import TableApplications from '../components/tableapplications';
import localIcons from '../components/img/icons'

import './scss/applications.scss';

class ApplicationVaneset extends Component {  
  intervalTimer = {};
  updates = 0;
  state = {
      isExpanded: false,
      height: 0,
      updates: 0
  };

  
  constructor(props) {
      super(props);
      this.state.isExpanded = props.expanded;
      this.contentRef = React.createRef();
  }

  updateHeight() {
      if(this.contentRef.current)
      {
          let height = this.contentRef.current.getBoundingClientRect().height;
          if(height !== this.state.height)
          {
              this.setState({
                  ...this.state,
                  height: height
                })
          }
      }
      this.updates = this.updates + 1;
      if(this.updates > 15)
          clearInterval(this.intervalTimer);
  }

  componentDidMount() {
      this.updateHeight();

      this.intervalTimer = setInterval(() => this.updateHeight(), 500);
  }

  componentWillUnmount() {
    clearInterval(this.intervalTimer);
  }

  render() {
    let {vaneset, t, UsesFractioning, activeApplication} = this.props;
    let isPS = vaneset.Name === ''
    return <div className="applications__vaneset" key={vaneset.Id}>
        { !isPS && 
          <div className="applications__header" 
               onClick={() => {
                  this.setState({
                    ...this.state,
                    isExpanded: !this.state.isExpanded
                  })}}>
          
            <div style={{width: "100%"}} className="applications__header__image" >
              <img src={vaneset.ImageUrl} alt={vaneset.Name} />
            </div>
            <div  className="applications__header__text">
              <h1>{vaneset.Name}</h1>
              {
                vaneset.PartNumbers !== undefined && vaneset.PartNumbers !== null && vaneset.PartNumbers.map((partNumber) => {                
                  return <p>
                  {t('fertilizer_list_part_number')} {partNumber}</p>
                })
              }
              <img
                style={{
                
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 10,
                margin: 'auto',
                width: 12,
                height: 12,
                transform: `${(this.state.isExpanded) ? "rotate(180deg)" : ""}`,
                transition: "all .75s",
                textAlign: 'right'
                }}
                width={12}
                height={12}
                src={localIcons.downArrow}
                alt=""
              />
            </div>
        </div>}

        <div className={'applications__content-expander'} style={{height: ((this.state.isExpanded || isPS)  ? this.state.height + 15 : 0)}}>
            <div className={'applications__content-container'} ref={this.contentRef}>
              <TableApplications
                key={vaneset.Id}
                UsesFractioning={UsesFractioning}
                list={vaneset.Applications}
                activeApplication={(id) => activeApplication(vaneset.Id, id)}
                t={t}
              />
            </div>
        </div>
      </div>
  }
}

ApplicationVaneset.propTypes = {
  vaneset: PropTypes.object,
  expanded: PropTypes.bool,
  UsesFractioning: PropTypes.bool,
  activeApplication: PropTypes.func,
  t: PropTypes.func,
};
  
ApplicationVaneset.defaultProps = {
  vaneset: null,
  expanded: false,
  UsesFractioning: true,
  activeApplication: null,
  t: null,
};


class Applications extends Component {

  state = {
    vaneSetsOpened: null,
    stickyOffSet: null,
  };

  componentDidMount() {
    this.fetchApplications();
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillReceiveProps(props) {
    let {fineDosage, widerSearchCriteriaUsed, applications, vanesetId,
      applicationId} = props;
    let {match, history} = this.props;
    if(this.props.fineDosage===null && fineDosage!==null) {
      history.push(`/${match.params.brand}/advice/5/`);
    }
    if (widerSearchCriteriaUsed === true
        && this.props.widerSearchCriteriaUsed === false
        && applications.length > 0) {
      this.props.showModal({
        title: 'popup_title_attention',
        text: 'popup_message_attention'
      })
    }
    if(!(applicationId===this.props.applicationId && vanesetId===this.props.vanesetId) && applicationId!==null ) {
      setTimeout(()=> this.onSumbit(), 1);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  fetchApplications() {
    let {applications} = this.props;
    if(applications===null) {
      this.props.fetchApplications();
    }
  }
  fetchApplicationsAll = () => {
    this.props.activeShapeAll(this.props.shapes.map(item => item.Id));
    this.props.fetchApplications();
  };
  
  goBack = () => {
    const {match, history} = this.props;
    history.push(`/${match.params.brand}/advice/3/`);
  };

  activeApplication(vanesetId, id) {
    if(id===this.props.applicationId && vanesetId === this.props.vanesetId) {
      this.onSumbit();
    } else {
      this.props.activeApplication(vanesetId, id);
    }
  }

  handleScroll = () => {
    this.refs.stickyBar &&
    this.setState({
      ...this.state,
      stickyOffSet: this.refs.stickyBar.getBoundingClientRect().top
    })
  };

  toggleVan(id) {
    this.setState((state) => {
      return {
        ...state,
        vaneSetsOpened: id
      };
    });
  }

  onSumbit() {
    let {match, history, fineDosage} = this.props;
    if(fineDosage===null) {
      this.props.isFineDosage();
    } else {
      history.push(`/${match.params.brand}/advice/5/`);
    }
  }

  getSelectedVaneSetId(){
    const { vanesetId } = this.props;

    return this.state.vaneSetsOpened ||
      vanesetId ||
      null;
  }

  render() {

    let {t, showModal, applicationId, UsesFractioning} = this.props;
    let applications = (this.props.applications) ? this.props.applications : [];

    if(this.props.loading) {
      return null;
    }

    return <div className='applications'>
      <div className="container">
        <h2 className='advice__title' style={{marginBottom: 0}}>
          {t('page_title_fertilizer_list')}
          <a
            className='advice__title__help'
            onClick={()=> showModal({
              title: 'fertilizer_list_info_title',
              text: 'help_spreadchart_applicationlist'
            })}
          >
            <svg viewBox="0 0 30 30"><use xlinkHref="#help" /></svg>
          </a>
        </h2>

      { (applications.length===0) &&
        <Modal hideButton={true} onClose={this.goBack}>
          <p>{t('fertilizer_list_nothing_found')}</p>
          <button
              onClick={this.fetchApplicationsAll}
              className='btn-primary'
          >{t('button_show_all')}</button>
          <button
              onClick={this.goBack}
              className='btn-primary'
          >{t('button_back')}</button>
        </Modal> }
        
        <p className='advice__description' >
          {t('fertilizer_list_header_text')}
        </p>

        <div>
          {
            applications.sort((a, b) => b.Applications.length - a.Applications.length).map((ite, i) => {
              const isSelected = (ite.Id === this.getSelectedVaneSetId() || (!this.getSelectedVaneSetId() && i === 0));

              return <ApplicationVaneset vaneset={ite}
                t={t}
                activeApplication={(vid, id) => this.activeApplication(vid, id)}
                UsesFractioning={UsesFractioning}>
                {ite.Name}
              </ApplicationVaneset>
            })
          }
        </div>
      </div>
      <Advicebar currentStep={4} onNext={()=> this.onSumbit()} nextEnabled={(applicationId!==null)} />
    </div>
/*
    return <div className='applications'>
      <div className="container">
      <h2 className='advice__title' style={{marginBottom: 0}}>
        {t('page_title_fertilizer_list')}
        <a
          className='advice__title__help'
          onClick={()=> showModal({
            title: 'fertilizer_list_info_title',
            text: 'help_spreadchart_applicationlist'
          })}
        >
          <svg viewBox="0 0 30 30"><use xlinkHref="#help" /></svg>
        </a>
      </h2>

      <p
        className='advice__description'
      >
        {t('fertilizer_list_header_text')}
      </p>
      </div>
      { (applications.length===0) &&
        <Modal hideButton={true} onClose={this.goBack}>
          <p>{t('fertilizer_list_nothing_found')}</p>
          <button
              onClick={this.fetchApplicationsAll}
              className='btn-primary'
          >{t('button_show_all')}</button>
          <button
              onClick={this.goBack}
              className='btn-primary'
          >{t('button_back')}</button>
        </Modal> }

      <div ref='container'>
        <div
          ref={"stickyBar"}
          style={{
            position: 'sticky',
            padding: '10px 0 0px 30px',
            top: 0,
            marginLeft: '-30px',
            backgroundColor: "#ffffff",
            zIndex: 10,
            boxShadow: this.state.stickyOffSet=== 0 ? "0 10px 20px 0 rgba(0,0,0,0.1)" : ""
          }}
        >
               <div
                 className='container'
                style={{
                  overflow: "hidden",
                  position: "relative"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    overflowX: "auto",

                  }}
                >
                  {
                    applications.map((ite, i) => {
                      const isSelected = (ite.Id === this.getSelectedVaneSetId() || (!this.getSelectedVaneSetId() && i === 0));

                      return <div
                        key={ite.Id}
                        className={(ite.Id === this.getSelectedVaneSetId() || (!this.getSelectedVaneSetId() && i === 0)) ? "selected_vaneset_node" : ""}
                      >
                        {
                          (ite.Name !== '') && <div
                            className="hover-cursor"
                            key={ite.Id}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContents: 'center',
                              alignItems: 'center',
                              width: 165,
                              minWidth: 165,
                              minHeight: 75,
                              paddingBottom: 12,
                              borderBottom: isSelected
                                ? '2px solid #007B3B'
                                : '2px solid rgba(51, 51, 56, .5)',
                              opacity: isSelected ? 1 : 0.3,
                            }}
                            onClick={() => this.toggleVan(ite.Id)}

                            
                            ga-on="click"
                            ga-event-category="Vanes"
                            ga-event-action="switch"
                            ga-event-label={ite.Id}
                          >
                            <img
                              style={{marginLeft: "10px"}}
                              width={140} src={ite.ImageUrl} alt={ite.Name}
                            />
                          </div>
                        }
                      </div>
                    })
                  }
                </div>
              </div>
        </div>
        <div className="container">
        {
          applications.map((ite, i) => {
            return (ite.Id === this.getSelectedVaneSetId() || (!this.getSelectedVaneSetId() && i === 0)) &&
              <TableApplications
                key={ite.Id}
                UsesFractioning={UsesFractioning}
                activeApplication={(id) => this.activeApplication(ite.Id, id)}
                active={applicationId}
                list={ite.Applications}
                t={t}
              />
          })
        }
        </div>
      </div>
      <Advicebar currentStep={4} onNext={()=> this.onSumbit()} nextEnabled={(applicationId!==null)} />
    </div>*/
  }
}

const mapStateToProps = (state) => {
  let machineId = selectors.getCurrentMachineId(state),
      machines = selectors.getMachines(state);
      return {
        applications: selectors.getCurrentApplications(state),
        applicationId: selectors.getCurrentApplicationId(state),
        vanesetId: selectors.getCurrentVanesetId(state),
        t: selectors.getTranslations(state),
        loading: selectors.isLoading(state),
        fineDosage: selectors.getFineDosage(state),
        UsesFractioning: machines[machineId].UsesFractioning,
        widerSearchCriteriaUsed: state.currentAdvice.widerSearchCriteriaUsed,
        shapes: selectors.getShapes(state),
  };
}
const actions = {
  fetchApplications,
  activeApplication,
  showModal,
  activeShapeAll,
  isFineDosage
};

Applications = connect(mapStateToProps, actions)(Applications);

export default Applications;