import React from 'react';
import MachineItem from './machineitem';

const MachineCategory = ({name, models, clickMachine, machineId}) =>
  <div className="machineslist__brand">
    <h3>{name}</h3>
    <div className="machineslist__items__container">
      {models && models.length && models.map(item =>
        <div
          key={item.Id}
          className={`machineslist__item ${machineId===item.Id && "machineslist__item--active"}`}
        >
          <MachineItem
            clickMachine={clickMachine}
            name={item.Name}
            imageUrl={item.ImageURL}
            id={item.Id}
          />
        </div>
      )}
    </div>
  </div>;

export default MachineCategory;
