import React, {Component} from 'react';
import icons from './img/icons';

import './scss/searchfield.scss';

class SearchField extends Component {
  state = {
    isFocused: false
  };

  componentDidMount() {
    const { input } = this.refs;
    input && input.addEventListener('focus', this.setInputFocus);
    input && input.addEventListener('focusout', this.resetInputFocus);
  };

  componentWillUnmount() {
    const { input } = this.refs;
    input && input.removeEventListener('focus', this.setInputFocus);
    input && input.removeEventListener('focusout', this.resetInputFocus);
  };

  setInputFocus = () => {
    this.setState({
      ...this.state,
      isFocused: true
    });
  };

  resetInputFocus = () => {
    this.setState({
      ...this.state,
      isFocused: false
    });
  };

  handleFocus = () => {
    const { toggleHideAdviceBar } = this.props;
    toggleHideAdviceBar && toggleHideAdviceBar(true);
  };

  handleBlur = () => {
    const { toggleHideAdviceBar } = this.props;
    toggleHideAdviceBar && toggleHideAdviceBar(false);
  };

  render() {
    const {value, onChange, placeholder, onSubmit, notFound} = this.props;
    return (
      <div className="input-search">
        <form onSubmit={onSubmit}>
          <input
            ref="input"
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
          />
          <img
            alt=""
            src={this.state.isFocused ? icons.icoSearchBlue : icons.icoSearch}
            onClick={onSubmit}/>
        </form>
        {notFound && <span>{notFound}</span>}
      </div>
    );
  };
}

export default SearchField;
