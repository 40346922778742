import React, {Component} from 'react';

import './scss/languages.scss';

export default class Language extends Component {

  state = {menuVisible:false};

  activeLanguage(l) {
    this.setState({menuVisible: false});
    this.props.activateLanguage(l.id);
  }

  render() {
    let {languages, currentLanguage} = this.props;
    let {menuVisible} = this.state;
    return <div className='languages'>
      <div
        onClick={()=> this.setState({menuVisible: true})}
        className='languages__active'
      >
        <span
          className='languages__flag'
          style={{backgroundImage: `url(${currentLanguage.flag})`}}
        ></span>
        {currentLanguage.name}
      </div>
      { (menuVisible) &&
        <div
          onClick={()=> this.setState({menuVisible: false})}
          className='languages__close'></div> }
      { (menuVisible) && <ul className='languages__list'>
        { languages.map((l, i) =>
          <li
            className={'languages__list__item' +
              ((currentLanguage.id === l.id)
              ? ' languages__list__item--active'
              : '')}
            key={'l' + i}
            onClick={()=>this.activeLanguage(l)}
          >
          <span
            className='languages__flag'
            style={{backgroundImage: `url(${l.flag})`}}
          ></span>
          {l.name}</li>)}
      </ul> }
    </div>
  }
}
