import * as constants from '../constants';
import * as Api from '../../api';
import * as selectors from '../../store/selectors';
import {trackError, preloadStarted, preloadFinished} from './app';


const fetchVideosStarted = () => ({
  type: constants.FETCH_VIDEOS_STARTED,
});

const fetchVideosSuccess = (videos) => ({
  type: constants.FETCH_VIDEOS_SUCCESS,
  payload: videos
});

export const fetchVideos = () => (dispatch, getState) => {
  dispatch(preloadStarted());
  dispatch(fetchVideosStarted());
  let lang = selectors.getCurrentLanguageCode(getState()),
    units = selectors.getCurrentUnits(getState()),
    brand = selectors.getBrand(getState());
  return Api.getVideos(lang, brand, units)
  .then((r) => {
    dispatch(preloadFinished());
    return dispatch(fetchVideosSuccess(r));
  })
  .catch(err => dispatch(trackError(err)));
};
