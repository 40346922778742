import * as constants from '../constants';
import * as Api from '../../api';
import * as selectors from '../../store/selectors';
import {trackError, preloadStarted, preloadFinished} from './app';


const fetchSpreaderVideosStarted = () => ({
  type: constants.FETCH_SPREADERVIDEOS_STARTED,
});
const fetchSpreaderVideosSuccess = (spreaderVideos) => ({
  type: constants.FETCH_SPREADERVIDEOS_SUCCESS,
  payload: spreaderVideos
});
export const fetchSpreaderVideos = (spreaderId) => (dispatch, getState) => {
  dispatch(preloadStarted());
  dispatch(fetchSpreaderVideosStarted());
  const lang = selectors.getCurrentLanguageCode(getState());
  return Api.getSpreaderVideos(spreaderId, lang)
  .then((r) => {
    dispatch(preloadFinished());
    return dispatch(fetchSpreaderVideosSuccess(r));
  })
  .catch(err => dispatch(trackError(err)));
};

