export const getCurrentStep = (state) => {
  const {step} = state.currentAdvice;
  return (step===null) ? 1 : step;
};

export const getCurrentField = (state) => {
  const {WorkingWidth, ApplicationRate, applicationType,
    DrivingSpeed } = state.currentAdvice;
  return {WorkingWidth, ApplicationRate, applicationType,
    DrivingSpeed};
};

export const getCurrentAppType = (state) => {
  switch(state.currentAdvice.applicationType) {
    case 'LowTopDress':
      return 'fieldsettings_application_small_topdress_metric';
    case 'HighTopDress':
      return 'fieldsettings_application_large_topdress_metric';
    default:
      return 'fieldsettings_application_basic';
  }
};


export const isCurrentFieldValid = (state) =>
  state.currentAdvice.validField;

export const getCurrentMachineId = (state) =>
  state.currentAdvice.machine;

export const getCurrentHistoryMachine = (state) =>
  state.currentAdvice.machineHistory;

  export const getCurrentShapeRanges = (state) =>
    state.currentAdvice.shapeRanges;

export const getCurrentProperties = (state) => {
  let {granuleSizeMinus2, granuleSizeB23, granuleSizeB34, granuleSize4,
    adviceDensity} = state.currentAdvice;
  return {granuleSizeMinus2, granuleSizeB23, granuleSizeB34, granuleSize4,
    adviceDensity};
};

export const getCurrentValidProperties = (state) => {
  let props = getCurrentProperties(state);
  let {granuleSizeMinus2, granuleSizeB23, granuleSizeB34, granuleSize4} = props;
  let data = {};
  let t = 0;
  for (let i in props) {
    if(i!=='adviceDensity') {
      t += Number(props[i]);
    }
  }
  if(t===100) {
    data = {granuleSizeMinus2, granuleSizeB23, granuleSizeB34, granuleSize4};
  }
  if(props.adviceDensity!==0) {
    data['adviceDensity'] = props.adviceDensity;
  }
  return data;
}

export const getCurrentShapeId = (state) =>
  state.currentAdvice.shapeId;

export const getCurrentApplications = (state) =>
  state.currentAdvice.applications;

export const getCurrentApplicationId = (state) =>
  state.currentAdvice.fertiliserId

export const isCurrentKitUsed = (state) =>
  state.currentAdvice.kitUsed;

export const getCurrentVanesetId = (state) =>
  state.currentAdvice.vanesetId

export const getCurrentIsValid = (state) =>
  state.currentAdvice.isValid;

export const getCurrentApplication = (state) => {
  let applicationId = getCurrentApplicationId(state);
  let applications = getCurrentApplications(state);
  const numberApplicationId = typeof applicationId === 'string' ?
    parseInt(applicationId, 10) : applicationId;
  if(applications) {
    for(let i in applications) {
      let r = applications[i].Applications.filter((app) =>
        app.FertilizerId === numberApplicationId);
        if(r.length>0) {
          return r[0];
        }
    }
  }
  return null;
}
export const getApplication = (state) => (id) =>  {
  let applications = getCurrentApplications(state);
  for(let i in applications) {
    let r = applications[i].Applications.filter((app) => app.Id === id);
    if(r.length>0) {
      return r[0].Name
    }
  }
  return "";
}


export const getCurrentFertilizerId = (state) => {
  let application = getCurrentApplication(state);
  if(application) {
    return getCurrentApplication(state).FertilizerId;
  }
  return null;
}

export const getFineDosage = (state) =>
  state.currentAdvice.fineDosage

export const getCurrentResult = (state) =>
  state.currentAdvice.result;

export const getCurrentTable = (state) => {
  let result = getCurrentResult(state);
  return (result===null) ? [] : result.Table;
}

export const getBaseTablePos = (state) => {
  return {
    x: state.currentAdvice.baseResultTableX,
    y: state.currentAdvice.baseResultTableY
  };
};

export const getCurrentTablePos = (state) => ({
  x: state.currentAdvice.resultTableX,
  y: state.currentAdvice.resultTableY
});

export const getCurrentPdf = (state) =>
  state.currentAdvice.resultPdf;

export const getCurrentAdvice = (state) =>
  state.currentAdvice;

export const getCurrentAdviceId = (state) =>
  getCurrentAdvice(state).id;

export const isHistoryAdvice = (state) =>
  state.currentAdvice.inHistory

