import React, { Component } from 'react';
import * as utils from '../utils';
import './scss/tablehistory.scss';
import * as selectors from '../store/selectors';
import { connect } from 'react-redux';
import { favouriteRemoveAdvice } from '../store/actions/history';
import icons from './img/icons';
import Modal from './modal';


class HistoryCard extends Component {
  state = {isModalOpen: false};

  formatDate(dt) {
    let date = utils.formatDate(dt);
    let hour = utils.formatHour(dt);
    return date + ' ' + hour;
  }

  getApplication(applications, id) {
    const numberId = typeof id === 'string' ? parseInt(id, 10) : id;
    for(let i in applications) {
      let r = applications[i].Applications.filter((app) => app.Id === numberId);
      if(r.length>0) {
        return r[0];
      }
    }
  }

  getShape(applications, id) {
    const numberId = typeof id === 'string' ? parseInt(id, 10) : id;
    for(let i in applications) {
      let r = applications[i].Applications.filter((app) => app.Id === numberId);
      if(r.length>0) {
        return r[0].Shape
      }
    }
  }

  render() {
    const {t, ele, loadAdvice, index} = this.props;
    const {isModalOpen} = this.state;
    const workingWidth = ele.workingWidth ? ele.workingWidth : ele.WorkingWidth;
    const drivingSpeed = ele.drivingSpeed ? ele.drivingSpeed : ele.DrivingSpeed;
    return <div
      className={"history-card"}
      style={{
        borderRadius: 4,
        backgroundColor: "#FFFFFF",
        boxShadow: `${"0 10px 20px 0 rgba(0,0,0,0.1)"}${index === 0 ? ", 0px -10px 15px -10px rgba(0,0,0,0.1)" : ""}`,
        height: 124,
      }}
    >
      {
        isModalOpen && <Modal
          hideButton={true}
          closeTxt={t('button_close')}
          onClose={() => {
            this.setState({ isModalOpen: false });
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContents: 'center',
              alignItems: 'center',
              flexDirection: "column"
            }}
          >
            <div
              style={{
                alignSelf: 'flex-start',
              }}
            >
              <div
                style={{
                  color: "#333338",
                  fontSize: 16,
                  fontWeight: "bold",
                  marginBottom: 10,
                }}
              >
                {t('delete-advice-title')}
              </div>
              <div
                style={{
                  color: "#333338",
                  fontSize: 13,
                  marginBottom: 36,
                }}
              >
                {t('delete-advice-are-you-sure')}
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                width: "100%"
              }}
            >
              <button
                onClick={() => {
                  this.setState({ isModalOpen: false });
                }}
                className="btn-primary"
                style={{
                  width: "40%",
                  backgroundColor: "#979797",
                  color: "#FFFFFF",
                  marginRight: 15
                }}
              >
                {t('keep-advice')}
              </button>
              <button
                style={{
                  width: "40%",
                  margin: 0,
                }}
                onClick={() => {
                  this.props.favouriteRemoveAdvice(ele.id);
                  this.setState({ isModalOpen: false });
                }}
                className="btn-primary"
              >
                {t('delete-advice')}
              </button>
            </div>
          </div>
        </Modal>
      }
      <div      

        onClick={()=> loadAdvice(ele.id)}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "100%"
        }}
      >
        <img
          className={"card-image"}
          width={80}
          height={100}
          src={ele.result.Images.Fertiliser}
          alt={ele.result.FertiliserId}
        />
        <div
          className={'truncate-text'}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: '100%',
            padding: "6px 12px 12px 0",

          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              height: "1.4em",
            }}
          >
            <div
              style={{
                opacity: 0.5,
                color: "#333338",
                fontSize: 10,
                fontWeight: "bold",
                height: "1.5em",
              }}
            >
              {this.formatDate(ele.id)}
            </div>
            <div
              style={{
                marginLeft: ".5em",
                cursor: "pointer",
                color: "#333338",
                fontSize: 16,
                fontWeight: "bold",
                lineHeight: 1,
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.setState({isModalOpen: true});
              }}
            >
              <img src={icons.trash} alt={""} />
            </div>
          </div>
          <div
            className={'truncate-text'}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <div
              className={'truncate-text'}
              style={{
                color: "#333338",
                fontSize: 16,
                fontWeight: "bold",
                letterSpacing: 0,
                width: "100%",
              }}
            >
              {ele.name || ele.machineHistory.Name}
            </div>
            <div
              style={{
                color: '#333338',
                fontSize: 13,
                fontWeight: 400,
                letterSpacing: 0,
                width: "100%",
              }}
            >
              <div className={"truncate-text"}>{ele.result.FertiliserName}</div>
              <div className={"truncate-text"}>
                {ele.result.FertiliserShape}
              </div>
              <div className={"truncate-text"}>
                {
                  [
                    `${workingWidth} ${t('unit_distance')}`,  
                    `${drivingSpeed} ${t('unit_speed')}`,
                    `${ele.result.FertiliserDensity} ${t('unit_density')}`
                  ].join(', ')
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    t: selectors.getTranslations(state),
  };
};

const actions = {
  favouriteRemoveAdvice
};

HistoryCard = connect(mapStateToProps, actions)(HistoryCard);

class TableHistory extends Component {
  render() {
    let {t, items, loadAdvice} = this.props;
      return <div className='tableahistory' ref='tableahistory'>
      { (items.length > 0) && <div className='tableahistory__wrapper'>
        <div className='tableahistory__table'>
          {
            items.sort((a, b) => b.id - a.id).map((ele, i) =>
              <div
                key={'r' + i}
                style={{marginBottom: 15}}
              >
                <HistoryCard index={i} ele={ele} loadAdvice={loadAdvice}/>
              </div>
          )}
        </div>
      </div> }
      { (items.length === 0) &&
            <div className='tableahistory__noresults'>
              {t('history_not_stored')}
      </div>}
    </div>
  }
}

export default TableHistory;
