import React from 'react';
import {Link} from 'react-router-dom';

import Menu from './menu';
import Languages from './languages';

import './scss/header.scss';


const Header = ({t, languages, currentLanguage, activateLanguage, brand,
  showHelp, platform}) =>

  <header className='header'>
    <div className='header__preheader'>
      <Languages
        languages={languages}
        currentLanguage={currentLanguage}
        activateLanguage={activateLanguage}
      />
    </div>
    <div className='header__logo'>
      <Link to={`/${brand}/home/`}></Link>
    </div>
    <Menu brand={brand} t={t} showHelp={showHelp} platform={platform} />
  </header>

export default Header;
