export const getPlatform = (state) =>
  state.app.platform;

export const getWebview = (state) =>
  state.app.webview;

export const getBrowser = (state) =>
  state.app.browser;

export const getBrand = (state) =>
  state.app.brand;

export const isLoading = (state) =>
  state.app.loading;

export const getModalMessage = (state) =>
  state.app.modalMessage;

export const isInitialized = (state) =>
  state.app.initialized;

export const isDisclaimerAccepted = (state) =>
  state.app.disclaimerAccepted;

export const isAppPopupClosed = (state) =>
  state.app.appPopupClosed;

export const isHelpVisible = (state) =>
  state.app.helpVisible;

export const getApiDatabaseVersion = (state) =>
  state.app.apiDatabaseVersion;

export const getApiVersion = (state) =>
  state.app.apiVersion;

export const getAppDownloadInfo = (state) =>
  state.app.appDownloadInfo;

export const getHelpSection = (state) =>
  state.app.helpSection;
