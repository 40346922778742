import React, {Component} from 'react';
import Proptypes from 'prop-types';

import './scss/inputrange.scss';

class InputRange extends Component {

  constructor(props) {
    super(props);
    const defValue = (props.defaultValue) ? props.defaultValue : 0;
    this.state = {value: defValue, error: null};
  }

  componentWillReceiveProps({defaultValue}) {
    if(this.props.defaultValue !== defaultValue) {
      this.setState({value: defaultValue});
    }
  }

  handleChange = (e) => {
    const { onChange, maxLength } = this.props;
    const { value, validity } = e.target;
    const validatedValue = validity.valid && value.length <= maxLength ? value : this.state.value;
    const replaceCommaForDot = validatedValue.toString().replace(',', '.');
    const result = this.updateValue(replaceCommaForDot);

    this.setState({
      ...this.state,
      value: result,
    });

    onChange(replaceCommaForDot);
  };

  handleFocus = () => {
    const { value } = this.state;
    const { toggleHideAdviceBar } = this.props;
    toggleHideAdviceBar && toggleHideAdviceBar(true);

    if(value === 0) {
      this.setState({error: null, value: '', last: value});
    } else {
      this.setState({error: null, last: null});
    }
  };

  handleBlur = () => {
    const {last, value} = this.state;
    const { toggleHideAdviceBar } = this.props;
    toggleHideAdviceBar && toggleHideAdviceBar(false);

    if(value === '' && last !== null) {
      this.setState({value: last}, () => this.validate());
    } else {
      this.validate();
    }
  };

  updateValue = (value, checkFloatingPoint = false) => {
    const {modifyBeforeUpdate} = this.props;
    const v = modifyBeforeUpdate ? modifyBeforeUpdate(value) : value;

    if(isNaN(v)) {
      return 0;
    }

    if(checkFloatingPoint) {
      if(typeof v === "string") {
        const str = v.split(".");

        if(str.length !== 1 && !str[1]) {
          return Number(str[0]);
        }
      }
    }
    return v;
  };

  validate() {
    const { min, max, onChange, canBeZero, defaultValue } = this.props;
    const { value } = this.state;
    const result = this.updateValue(value, true);

    if(canBeZero === true && (Number(result) === 0 || result === '')) {
      this.setState({error: null, value: 0});
      onChange(0);
    } else if(min > result) {
      onChange(min);
      this.setState({error: 'min', value: defaultValue});
    } else if(max < result) {
      onChange(max);
      this.setState({error: 'max', value: defaultValue});
    } else {
      this.setState({error: null, value: result});
      onChange(result);
    }
  }

  isValid() {
    return this.state.error === null;
  }

  render() {
    const {value: v, error} = this.state;
    const {label, errorMsg, help, icon, borderColor} = this.props;
    const value = this.updateValue(v);

    return <div
      className='inputrange'
      style={{
        paddingBottom: error && errorMsg && "30px"
      }}
    >
      <label className='truncate-text inputrange__label'>{label}</label>
      <div
        className={`inputrange__input
        ${error ? "inputrange__input--error" : ""}`}
        style={{
          borderColor: borderColor
        }}
        onClick={() => this.refs.input.focus()}
      >
        {icon &&
        <img
          className="icon"
          alt="icon"
          src={icon}
        />}
        <input
          type="text"
          inputMode="decimal"
          pattern="[0-9,.]*"
          value={value}
          ref='input'
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          onChange={this.handleChange}
        />
        <span
          style={{
            paddingRight: !icon && '12px'
          }}>
          {help}
        </span>
        {error &&
        <div className='inputrange__error'>
          {errorMsg}
        </div>}
      </div>
    </div>;
  }
}

InputRange.propTypes = {
  label: Proptypes.string,
  maxLength: Proptypes.number.isRequired,
  icon: Proptypes.string,
  min: Proptypes.number,
  max: Proptypes.number,
  errorMsg: Proptypes.string,
  defaultValue: Proptypes.number,
};

export default InputRange;
