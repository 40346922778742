import * as constants from '../constants';
import * as Api from '../../api';
import {trackError} from './app';

const fetchIconsStarted = () => ({
  type: constants.FETCH_ICONS_STARTED,
});
const fetchIconsSuccess = (payload) => ({
  type: constants.FETCH_ICONS_SUCCESS,
  payload
});

export const fetchIcons = () => (dispatch) => {
  dispatch(fetchIconsStarted());
  return Api.getIcons()
    .then((r) => dispatch(fetchIconsSuccess(r)))
    .catch(err => dispatch(trackError(err)));
};
