export const isFetchingMachines = (state) => {
  return state.machines.fetching;
};
export const getCategories = (state) => {
  return state.machines.categories;
};

export const getMachines = (state) => {
  return state.machines.machines;
};

export const getMachine = (state, id) => {
  return state.machines.machines[id];
};

export const getMachinesList = (state) => {
  return state.machines.machinesList
};

export const getSearchedMachine = (state) => {
  return state.machines.searchMachine
};

export const getCurrentMachine = (state) => {
  let id = state.machines.currentMachine;
  return id === 0 ? null : getMachines(state)[id];
};

const getPreviousMachineSettings = ({ machines }) => machines.previousMachineSettings;


export const getCategoriesAncestors = (state) =>
  state.machines.ancestors;

export const getCategoryBreadcrumb = (state, machineId=null) => {
  if(!machineId) {
    return [];
  }
  let ancestors = getCategoriesAncestors(state);
  let breadcrumb = [];
  let current = ancestors[machineId];
  let count = 0;
  while(current !== 0 && count<10) {
    breadcrumb.unshift(current);
    current = ancestors[current];
    count++;
  }
  return breadcrumb;
};

export const getDefaultWorkingWidth = (state, machineId) => {
  const machines = getMachines(state);
  const previousMachineSettings = getPreviousMachineSettings(state);
  const isMachineSettingStored = machineId && previousMachineSettings && previousMachineSettings[machineId];
  return isMachineSettingStored ? previousMachineSettings[machineId].WorkingWidth : machines[machineId].DefaultWorkingWidth;
};

export const getDefaultDrivingSpeed = (state, machineId) => {
  const machines = getMachines(state);
  const previousMachineSettings = getPreviousMachineSettings(state);
  const isMachineSettingStored = previousMachineSettings && previousMachineSettings[machineId]
    && previousMachineSettings[machineId].DrivingSpeed;
  return isMachineSettingStored ? previousMachineSettings[machineId].DrivingSpeed : machines[machineId].DefaultDrivingSpeed;
};

export const getDefaultApplicationRate = (state, machineId) => {
  const previousMachineSettings = getPreviousMachineSettings(state);
  const isMachineSettingStored = previousMachineSettings && previousMachineSettings[machineId]
    && previousMachineSettings[machineId].ApplicationRate;
  return isMachineSettingStored && previousMachineSettings[machineId].ApplicationRate;
};

export const getWorkingLimits = (state, machineId) => {
  const machines = getMachines(state);
  const MinWorkingWidth = machines[machineId].MinWorkingWidth;
  const MaxWorkingWidth = machines[machineId].MaxWorkingWidth;
  return {min:MinWorkingWidth, max:MaxWorkingWidth };
};

export const getMachineName = (state, id) => {
  const machines = getMachines(state);
  if(id in machines) {
    return machines[id].Name
  }
  return null;
};

export const machineHasSpout = (state, id) => {
  const machines = getMachines(state);
  if(id in machines) {
    return machines[id].HasSpout
  }
  return false;
};

export const machineCanTopDress = (state, id) => {
  const machines = getMachines(state);
  if(id in machines) {
    return machines[id].CanTopDress
  }
  return false;
};

