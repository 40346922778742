import React, {Component} from 'react';
import PropTypes from 'prop-types';

import icons from './img/icons';

/*
iconName: an exported field name of the img icons import

Usage examples:

  <IconButton iconName={"file"} title={"Download"} />
  <IconButton iconName={"file"} title={"Download"} width={"20%"}/>
  <IconButton iconName={"email"} />
  <IconButton title={"Download"} />
*/
class IconButton extends Component {
  render() {
    const {iconName, title, width, textColor, bgColor, onClick, disabled,
    gaCategory, gaAction, gaLabel} = this.props;
    const buttonHeight = 30;

    return <div
      className={'flex flex-center icon__button'}
      style={{
        flexWrap: 'nowrap',
        height: buttonHeight,
        color: textColor || '#333338',
        fontSize: '12px',
        fontWeight: 'bold',
        borderRadius: 4,
        backgroundColor: bgColor || '#FFFFFF',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,0.2)',
        width,
        padding: 7,
        cursor: disabled ? 'not-allowed' : 'pointer',
        maxWidth: 230
      }}
      data-disabled={disabled}
      
      ga-on="click"
      ga-event-category={gaCategory}
      ga-event-action={gaAction}
      ga-event-label={gaLabel}

      onClick={() => !disabled && onClick && onClick()}
    >
      {
        iconName && <div
          className={'flex flex-center flex-column icon__button__icon'}
          style={{ width: 18, height: 14, marginRight: title ? 8 : 0 }}
        >
          <img 
            src={icons[iconName]}
            style={{            
              maxHeight: buttonHeight-10,
            }} 
            alt="" />
        </div>
      }
      {
        title && <div
          className={'truncate-text'}
          style={{
            maxWidth: '80%',
            color: textColor || '#333338',
            fontSize: 12,
            fontWeight: 'bold',
            display: 'inline-block',
            height: buttonHeight,
            lineHeight: `${buttonHeight + 4}px`,
          }}
        >
          {title}
        </div>
      }
    </div>;
  }
}

IconButton.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onClick: PropTypes.func,

  gaCategory: PropTypes.string,
  gaAction: PropTypes.string,
  gaLabel: PropTypes.string,
};

IconButton.defaultProps = {
  iconName: "",
  title: "",
  width: "auto",

  gaCategory: "Undefined",
  gaAction: "Undefined",
  gaLabel: "Undefined",
};


export default IconButton;
