import * as constants from '../constants';
import * as Api from '../../api';
import {trackError, preloadStarted, preloadFinished} from './app';


const fetchAutosetappStarted = () => ({
  type: constants.FETCH_AUTOSETAPP_STARTED,
});
const fetchAutosetappSuccess = (r) => ({
  type: constants.FETCH_AUTOSETAPP_SUCCESS,
  payload: {autosetapp: r}
});
export const fetchAutosetapp = () => (dispatch, getState) => {
  dispatch(preloadStarted());
  dispatch(fetchAutosetappStarted());
  return Api.getAutosetapp()
    .then((r) => {
      dispatch(preloadFinished());
      return dispatch(fetchAutosetappSuccess(r));
    })
    .catch(err => dispatch(trackError(err))
  );
};
