import { combineReducers } from 'redux';

import app from './app';
import settings from './settings';
import locales from './locales';
import machines from './machines';
import shapes from './shapes';
import currentAdvice from './currentadvice';
import sharedAdvice from './sharedAdvice';
import favourites from './favourites';
import icons from './icons';
import otherapps from './otherapps';
import autosetapp from './autosetapp';
import ressellers from './ressellers';
import videos from './videos';
import faq from './faq';
import spreaderVideos from './spreadervideos';


const spreaderApp = combineReducers({
  app,
  settings,
  locales,
  machines,
  shapes,
  currentAdvice,
  sharedAdvice,
  favourites,
  icons,
  otherapps,
  autosetapp,
  ressellers,
  videos,
  faq,
  spreaderVideos,
});

export default spreaderApp;
