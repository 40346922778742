import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import * as constants from '../store/constants';

import './scss/menu.scss';

import sVicon from './img/vicon-slogan.png';
import sKverneland from './img/kverneland-slogan.png';

const slogans = {kverneland: sKverneland, vicon:sVicon};

export default class Menu extends Component {

  state = {menuVisible: false}

  toggleMenu() {
    let {menuVisible} = this.state;
    this.setState({menuVisible:!menuVisible});
  }

  closeMenu() {
    this.setState({menuVisible:false});
  }

  render() {
    let {brand, t, showHelp, platform} = this.props;
    let {menuVisible} = this.state;

    return <div className={'menu'+((menuVisible) ? ' menu--visible' : '')}>
      <nav className='menu__items'>
        <ul>
          <li><NavLink
            onClick={()=>this.closeMenu()}
            to={`/${brand}/home/`}
          >{t('module_title_home')}</NavLink></li>
          { (platform === constants.PLATFORM_DESKTOP) && <li><NavLink
            onClick={() => this.closeMenu()}
            to={`/${brand}/autosetapp/`}
          >{t('menu_autosetapp')}</NavLink></li>}
          <li><a
            onClick={()=> {
              showHelp();
              this.closeMenu();
            }}
          >{t('menu_help')}</a></li>
          <li><NavLink
            onClick={() => this.closeMenu()}
            to={`/${brand}/about/`}
          >{t('about_page_title')}</NavLink></li>
          {
            //Don't show for kubota: JIRA SED3740-731
            //brand !== 'kubota' &&
            //<li><NavLink
            //  onClick={() => this.closeMenu()}
            //  to={`/${brand}/otherapps/`}
            //>{t('menu_other_apps')}</NavLink></li>
          }
          <li><NavLink
            onClick={() => this.closeMenu()}
            to={`/${brand}/faq/`}
          >{t('menu_faq')}</NavLink></li>
          <li><NavLink
            onClick={() => this.closeMenu()}
            to={`/${brand}/contact/`}
          >{t('menu_contact')}</NavLink></li>
        </ul>
        { (brand!=='kubota') && <img className='menu__slogan' src={slogans[brand]} alt="" /> }
      </nav>
      <button
        className="menu__toggle"
        type="button"
        onClick={()=>this.toggleMenu()}
      >
        <span></span>
        <span></span>
        <span></span>
      </button>
    </div>
  }
}
