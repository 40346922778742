import React, { Component } from 'react';
import { HelpIconNode } from '../help';
export class AdviceHelp extends Component {
  render() {
    let { icons, t } = this.props;

    return <div>
      <h2>
        {t("help_advice_title")}
      </h2>
      <p>
        {t("help_advice_description")}
      </p>

      <HelpIconNode
        icon={icons.WorkingWidth}
        title={t('results-overall-workingwidth-title')}
        text={t('results-overall-workingwidth-description')} />

      <HelpIconNode
        icon={icons.DrivingSpeed}
        title={t('results-overall-driving-speed-title')}
        text={t('results-overall-driving-speed-description')} />

      <HelpIconNode
        icon={icons.ApplicationRate}
        title={t('results-overall-application-rate-title')}
        text={t('results-overall-application-rate-description')} />

      <HelpIconNode
        icon={icons.Dosing}
        title={t('results-advice-dosing-title')}
        text={t('results-advice-dosing-description')} />

      <HelpIconNode
        icon={icons.Timing}
        title={t('results-advice-timing-title')}
        text={t('results-advice-timing-description')} />

      <HelpIconNode
        icon={icons.Flow}
        title={t('results-advice-flow-title')}
        text={t('results-advice-flow-description')} />

      <HelpIconNode
        icon={icons.Container}
        title={t('results-advice-container-title')}
        text={t('results-advice-container-description')} />

      <HelpIconNode
        icon={icons.PtoRpm}
        title={t('results-advice-ptorpm-title')}
        text={t('results-advice-ptorpm-description')} />

      <HelpIconNode
        icon={icons.DiscRpm}
        title={t('results-advice-discrpm-title')}
        text={t('results-advice-discrpm-description')} />

      <HelpIconNode
        icon={icons.Inclination}
        title={t('results-advice-inclination-title')}
        text={t('results-advice-inclination-description')} />

      <HelpIconNode
        icon={icons.GeoPoint}
        title={t('results-advice-convergencepoint-title')}
        text={t('results-advice-convergencepoint-description')} />

      <HelpIconNode
        icon={icons.FineDosing}
        title={t('results-advice-finedosing-title')}
        text={t('results-advice-finedosing-description')} />

      <HelpIconNode
        icon={icons.ToBorder}
        title={t('result-panel-toborder-header')}
        text={t('results-border-toborder-description')} />

      <HelpIconNode
        icon={icons.PtoRpm}
        title={t('results-advice-ptorpm-title')}
        text={t('results-border-ptorpm-description')} />

      <HelpIconNode
        icon={icons.DiscRpm}
        title={t('results-advice-discrpm-title')}
        text={t('results-border-discrpm-description')} />

      <HelpIconNode
        icon={icons.ToBorderSetting}
        title={t('results-advice-toborder-title')}
        text={t('results-border-setting-description')} />

      <HelpIconNode
        icon={icons.ApplicationRate}
        title={t('results-overall-application-rate-title')}
        text={t('results-border-application-rate-description')} />

      <HelpIconNode
        icon={icons.Timing}
        title={t('results-advice-timing-title')}
        text={t('results-border-timing-description')} />

      <HelpIconNode
        icon={icons.Yield}
        title={t('results-boundary-yield')}
        text={t('results-border-yield-description')} />

      <HelpIconNode
        icon={icons.Eco}
        title={t('results-boundary-eco')}
        text={t('results-border-eco-description')} />

      <HelpIconNode
        icon={icons.H2O}
        title={t('results-boundary-h2o')}
        text={t('results-border-h2o-description')} />

      <HelpIconNode
        icon={icons.FromBorder}
        title={t('result-panel-fromborder-header')}
        text={t('results-border-fromborder-description')} />

      <HelpIconNode
        icon={icons.PtoRpm}
        title={t('results-advice-ptorpm-title')}
        text={t('results-fromborder-ptorpm-description')} />

      <HelpIconNode
        icon={icons.DiscRpm}
        title={t('results-advice-discrpm-title')}
        text={t('results-fromborder-discrpm-description')} />

      <HelpIconNode
        icon={icons.FromBorderSetting}
        title={t('results-advice-fromborder-title')}
        text={t('results-fromborder-setting-description')} />


      <HelpIconNode
        icon={icons.Eco}
        title={t('results-boundary-eco')}
        text={t('results-fromborder-eco-description')} />

      <HelpIconNode
        icon={icons.H2O}
        title={t('results-boundary-h2o')}
        text={t('results-fromborder-h2o-description')} />
    </div>;
  }
}
