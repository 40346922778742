import {Base64} from "js-base64";

let baseUrl = process.env.REACT_APP_BASE_URL;
if('DATA' in window && 'api_url' in window['DATA']) {
  baseUrl = window['DATA'].api_url;
}
// const app = process.env.REACT_APP_APP;
const auth = Base64.encode(`${process.env.REACT_APP_API_USER}:${process.env.REACT_APP_API_PASS}`);

const objectKeysToQueryParams = (obj) =>
  Object.entries(obj).reduce((acc, [key, value], index) =>
      (value)
        ? `${acc}${index !== 0 ? '&' : ''}${key}=${value}`
        : acc
    , '');

const request = (u, method='GET', data) =>
  new Promise((resolve, reject) => {
    let url = baseUrl+u;
    let params = {
      method: method,
      headers: new Headers({
        'Authorization': 'Basic '+ auth,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'credentials': 'include'
      })
    };
    if(method==='POST') {
      params['body'] = JSON.stringify(data);
    }
    fetch(url, params)
    .then((response) => {
      if (response.status === 200) {        
        if(method==='POST')
        {
          return resolve({});
        }
        else
        {
          return resolve(response.json());
        }
      } else {
        if (response.ok) {
          return resolve({});
        }
      }
      return reject(response);
    })
    .catch((error) => reject(error));
  });


export const getLanguages = (brand) => {
  const url = `/Localisation/Languages?brand=${brand}`;
  return request(url);
};

export const getTranslations = (lang, brand, unit) => {
  const url = `/Localisation/Translations?brand=${brand}&language=${lang}&units=${unit}`;
  return request(url);
};

export const getIcons = () => {
  return request('/Icons');
};

export const getSettings = (units) => {
  const url = `/Database/Settings?units=${units}`;
  return request(url);
};

export const getMachines = (brand, units) => {
  const url = `/Spreader/Grouped?brand=${brand}&units=${units}`;
  return request(url);
};

export const getMachine = (machine, units) => {
  const url = `/Spreader/${machine}?units=${units}`;
  return request(url);
};

export const validateField = (
  units,
  machine,
  {
    WorkingWidth: workingWidth,
    ApplicationRate: applicationRate,
    DrivingSpeed: drivingSpeed
  } = {}
) => {
  const url = `/Spreader/${machine}/FieldSettingsInRange?${objectKeysToQueryParams({
    applicationRate,
    drivingSpeed,
    workingWidth,
    units,
  })}`;
  return request(url);
};

export const getMachineBySerialNumber = (serialNumber) => {
  const url = `/Spreader/Serial?serial=${serialNumber}`;
  return request(url);
};

export const getShapes = (lang) => {
  const url = `/Fertiliser/Shapes?language=${lang}`;
  return request(url);
};

export const getLegalLinks = (lang) => {
  const url = `/Localisation/LegalUrls?language=${lang}`;
  return request(url);
};

export const getApplications = (lang, units, machine, shape, field = {}, properties) => {
  const url = `/Advice/${machine}/Search?${objectKeysToQueryParams({
    width: field.WorkingWidth,
    fertiliserDensity: ('adviceDensity' in properties)
      ? properties.adviceDensity
      : null,
    fractioning: ('granuleSizeMinus2' in properties)
      ? [
        properties.granuleSizeMinus2,
        properties.granuleSizeB23,
        properties.granuleSizeB34,
        properties.granuleSize4
      ].join("-")
      : null,
    language: lang,
    units,
  })}&${shape && typeof shape !== 'string' &&
    shape.map((id) => `fertiliserShape=${id}`).join("&")}`;
  return request(url);
};

export const isFineDosage = (units, machine, field = {}) => {
  const url = `/Spreader/${machine}/FineDosageNecessary?${objectKeysToQueryParams({
    applicationRate: field.ApplicationRate,
    drivingSpeed: field.DrivingSpeed,
    workingWidth: field.WorkingWidth,
    units,
  })}`;
  return request(url);
};

export const getResult = (lang, units, machine, fertilizer, VaneSetId, kitUsed, field) => {
  const url = `/Chart/${machine}/${fertilizer}?${objectKeysToQueryParams({
    vanes: VaneSetId,
    workingWidth: field.WorkingWidth,
    applicationRate: field.ApplicationRate,
    drivingSpeed: field.DrivingSpeed,
    fineApplicationKitUsed: kitUsed,
    topDressMethod: field.applicationType,
    languageCode: lang,
    units
  })}`;
  return request(url);
};

export const getSharedResult = (args) => {
  const url = `/Chart/${args.machine}/${args.fertilizer}?${objectKeysToQueryParams(args)}`;
  return request(url);
};

export const getPdf = (lang, units, machine, fertilizer, VaneSetId, kitUsed, field) => {
  const url = `/Book/${machine}/${fertilizer}/DownloadLink?${objectKeysToQueryParams({
    drivingSpeed: field.DrivingSpeed,
    workingWidth: field.WorkingWidth,
    vaneSetId: VaneSetId,
    fineApplicationKitUsed: kitUsed,
    language: lang,
    units
  })}`;
  return request(url);
};

export const getAutosetapp = () => {
  const url = `/Database/AutoSetAppUpdate`;
  return request(url);
};

export const getOtherApps = (lang, brand) => {
  const url = `/Apps?brand=${brand.toUpperCase()}`;
  return request(url);
};

export const getRessellers = (lang, brand, unit) => {
  const url = `/Resellers?brand=${brand.toUpperCase()}&language=${lang}&units=${unit}`;
  return request(url);
};

export const getApiVersion = () => {
  const url = '/Version';
  return request(url);
};

export const getVideos = (lang, brand, unit) => {
  const url = `/Videos?brand=${brand.toUpperCase()}&language=${lang}&units=${unit}`;
  return request(url);
};

export const getSpreaderVideos = (machine, lang) => {
  const url = `/Spreader/${machine}/Videos?language=${lang}`;
  return request(url);
};

export const getFAQ = (brand, units, lang) => {
  const url = `/FAQ?brand=${brand}&language=${lang}&units=${units}`;
  return request(url);
};

export const getAppDownloadInfo = (brand, lang) => {
  const url = `/AppStoreLinks?brand=${brand}&language=${lang}`;
  return request(url);
};

export const getRawTranslations = (lang) => {
  const url = `/Localisation/RawTranslations?code=${lang}`;
  return request(url);
};

export const updateRawTranslations = (trans) => {
  const url = `/Localisation/RawTranslations`;
  return request(url, 'POST', trans);
};

export const getShapeRanges = (machineId, units) => {
  const url = `/Advice/${machineId}/ShapeRanges?units=${units}`;
  return request(url);
};