import React, { Component } from 'react';
import {connect} from 'react-redux';

import * as utils from '../utils';
import * as selectors from '../store/selectors';
import {fetchRessellers} from '../store/actions/ressellers';

import HeaderImage from '../components/headerimage';
import faceImg from './img/facebook_button.png';
import twitImg from './img/twitter_button.png';
import youtbImg from './img/youtube_button.png';
import instagramImg from './img/instagram_button.svg';

import './scss/contact.scss';


class Contact extends Component {

  componentDidMount() {
    if(this.props.ressellers===null) {
      this.props.fetchRessellers();
    }
    utils.bodyClassList().add('whiteBackground');
  }
  componentWillUnmount() {
    utils.bodyClassList().remove('whiteBackground');
  }

  componentWillReceiveProps(props) {
    if(props.ressellers===null && this.props.ressellers!==null) {
      this.props.fetchRessellers();
    }
  }

  render () {
    let {t, match, ressellers} = this.props;

    if(ressellers===null){
      return null;
    }

    return <div className='contact'>
      <HeaderImage t={t} title="contact_page_title" brand={match.params.brand} />
      <div className='container contact__content'>
        <div className='contact__content__wrapper'>
          { ressellers.map((ite, i)=>
            <div key={'c'+i} className='contact__content__item'>
            <h2>{ite.Company}</h2>
            <p dangerouslySetInnerHTML={{__html: ite.Address}}></p>
            <ul>
              { (ite.Phone) && <li>
                <strong>{t('contact_telephone')}</strong>
                {ite.Phone}
              </li> }
              { (ite.Email) && <li>
                <strong>{t('contact_email')}</strong>
                <a href={`mailto:${ite.Email}`}>{ite.Email}</a>
              </li> }
              { (ite.Web) && <li>
                <strong>{t('contact_web')}</strong>
                <a
                  target="_blank" rel="noopener noreferrer"
                  href={ite.Web}>{ite.Web}</a>
              </li>}
            </ul>
          </div>)}
        </div>
        <div className="contact__content__sidebar">
          {(ressellers[0].Facebook || ressellers[0].Twitter || ressellers[0].Youtube) &&
          <h2>{t('social_media')}</h2>}
          { (ressellers[0].Facebook) && <a href={(ressellers[0].Facebook)} target="_blank" rel="noopener noreferrer">
            <img src={faceImg} alt="facebook"
                ga-on="click"
                ga-event-category="SocialMediaButton"
                ga-event-action="navigate"
                ga-event-label="facebook" />
          </a> }
          { (ressellers[0].Twitter) && <a href={ressellers[0].Twitter} target="_blank" rel="noopener noreferrer">
            <img src={twitImg} alt="twitter"
                ga-on="click"
                ga-event-category="SocialMediaButton"
                ga-event-action="navigate"
                ga-event-label="twitter"  />
          </a> }
          { (ressellers[0].Youtube) && <a href={ressellers[0].Youtube} target="_blank" rel="noopener noreferrer">
            <img src={youtbImg} alt="youtube"
                ga-on="click"
                ga-event-category="SocialMediaButton"
                ga-event-action="navigate"
                ga-event-label="youtube"  />
          </a> }
          { (ressellers[0].Instagram) && <a href={ressellers[0].Instagram} target="_blank" rel="noopener noreferrer">
            <img src={instagramImg} alt="instagram"
                ga-on="click"
                ga-event-category="SocialMediaButton"
                ga-event-action="navigate"
                ga-event-label="instagram"  />
          </a> }
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    t: selectors.getTranslations(state),
    ressellers: selectors.getRessellers(state)
  };
}
const actions = {
  fetchRessellers
}
Contact = connect(mapStateToProps, actions)(Contact);
export default Contact;
