import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter} from 'react-router-dom';

import * as selectors from '../store/selectors';
import * as constants from '../store/constants';
import {Step1, Step2, Step3, Step4, Step5} from './advicebarsteps';

import {fetchShapes} from '../store/actions/shapes';

import './scss/advicebar.scss';


class Advicebar extends Component {

  componentDidMount() {
    this.fetchShapes();
  }

  fetchShapes() {
    let {shapes, isFetching} = this.props;
    if(shapes.length===0 && isFetching===false) {
      this.props.fetchShapes();
    }
  }

  stepClass(step) {
    let {currentStep, stepCompleted} = this.props;
    let classes = 'advicebar__item';
    classes += ((step<stepCompleted) ? ' advicebar__item--success' : '');
    classes += ((step===currentStep) ? ' advicebar__item--current' : '');
    return classes;
  }

  render() {
    const {onNext, nextEnabled, t, currentStep, hidden, platform} = this.props;
    return (
    <div
      className='advicebar'
      style={{
        visibility: hidden && platform !== constants.PLATFORM_DESKTOP ? 'hidden' : 'initial'
      }}
    >
      <Step1
        stepClass={this.stepClass(1)}
        {...this.props}
      />
      <hr/>
      <Step2
        stepClass={this.stepClass(2)}
        {...this.props}
      />
      <hr/>
      <Step3
        stepClass={this.stepClass(3)}
        {...this.props} />
      <hr/>
      <Step4
        stepClass={this.stepClass(4)}
        {...this.props} />
      <hr/>
      <Step5
        stepClass={this.stepClass(5)}
        {...this.props} />
      {currentStep!==5 &&
      <div className='advicebar__item advicebar__item--button'>
        { <button
          className={'btn-mobile-rounded'+((!nextEnabled) ? ' btn-mobile-rounded--disabled' : '')}
          onClick={()=> (nextEnabled) ? onNext() : ''}
        >
          <svg viewBox="0 0 10.78 18.56"><use xlinkHref="#arrow" /></svg>
          {t('page_next')}
        </button> }
      </div>}
    </div>
    )
  }
}
const mapStateToProps = (state) => {
  const machineId = selectors.getCurrentMachineId(state);
  const shapeId = selectors.getCurrentShapeId(state);
  return {
    isFetching:selectors.isFetchingShapes(state),
    shapes: selectors.getShapes(state),
    
    stepCompleted: selectors.getCurrentStep(state),
    machineName: selectors.getMachineName(state, machineId),
    properties: selectors.getCurrentValidProperties(state),
    applicationType: selectors.getCurrentAppType(state),
    field: selectors.getCurrentField(state),
    hasSpout: selectors.machineHasSpout(state, machineId),
    shapeName: selectors.getShapeName(state, shapeId),
    application: selectors.getCurrentApplication(state),
    result: selectors.getCurrentResult(state),
    t: selectors.getTranslations(state),
    platform: selectors.getPlatform(state)
  };
};

const actions = {
  fetchShapes,
};

Advicebar = compose(
  withRouter,
  connect(mapStateToProps, actions)
)(Advicebar);

export default Advicebar;
