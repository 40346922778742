import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as utils from '../utils';
import * as selectors from '../store/selectors';
import {favouriteLoadAdvice} from '../store/actions/history';
import {newAdvice} from '../store/actions/currentadvice';
import {showModal, showHelp} from '../store/actions/app';
import './scss/home.scss';

import TableHistory from '../components/tablehistory';
import VideoList from '../components/videolist';
import icons from '../components/img/icons';


class Home extends Component {

  loadAdvice(id) {
    const {match, history, favouriteLoadAdvice} = this.props;
    favouriteLoadAdvice(id);
    history.push(`/${match.params.brand}/advice/5/`);
  }

  newAdvice() {
    const {match, history, newAdvice} = this.props;
    newAdvice();
    history.push(`/${match.params.brand}/advice/2/`);
  }

  formatDate(dt) {
    const date = utils.formatDate(dt);
    const hour = utils.formatHour(dt);
    return date + ' ' + hour;
  }

  render() {
    const {t, items, match, showHelp, legalLinks} = this.props;
    const {brand} = match.params;
    const title = t('app_title').replace('[Brand.Name]', brand);
    const path = `${window['DATA'].api_url}/2011/v1/NA/Images/Banners/`;

    return <div className='home'>
      <div className='home__header'>
        <div className='container'>
          <div className='home__header__box'>
            <div>
              <h2 style={{fontStretch: "condensed"}}>
                {title}
              </h2>
              <button              
                onClick={ ()=> this.newAdvice() }
                className='btn-primary'
                style={{fontSize: 18, height: 50,fontStretch: "condensed"}}
              >
                <span>{`${t('menu_new_advice')}`}</span>
                <span style={{
                  display: "inline-block",
                  marginLeft: ".5em",
                  lineHeight: "6px",
                  verticalAlign: "middle"
                }}>
                  <img src={icons.arrowLeft} alt={`${t('menu_new_advice')}`} />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className='home__header__picture'>
          <div
            style={{backgroundImage: `url(${path}Banner_XLarge_${brand.toUpperCase()}.jpg)`}}/>
        </div>
      </div>
      <div className='container home__content'>
        <VideoList />
        <div
          style={{
            marginTop: '4em',
          }}
        >
          <h2
            style={{
              marginBottom: 14,
              fontSize: 16,
              fontWeight: "bold",
              color: "#333338",
            }}
            className="advice__title"
          >
            <span>{t('history_page_title')}</span>
            <span
              style={{
                display: 'inline-block',
                marginLeft: '0.1em',
                color: '#FFC107',
                fontSize: 14,
                verticalAlign: "super"
              }}
            >
            <img src={icons.star} alt={""} />
          </span>
            <a
              className='advice__title__help'
              onClick={()=> showHelp('Favourites')}
            ><svg viewBox="0 0 30 30"><use xlinkHref="#help" /></svg></a>
          </h2>
          <TableHistory t={t} items={items} loadAdvice={(id) => this.loadAdvice(id)}/>
        </div>
        
        <div className="home_legal_container">
          <div className="home_legal_text">
            <a href={legalLinks && legalLinks.LegalInfoUrl}>{t('home-legal-notice')}</a> - <a href={legalLinks && legalLinks.CookieInfoUrl}>{t('home-legal-cookie')}</a>
          </div>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state) => {
  let units = selectors.getCurrentUnits(state);

  return {
    t: selectors.getTranslations(state),
    items: selectors.getFavourites(state, units),
    brand: selectors.getBrand(state),
    legalLinks: selectors.getLegalLinks(state)
  };
}
const actions = {
  favouriteLoadAdvice,
  newAdvice,
  showModal,
  showHelp
};
Home = connect(mapStateToProps, actions)(Home);
export default Home;
