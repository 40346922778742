import * as constants from '../constants';
import * as Api from '../../api';
import * as sactions from './settings';
import {trackError, preloadStarted} from './app';
import * as selectors from '../selectors';
import {fetchIcons} from './icons';

const fetchLanguagesStarted = () => ({
  type: constants.FETCH_LANGUAGES_STARTED,
});
const fetchLanguagesSuccess = (payload) => ({
  type: constants.FETCH_LANGUAGES_SUCCESS,
  payload
});

export const fetchLanguages = () => async (dispatch, getState) => {
  dispatch(fetchLanguagesStarted());
  try {
    const json = await Api.getLanguages(selectors.getBrand(getState()));
    return dispatch(fetchLanguagesSuccess(json));
  }
  catch (err) {
    return dispatch(trackError(err));
  }
};

export const fetchLegalLinks = () => async (dispatch, getState) => {
  dispatch(fetchLegalLinksStarted());
  let lang = selectors.getCurrentLanguageCode(getState());
  try{
    const json = await Api.getLegalLinks(lang);
    return dispatch(fetchLegalLinksSuccess(json));
  }
  catch (err) {
    return dispatch(trackError(err));
  }
};

const fetchLegalLinksStarted = () => ({
  type: constants.FETCH_LEGAL_LINKS_STARTED,
})

const fetchLegalLinksSuccess = (payload) => ({
  type: constants.FETCH_LEGAL_LINKS_SUCCESS,
  payload
})

const activateDefaultLanguage = () => ({
  type: constants.ACTIVATE_DEFAULT_LANGUAGE,
  payload: (navigator.languages)
    ? navigator.languages
    : [navigator.language]
});
export const activateLanguage = (languageId = null) => (dispatch, getState) => {
  if(languageId === null) {
    dispatch(activateDefaultLanguage());
  } else {
    var unit = selectors.getCurrentUnits(getState());
    dispatch({
      type: constants.ACTIVATE_LANGUAGE,
      payload: languageId
    });
    var newUnit = selectors.getCurrentUnits(getState());
    if(unit !== newUnit)
    {
      dispatch({
        type: constants.UNITS_CHANGED,
        payload: newUnit
      })
    }
  }
  dispatch(preloadStarted());
  dispatch({
    type: constants.ADD_DEFAULT_SETTINGS,
    payload: {
      lang: selectors.getCurrentLanguageCode(getState()),
      unit: selectors.getCurrentUnits(getState())
    }
  });
  return dispatch(fetchTranslations())
    .then(() => {
        dispatch(sactions.fetchSettings());
        dispatch(fetchLegalLinks());
        dispatch(fetchIcons());
      })
};

const fetchTranslationsStarted = () => ({
  type: constants.FETCH_TRANSLATIONS_STARTED,
});
const fetchTranslationsSuccess = (payload) => ({
  type: constants.FETCH_TRANSLATIONS_SUCCESS,
  payload
});
export const fetchTranslations = () => (dispatch, getState) => {
  dispatch(fetchTranslationsStarted());
  let lang = selectors.getCurrentLanguageCode(getState());
  let unit = selectors.getCurrentUnits(getState());
  let brand = selectors.getBrand(getState());
  return Api.getTranslations(lang, brand, unit)
    .then((r) => dispatch(fetchTranslationsSuccess(r)))
    .catch(err => dispatch(trackError(err)));

};


const fetchRawTranslationsStarted = () => ({
  type: constants.FETCH_RAW_TRANSLATIONS_STARTED,
});
const fetchRawTranslationsSuccess = (payload) => ({
  type: constants.FETCH_RAW_TRANSLATIONS_SUCCESS,
  payload
});
export const fetchRawTranslations = () => (dispatch, getState) => {
  dispatch(fetchRawTranslationsStarted());
  let lang = selectors.getCurrentLanguageCode(getState());
  return Api.getRawTranslations(lang)
    .then((r) => dispatch(fetchRawTranslationsSuccess(r)))
    .catch(err => dispatch(trackError(err)));
};

const updateRawTranslationsStarted = () => ({
  type: constants.UPDATE_RAW_TRANSLATIONS_STARTED,
});
const updateRawTranslationsSuccess = (payload) => ({
  type: constants.UPDATE_RAW_TRANSLATIONS_SUCCESS,
  payload
});
export const updateRawTranslations = () => async (dispatch, getState) => {
  dispatch(updateRawTranslationsStarted());
  let trans = selectors.getRawTranslations(getState());
  try {
    const r = await Api.updateRawTranslations(trans);
    dispatch(updateRawTranslationsSuccess(r));
    trans.forEach(element => {
      element.HasChanged = false;
    });
    dispatch(activateLanguage(selectors.getCurrentLanguage(getState()).id));
  }
  catch (err) {
    return dispatch(trackError(err));
  }
};