import React, { Component } from 'react';

import './scss/headerimage.scss';


class HeaderImage extends Component {

  render() {
    let {title, brand, t} = this.props;
    let path = `${window['DATA'].api_url}/Images/Website/Banners/`;

    return <div className='headerimage'>
      <h1 className='headerimage__title'>{t(title)}</h1>
      <div
        className='headerimage__picture desktop'
        style={{backgroundImage: `url(${path}Banner_Large_${brand.toUpperCase()}.jpg)`}}></div>
      <div
        className='headerimage__picture tablet'
        style={{backgroundImage: `url(${path}Banner_Medium_${brand.toUpperCase()}.jpg)`}}></div>
      <div
        className='headerimage__picture mobile'
        style={{backgroundImage: `url(${path}Banner_Small_${brand.toUpperCase()}.jpg)`}}></div>
    </div>
  }
}

export default HeaderImage;
