import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as selectors from '../store/selectors';

import './scss/resultadvice.scss';
import AdviceResultItem from './adviceresultitem';

import ExpendableCard from './expandableCard';
import VideoList from './videolist';

class ResultAdvice extends Component {

  getInclination() {
    const { result, currentAdvice} = this.props;
    const { applicationType } = currentAdvice;

    if(result.Inclinations && result.Inclinations.length > 0)
    {
      var filtered = result.Inclinations.filter(inc => inc.TopDressMethod === applicationType);
      if(filtered.length === 1)
        return filtered[0].Inclination;
    }

    return result.Inclination;
  }

  render() {
    const { table, t, machine, result, kitUsed, tablePos} = this.props;

    return <div className="resultadvice">
      <ExpendableCard header={t('result-panel-settings-header')} expanded={true} gaLabel={"AdviceSettings"}>
        <div className="resultadvice__values">
          <AdviceResultItem
            header={t('results-advice-dosing-title')}
            value={table[tablePos.y][tablePos.x].DosingSetting}
            ico={result.Images.Dosing}
            isLarge={true}
          />
          {
            (machine.HasTiming) &&
            <AdviceResultItem
              header={t('results-advice-timing-title')}
              value={table[tablePos.y][tablePos.x].Timing}
              ico={result.Images.Timing}
              isLarge={true}
              isValueCondensed={false}
            />
          }
          {
            !machine.HasSpout &&
            <AdviceResultItem
              header={t('results-advice-flow-title')}
              value={table[tablePos.y][tablePos.x].FlowSpreading}
              ico={result.Images.Flow}
              units={t('unit_flow')}
              isLarge={true}
            />
          }
          <AdviceResultItem
            header={t('results-advice-container-title')}
            value={table[tablePos.y][tablePos.x].FlowTesting}
            ico={result.Images.Container}
            units={t('unit_flow')}
            isLarge={true}
          />
          <AdviceResultItem
            header={t('results-advice-ptorpm-title')}
            value={result.PTOSpeed}
            ico={result.Images.PtoRpm}
            units={"rpm"}
            isLarge={true}
          />
          {
            !machine.HasSpout &&
            <AdviceResultItem
              header={t('results-advice-discrpm-title')}
              value={result.DiscSpeed}
              ico={result.Images.DiscRpm}
              units={'rpm'}
              isLarge={true}
            />
          }
          {
            !machine.HasSpout &&
            <AdviceResultItem
              header={t('results-advice-inclination-title')}
              value={this.getInclination()}
              ico={result.Images.Inclination}
              units={"°"}
              isLarge={true}
            />
          }
          {
            !machine.HasSpout &&
            <AdviceResultItem
              header={t('results-advice-convergencepoint-title')}
              value={result.ConvergencePoint}
              ico={result.Images.ConvergencePoint}
              units={t('unit_distance')}
              isLarge={true}
            />
          }
          <AdviceResultItem
            header={t('results-advice-finedosing-title')}
            value={(kitUsed) ? t('button_yes') : t('button_no')}
            ico={result.Images.FineDosing}
            isLarge={true}
          />
          {
            (result.Images.TransmissionValue) &&
            <AdviceResultItem
              header={t('results-advice-transmission-title')}
              value={result.Images.TransmissionValue}
              isImageValueType={true}
              ico={result.Images.Transmission}
              isLarge={true}
            />
          }
        </div>
        
        <VideoList  spreaderId={ machine.Id + '' } />
      </ExpendableCard>
    </div>
  }
}
const mapStateToProps = (state) => {
  return {
    t: selectors.getTranslations(state),
    table: selectors.getCurrentTable(state),
    tablePos: selectors.getCurrentTablePos(state),
    settings: selectors.getCurrentField(state),
    appType: selectors.getCurrentAppType(state),
    result: selectors.getCurrentResult(state),
    kitUsed: selectors.isCurrentKitUsed(state),
    currentAdvice: selectors.getCurrentAdvice(state),
  };
};

const actions = {
};

ResultAdvice = connect(mapStateToProps, actions)(ResultAdvice);

export default ResultAdvice;
