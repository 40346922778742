import * as constants from '../constants';

const initial = {
  fetching: false,
  currentMachine: null,
  machines: {},
  machinesList: [],
  searchMachine: {
    serialNumber: "",
    notFound: false,
    result: null,
  },
  ancestors: {},
  previousMachineSettings: {}
};

const machines = (state = initial, action) => {
  switch (action.type) {
    case constants.FETCH_MACHINES_SUCCESS: {
      return {
        ...state,
        machinesList: action.payload,
        machines: action.payload.flatMap(m => m.Models).reduce((map, mach) => {
          map[mach.Id] = mach;
          return map;
        }, {})
      }
    }
    case constants.FETCH_MACHINE_SUCCESS: {
      let {payload} = action;
      let machines = Object.assign({}, state.machines, {
        [payload.Id]: payload
      });
      let currentMachine = payload.Id;
      return {
        ...state,
        machines,
        currentMachine
      }
    }
    case constants.MACHINE_SELECTED: {
      let currentMachine = action.payload;
      return {
        ...state,
        currentMachine
      }
    }
    case constants.FETCH_MACHINE_BY_SERIAL_STARTED: {
      return {
        ...state,
        searchMachine: {
          ...state.searchMachine,
          serialNumber: action.payload,
        }
      }
    }
    case constants.FETCH_MACHINE_BY_SERIAL_SUCCESS: {
      if(action.payload.Result === "OK") {
        return {
          ...state,
          searchMachine: {
            ...state.searchMachine,
            result: action.payload.Model,
            notFound: false
          }
        }
      }
        return {
          ...state,
          searchMachine: {
            ...state.searchMachine,
            result: null,
            notFound: true
          }
        }
      }
    case constants.CLEAR_MACHINE_SEARCH: {
      return {
        ...state,
        searchMachine: {
          notFound: false,
          result: null
        }
      }
    }
    case constants.API_OUT_OF_DATE:
    case constants.UNITS_CHANGED: {
      let {ancestors} = state;
      return Object.assign({}, initial, ancestors);
    }
    case constants.ADVICE_UPDATE_FIELD:
      const {payload} = action;
      const {machineId, name, value} = payload;
      if(payload.name==='WorkingWidth' || payload.name==='DrivingSpeed' || payload.name==='ApplicationRate') {
        return {
          ...state,
          previousMachineSettings: {
            ...state.previousMachineSettings,
            [machineId]: {
              ...state.previousMachineSettings[machineId],
              [name]: value
            }
          }
        }
      }
        return state;
    default: {
      return state;
    }
  }
};
export default machines;
