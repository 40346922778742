import React from 'react';

const MachineItem = ({imageUrl, name, clickMachine, id}) =>
  <a
    className='machineslist__item__machine hover-cursor'
    onClick = {() => clickMachine(id)}
  >
    <div
      className='machineslist__item__image'
      style={{backgroundImage:`url(${imageUrl})`}}>
    </div>
    <h3>{name}</h3>
  </a>;

export default MachineItem;
