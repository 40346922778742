import * as constants from '../constants';

const initial = {
  current: null,
  languages: [],
  legalLinks: null,
  translations: {},
  rawTranslations: [],
  fallback: null
};

const locales = (state = initial, action) => {
  switch (action.type) {
    case constants.FETCH_LANGUAGES_SUCCESS: {
      let languages = action.payload.map((l) => ({
        code: l.LanguageId,
        id: l.Id,
        units: (l.UnitSystem===0) ? constants.METRIC : constants.IMPERIAL,
        name: l.LocalLanguageDescription,
        flag: l.SmallFlag
      }));
      let fallback = languages.filter((l)=> l.code === 'en');
      return Object.assign({}, state, {languages, fallback: fallback[0]});
    }
    case constants.ACTIVATE_DEFAULT_LANGUAGE: {
      let langs = action.payload;
      let {languages} = state;
      let matched = [];
      for(let i=0;i<langs.length;i++) {
        let normalized = langs[i].split("-")[0];
        matched = languages.filter((l)=> l.code === normalized);
        if(matched.length>=1) {
          break;
        }
      }
      let current = state.fallback;
      if(matched.length>0) {
        current = matched[0];
      }
      return Object.assign({}, state, {current});
    }
    case constants.ACTIVATE_LANGUAGE: {
      let id =  action.payload;
      let language = state.languages.filter((l) => l.id === id);
      return Object.assign({}, state, {
        current: language[0],
        rawTranslations: [],
        legalLinks: null
      });
    }
    case constants.FETCH_TRANSLATIONS_SUCCESS: {
      let translations = {};
      action.payload.forEach((t) => {
        translations[t.Id.toLowerCase()] = t.Val;
      })
      return Object.assign({}, state, {translations});
    }
    
    case constants.FETCH_RAW_TRANSLATIONS_SUCCESS: {
      let rawTranslations = action.payload;
      return Object.assign({}, state, {rawTranslations});      
    }

    case constants.FETCH_LEGAL_LINKS_SUCCESS: {
      let legalLinks = action.payload;
      return Object.assign({}, state, {legalLinks});   
    }

    case constants.API_OUT_OF_DATE: {
      return Object.assign({}, state, {translations:{}, languages: [], rawTranslations: [], legalLinks: null});
    }
    default: {
      return state;
    }

  }
}
export default locales;
