import React, { Component } from 'react';
import { HelpIconNode } from '../help';
export class FieldHelp extends Component {
  render() {
    let { icons, t } = this.props;

    return <div>
      <h2>
        {t("page_title_field_settings")}
      </h2>

      <p>{t("help-field-description")}</p>

      <HelpIconNode
        icon={icons.WorkingWidth}
        title={t('fieldsettings_working_width')}
        text={t('help-field-workingwidth-description')} />


      <HelpIconNode
        icon={icons.ApplicationRate}
        title={t('fieldsettings_rate')}
        text={t('help-field-applicationrate-description')} />

      <HelpIconNode
        icon={icons.DrivingSpeed}
        title={t('fieldsettings_speed')}
        text={t('help-field-drivingspeed-description')} />

      <HelpIconNode
        title={t('fieldsettings_application')}
        text={t('help-field-applicationtype-description')} />
    </div>;
  }
}
