import React, {Component} from 'react';
import {connect} from 'react-redux';

import { fetchAppDownloadInfo } from '../store/actions/app';
import * as selectors from '../store/selectors';
import close from './img/icons/close.svg';
import './scss/downloadpppopup.scss';


class DownloadAppPopup extends Component {

  componentDidMount() {
    const { fetchAppDownloadInfo } = this.props;
    fetchAppDownloadInfo();
  }

  render() {
    const { closeAppPopup, appDownloadInfo } = this.props;
    const isAndroidWrapper = window.MobileWrapperInterface !== undefined && window.MobileWrapperInterface.isMobileWrapper();
    return appDownloadInfo  && !isAndroidWrapper ?  (
      <div className="app__download">
        <img
          onClick={closeAppPopup}
          src={close}
          className="app__download--close__icon"
          alt="cross icon"
        />
        {
          <img
          src={appDownloadInfo.ImageURL}
          className={"app__download--download__icon"}
          alt="app icon"
          />
        }
        <div className='app__download--info'>
          <span>{appDownloadInfo.Brand}</span>
          <span>{appDownloadInfo.Message}</span>
        </div>
        <a className='app__download--button'
           href={appDownloadInfo.Link}
           onClick={closeAppPopup}
        >
          View
        </a>
      </div>) : null
  };
}

const mapStateToProps = state => ({
  appDownloadInfo: selectors.getAppDownloadInfo(state),
});

const mapDispatchToProps = {
  fetchAppDownloadInfo,
};

DownloadAppPopup = connect(mapStateToProps, mapDispatchToProps)(DownloadAppPopup);

export default DownloadAppPopup;
