import React from 'react';
import OptionSelector from './optionselector';

const ApplicationTypeOptionSelector = ({applicationType, t, onChange}) => {
  return <OptionSelector
    value={applicationType}
    onChange={onChange}
    options={[
      {
        value: "NoTopDress",
        name: t('fieldsettings_application_basic')
      },
      {
        value: "LowTopDress",
        name: t('fieldsettings_application_small_topdress_metric')
      },
      {
        value: "HighTopDress",
        name: t('fieldsettings_application_large_topdress_metric')
      },
    ]}
  />;
}

export default ApplicationTypeOptionSelector;
