import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as constants from '../store/constants';
import {fetchShapes} from '../store/actions/shapes';
import {showModal, showHelp} from '../store/actions/app';
import {activeShape, fetchShapeRanges} from '../store/actions/currentadvice';
import * as selectors from '../store/selectors';
import Shapes from '../components/shapes';
import './scss/fertilizerprops.scss';


class FertilizerProps extends Component {

  componentDidMount() {
    this.fetchShapes();
  }

  fetchShapes() {
    let {shapes, isFetching} = this.props;
    if(shapes.length===0 && isFetching===false) {
      this.props.fetchShapes();
    }
  }

  render() {
    const {shapes, shapeId, shapeRanges, activeShape, t, showHelp, field} = this.props;

    if(!shapeRanges)
    {
      this.props.fetchShapeRanges();
    }

    return <div className='fertilizerprops'>
      <div
        style={{
          position: "relative",
          width: "100%"
        }}
      >
        <h2 className="advice__title">
          {t('page_title_fertilizer_settings')}
        </h2>
        <a
          style={{
            top: "0%",
            transform: "translateY(25%)"
          }}
          className="advice__title__help"
          onClick={() => showHelp(constants.HELP_PAGES.SHAPE)}
        >
          <svg viewBox="0 0 30 30">
            <use xlinkHref="#help" />
          </svg>
        </a>
      </div>
      <div
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          width: "100%"
        }}
      >
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'baseline',
          }}
        >
          <h3 className="fertilizerprops__subtitle">{t('title_shapes')}</h3>
          <div
            style={{
              marginLeft: '.5em',
              fontSize: 12,
              color: "#333338",
              fontWeight: "normal",
            }}
          >
            {`(${t('title_shapes_comment')})`}
          </div>
        </div>
      </div>
      <Shapes {...{shapes, shapeRanges, shapeId, activeShape, width: field.WorkingWidth, t}} />
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    isFetching:selectors.isFetchingShapes(state),
    shapes: selectors.getShapes(state),
    shapeRanges: selectors.getCurrentShapeRanges(state),
    shapeId: selectors.getCurrentShapeId(state),
    field: selectors.getCurrentField(state),
    t: selectors.getTranslations(state),
  };
};

const actions = {
  fetchShapes,
  activeShape,
  fetchShapeRanges,
  showModal,
  showHelp,
};

FertilizerProps = connect(mapStateToProps, actions)(FertilizerProps);

export default FertilizerProps;
