import * as constants from '../constants';

const autosetapp = (state = null, action) => {
  switch (action.type) {
    default: {
      return state;
    }
    case constants.FETCH_AUTOSETAPP_SUCCESS: {
      let {autosetapp} = action.payload;
      return Object.assign({}, state, autosetapp);
    }
    case constants.API_OUT_OF_DATE:
    case constants.ACTIVATE_LANGUAGE: {
      return null;
    }
  }
}
export default autosetapp;
