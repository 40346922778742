import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as selectors from '../store/selectors';
import {isEmpty} from '../utils';
import {
  fetchMachine,
  fetchMachines,
  fetchMachineBySerialNumber,
  clearMachineSearch,
  activateMachine
} from '../store/actions/machines';
import {showModal} from '../store/actions/app';
import {activeMachine} from '../store/actions/currentadvice';

import Advicebar from '../components/advicebar';
import MachineCategory from '../components/machinecategory';
import SearchField from '../components/searchfield';
import MachineItem from '../components/machineitem';

import Modal from '../components/modal';

import icons from '../components/img/icons';

import './scss/machineslist.scss';
import QrScanner from 'qr-scanner';
import { PLATFORM_ANDROID, PLATFORM_IOS } from '../store/constants';


class MachinesList extends Component {
  state = {
    serialNumber: "", 
    qrcode_model_shown: false,
    error: null
  };

  qrCodeScanner = null;


  componentDidMount() {
    const {fetchMachines, searchedMachine} = this.props;
    fetchMachines();
    this.setState({ 
      ...this.state,
      serialNumber: searchedMachine.serialNumber
    });
  }

  componentDidUpdate() {
    if(this.state.qrcode_model_shown && !this.qrCodeScanner)
      this.startScanning();
  }

  
  componentWillReceiveProps(props) {
    if(this.props.machinesList.length !== 0 && props.machinesList.length === 0)
    {
      this.props.fetchMachines();
    }
  }

  handleSearchInputChange = (event) => {
    const {value} = event.target;
    this.handleSearchInput(value);
  };

  handleSearchInput = (value) => {
    const {clearMachineSearch, searchedMachine} = this.props;
    if(isEmpty(value)) {
      if(searchedMachine && (searchedMachine.result || searchedMachine.notFound)) {
        clearMachineSearch();
      }
    }
    else
    {
      this.props.fetchMachineBySerialNumber(value);
    }
    
    this.setState({
      ...this.state,
      serialNumber: value
    });
  }

  handleSearchSubmit = (event) => {
    event.preventDefault();
    const {serialNumber} = this.state;
    if(serialNumber !== "") {
      this.props.fetchMachineBySerialNumber(serialNumber);
    }
  };


  isMediaDevicesSupported() {
    return (navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  }

  goNext() {
    const {match, history} = this.props;
    history.push(`/${match.params.brand}/advice/2/`);
  }

  onSubmit = () => {
    if(this.props.machineId !== null) {
      this.goNext();
    }
  };

  clickMachine(id) {
    const {machines, activeMachine, activateMachine, fetchMachine} = this.props;
    if(!(id in machines)) {
      fetchMachine(id)
        .then(() => {
          activeMachine(id);
          activateMachine(id);
          this.goNext();
        });
    } else {
      activeMachine(id);
      activateMachine(id);
      this.goNext();
    }
  };


  clickQRCode() {
    if(this.qrCodeScanner)
      this.qrCodeScanner.stop();

    this.setState({
      ...this.state, 
      qrcode_model_shown : true
    });
  }

  async startScanning () {  
    const { QrCodeScanner } = await import(/* webpackChunkName: "qrCodeScanner" */ '../utils/qrCodeScanner');  
    this.qrCodeScanner = new QrCodeScanner("reader", 
      serial => {        
        this.closeQrModal();
        this.handleSearchInput(serial.split(";")[0]);
      });
    this.qrCodeScanner.start();
  }

  async hasCamera () {  
    const { QrCodeScanner } = await import(/* webpackChunkName: "qrCodeScanner" */ '../utils/qrCodeScanner'); 
    return QrCodeScanner.hasCamera();
  }

  closeQrModal() {
    if(this.qrCodeScanner)
    {
      this.qrCodeScanner.stop();
      this.qrCodeScanner = null;
    }

    this.setState({
      ...this.state, 
      qrcode_model_shown : false
    });

  }

  render() {
    const { machinesList, machineId, t, showModal, searchedMachine,
      adviceBarHidden, toggleHideAdviceBar, webview, platform } = this.props;


    let isMobile = platform === PLATFORM_ANDROID || platform === PLATFORM_IOS;

    return <div>
      {
        this.state.qrcode_model_shown && <Modal closeTxt="close" onClose={() => this.closeQrModal()}>
          <video id="reader" width="100%"/>
        </Modal>
      }
      <div className='container machines'>
        <h2 className='advice__title'>
          {t('page_title_machine_selection')}
          <a
            className='advice__title__help'
            onClick={() => showModal({
              title: 'machine_info_title',
              text: 'machine_info_text'
            })}
          >
            <svg viewBox="0 0 30 30">
              <use xlinkHref="#help"/>
            </svg>
          </a>
        </h2>
        <div className='machineslist'>
          <div className='machineslist__header'>
            <SearchField
              value={this.state.serialNumber}
              onChange={this.handleSearchInputChange}
              onSubmit={this.handleSearchSubmit}
              placeholder={t('serial_search_placeholder')}
              notFound={searchedMachine && searchedMachine.notFound && t('serial_search_no_result')}
              toggleHideAdviceBar={toggleHideAdviceBar}
            />
            {
              ((isMobile) && (!webview) && this.hasCamera()) &&
            <div className='machineslist__qrcode'
              onClick={() => this.clickQRCode()}>
              <img alt="qrCodeIcon" src={icons.qrcode}></img>
            </div>
            }
          </div>
          {searchedMachine.result && this.state.serialNumber !== "" ?
            <div
              className={
                `machineslist__item ${machineId === searchedMachine.result.Id && "machineslist__item--active"}`
              }
            >
              <MachineItem
                clickMachine={(id) => this.clickMachine(id)}
                id={searchedMachine.result.Id}
                name={searchedMachine.result.Name}
                imageUrl={searchedMachine.result.ImageURL}
              />
            </div>
            :
            machinesList.map(model =>
              <MachineCategory
                key={model.Name}
                machineId={machineId}
                clickMachine={(id) => this.clickMachine(id)}
                name={model.Name}
                models={model.Models}
              />
            )}
        </div>
        <Advicebar
          currentStep={1}
          onNext={this.onSubmit}
          nextEnabled={(machineId !== null)}
          hidden={adviceBarHidden}
        />
      </div>
    </div>;
  }
}

const mapStateToProps = (state) => {
  let machineId = selectors.getCurrentMachineId(state);
  return {
    categories: selectors.getCategories(state),
    machines: selectors.getMachines(state),
    machinesList: selectors.getMachinesList(state),
    searchedMachine: selectors.getSearchedMachine(state),
    webview: selectors.getWebview(state),
    platform: selectors.getPlatform(state),
    machineId,
    t: selectors.getTranslations(state),
    isFetching: selectors.isFetchingMachines(state),
    breadcrumb: selectors.getCategoryBreadcrumb(state, machineId)
  };
};

const actions = {
  fetchMachines,
  fetchMachine,
  fetchMachineBySerialNumber,
  clearMachineSearch,
  activeMachine,
  activateMachine,
  showModal
};

MachinesList = connect(mapStateToProps, actions)(MachinesList);

export default MachinesList;
