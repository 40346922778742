import * as constants from '../constants';

const initial = {
  platform: null,
  webview: false,
  browser: null,
  initialized: false,
  loading: true,
  disclaimerAccepted: false,
  gdprDisclaimerAccepted: false,
  appPopupClosed: false,
  brand: null,
  modalMessage: null,
  helpVisible: false,
  helpSection: null,
  apiDatabaseVersion: null,
  apiVersion: null,
  appDownloadInfo: null,
};

const app = (state = initial, action) => {
  switch (action.type) {
    case constants.DETECT_PLATFORM: {
      let {platform, webview, browser} = action.payload;
      return Object.assign({}, state, {
        platform,
        webview,
        browser
      });
    }
    case constants.INITIALIZE_APP_STARTED: {
      return Object.assign({}, state, {
        initialized: false,
        loading: true,
        brand: action.payload.brand
      });
    }
    case constants.INITIALIZE_APP_SUCCESS: {
      return Object.assign({}, state, {
        initialized: true,
        loading: false
      });
    }
    case constants.ACCEPT_DISCLAIMER: {
      return Object.assign({}, state, {
        disclaimerAccepted: true
      });
    }
    case constants.CLOSE_APP_POPUP: {
      return {
        ...state,
        appPopupClosed: true
      };
    }
    case constants.FETCH_APP_DOWNLOAD_INFO_SUCCESS: {
      const formattedPlatform = state.platform === constants.PLATFORM_ANDROID ? "Android" : state.platform === constants.PLATFORM_IOS ? "IOS" : null;
      return {
        ...state,
        appDownloadInfo: formattedPlatform ? action.payload.filter(data => data.Platform === formattedPlatform)[0] : null
      }
    }
    case constants.API_ERROR: {
      return Object.assign({}, state, {
        loading: false
      });
    }
    case constants.MODAL_SHOW: {
      let modalMessage = action.payload;
      return Object.assign({}, state, {modalMessage});
    }
    case constants.MODAL_HIDE: {
      return Object.assign({}, state, {modalMessage: null});
    }
    case constants.PRELOAD_STARTED: {
      return Object.assign({}, state, {loading: true});
    }
    case constants.PRELOAD_FINISHED: {
      return Object.assign({}, state, {loading: false});
    }
    case constants.HELP_SHOW: {
      let helpSection = action.payload;
      return Object.assign({}, state, {helpVisible:true, helpSection});
    }
    case constants.HELP_HIDE: {
      return Object.assign({}, state, {helpVisible:false});
    }
    case constants.CHECK_API_VERSION_SUCCESS: {
      return Object.assign({}, state, {...action.payload});
    }
    default: {
      return state;
    }
  }
}
export default app;
