import * as constants from '../constants';

const initial = {
  fetching: false,
  byId: []
}

const shapes = (state = initial, action) => {
  switch (action.type) {
    case constants.FETCH_SHAPES_STARTED: {
      return Object.assign({}, state, {
        fetching: true,
      });
    }
    case constants.FETCH_SHAPES_SUCCESS: {
      return {
        ...state,
        byId: action.payload
      }
    }
    case constants.API_OUT_OF_DATE:
    case constants.ACTIVATE_LANGUAGE: {
      return initial;
    }
    default: {
      return state;
    }
  }
}
export default shapes;
