import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchFAQ } from '../store/actions/faq';
import * as selectors from '../store/selectors';

import './scss/faq.scss';

import FaqItem from '../components/faqitem';

import HeaderImage from '../components/headerimage';

class Faq extends Component {

  componentDidMount() {
    this.props.fetchFAQ();
  }

  render() {
    const { t, match, faqs } = this.props;

    return (
      <div className='faq'>
        <HeaderImage t={t} title="menu_faq" brand={match.params.brand} />
        <div className='container faq__container'>
          {faqs && faqs.map(item =>
            <FaqItem
              id={item.Id}
              question={item.Question}
              answer={item.Answer}
            />
          )}
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => ({
  faqs: selectors.getFaqs(state),
  t: selectors.getTranslations(state)
});

const actions = {
  fetchFAQ,
};

Faq = connect(mapStateToProps, actions)(Faq);

export default Faq;
