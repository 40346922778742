import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as selectors from '../store/selectors';
import {fetchOtherapps} from '../store/actions/otherapps';

import HeaderImage from '../components/headerimage';

import './scss/otherapps.scss';


class OtherApps extends Component {

  componentDidMount() {
    let {apps, fetchOtherapps} = this.props;
    if(apps===null) {
      fetchOtherapps();
    }
  }

  render() {
    let {t, apps, match} = this.props;

    if(apps===null){
      return null;
    }

    return <div className='otherapps'>
      <HeaderImage t={t} title="other_apps_page_title" brand={match.params.brand} />

      <div className='container otherapps__content'>
        { apps.map((ite, i)=>
          <a
            className='otherapps__content__app'
            key={'a'+i}
            href={ite.StoreURL}
            target="_blank"
            rel="noopener noreferrer"
          >
              <div className='otherapps__content__img'>
                <img src={ite.ImageURL} alt="" />
              </div>
              <div className='otherapps__content__text'>
                <h2>{ite.Title}</h2>
                <p>{ite.Description}</p>
              </div>
          </a>)}
      </div>
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    t: selectors.getTranslations(state),
    apps: selectors.getOtherapps(state)
  };
}
const actions = {
  fetchOtherapps
};
OtherApps = connect(mapStateToProps, actions)(OtherApps);
export default OtherApps;
