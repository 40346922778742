import * as constants from '../constants';

const favourites = (state = [], action) => {    
  switch (action.type) {
    case constants.FAVOURITE_STORE_ADVICE: {
      const { advice, machine, units } = action.payload;

      if (!advice.advicePossible) {
        return state;
      }
      
      const savedAdvice = {
        ...advice,
        id: new Date().getTime(),
        inHistory: true,
        machineHistory: machine,
        units: units
      };

      //append
      return [...state, savedAdvice];
    }
    
    case constants.FETCH_PDF_SUCCESS: {
      let {DownloadURL, adviceId} = action.payload;
      return state.map((ite)=> {
        if(ite.id===adviceId) {
          return Object.assign({}, ite, {
            resultPdf:DownloadURL
          });
        }
        return ite;
      })
    }

    case constants.FAVOURITE_REMOVE_ADVICE: {
      return state.filter((item) => item.id !== action.payload);
    }

    default: {
      return state;
    }
  }
}

export default favourites;