import React, { Component } from 'react';

import Graphicbar from './graphicbar';

import './scss/resultoverall.scss';
import ButtonsControl from './buttonscontrol';
import * as selectors from '../store/selectors';
import { setTablePosition, setApplicationType } from '../store/actions/currentadvice';
import { connect } from 'react-redux';
import ApplicationTypeOptionSelector from './applicationtypeoptionselector';
import AdviceResultItem from './adviceresultitem';

import ExpendableCard from './expandableCard';

class ResultOverall extends Component {
  state = {
    isOverallExpanded: false
  };

  constructor(props) {
    super(props);
    const {x, y} = props.tablePos;
    this.props.setTablePosition(x, y);
  }

  componentWillReceiveProps(props) {
    if(props.table !== this.props.table) {
      const {x, y} = props.tablePos;
      this.props.setTablePosition(x, y);
    }
  };

  increaseX() {
    const { table, tablePos,setTablePosition } = this.props;
    let {x, y} = tablePos;

    if(x < table[y].length - 1 && table[y][x + 1] !== null) {
      x++;
      setTablePosition(x, y);
    }
  }

  decreaseX = () => {
    let { table, tablePos, setTablePosition } = this.props;
    let {x, y} = tablePos;

    if(x > 0 && table[y][x - 1] !== null) {
      x--;
      setTablePosition(x, y);
    }
  };

  increaseY = () => {
    let {table, tablePos,setTablePosition} = this.props;
    let {x, y} = tablePos;

    if(y < table.length - 1 && table[y + 1][x] !== null) {
      y++;
     setTablePosition(x, y);
    }
  };

  decreaseY() {
    let {table, tablePos, setTablePosition} = this.props;
    let {x, y} = tablePos;

    if(y > 0 && table[y - 1][x] !== null) {
      y--;
      setTablePosition(x, y);
    }
  }

  onChangeAppType(type) {
    const { setApplicationType } = this.props;
    setApplicationType(type);
  }

  resetXY(target) {
    const {tablePos, setTablePosition, baseTablePos} = this.props;
    let { x,y } = tablePos;
    if(target) {
      target === "x"
        ? setTablePosition(baseTablePos.x, y)
        : setTablePosition(x, baseTablePos.y);
    } else {
      setTablePosition(x, y);
    }
  }

  render() {
    let {
      t, machine, result, currentAdvice, table,
      tablePos, icons
    } = this.props;
    const {Images} = result;
    const {applicationType} = currentAdvice;
    const {x,y} = tablePos;

    let distribution = "0-0-0-0"
    if(result.FertiliserDistribution) 
      distribution = result.FertiliserDistribution.split("-");

    return <div>
      <ExpendableCard header={t('result-panel-machine-header')} gaLabel={"AdviceStaticSetup"}>  
        <div>
            <div className={'flex'}>
              <img
                className={'small-circle-image mr-13'}
                src={machine.ImageURL}
                alt=""
              />
              <div className="truncate-text width-full">
                <div className={'light-small-title truncate-text mb-8'}>
                  {t('results-overall-machine-title')}
                </div>
                <div className={'dark-small-title truncate-text'}>
                  {machine.Name}
                </div>
              </div>
            </div>
          </div>
          <div className={"flex justify-between"}>
            <div style={{width: "70%"}}>
              <div className={'mt-20'}>
                <div className={'flex'}>
                  <img className={'small-circle-image mr-13'} src={Images.Fertiliser} alt="" />
                    <div className="truncate-text width-full">
                      <div className={'light-small-title truncate-text mb-8'}>
                        {t('results-overall-product-title')}
                      </div>
                      
                  {
                    result.FertiliserProducer &&
                      <div className={'dark-small-title truncate-text mb-8'}>
                        {result.FertiliserProducer}
                      </div>
                  }
                      <div className={'dark-small-title truncate-text mb-8'}>
                        {result.FertiliserName}
                      </div>
                      <div className={'dark-small-title truncate-text mb-8'}>
                        {`${t(result.FertiliserShape)}`}
                      </div>
                  {
                    (machine.UsesFractioning) &&
                      <div className={'dark-small-title truncate-text'}>
                        {`${result.FertiliserDensity} ${t('unit_density')}`}
                      </div>
                  }
                    </div>
                </div>
              </div>
            </div>
            {
              (machine.UsesFractioning) &&
              <div style={{marginLeft: "auto"}} className={'align-self-end'}>
                <div style={{height: 50}}>
                  <Graphicbar
                    v1={Number(distribution[0])}
                    v2={Number(distribution[1])}
                    v3={Number(distribution[2])}
                    v4={Number(distribution[3])}
                    status={'success'}
                    showTitles={false}
                  />
                </div>
                <div className={"dark-small-title mt-5 text-center"}>
                  {result.FertiliserDistribution}
                </div>
              </div>
            }
          </div>
      </ExpendableCard>
      {!machine.HasSpout &&
        <ExpendableCard header={t('results-overall-vanes-title')} expanded={true} gaLabel={"AdviceVanes"}> 
          {//<AdviceResultItem header={t('results-overall-vanes-title')} value={result.Vanes} ico={icons.Vanes }/>
          }
          <h1 className={'resultoverall__vanes__title'}>{result.Vanes}</h1>
          <img className={'resultoverall__vanes__image'}
              src={Images.Vanes}
              alt="" />
        </ExpendableCard>
      }
      <ExpendableCard header={t('result-panel-field-header')} expanded={true} gaLabel={"AdviceFieldSettings"}>   
        <div>
          <AdviceResultItem header={t('results-overall-workingwidth-title')} value={currentAdvice.WorkingWidth} units={t('unit_distance')} ico={Images.WorkingWidth }/>
        </div>
        {
          (table && table[y][x]) &&
          <div >
            <div className="flex flex-center justify-between">
              <ButtonsControl
                header={t('results-overall-application-rate-title')}
                title={'5%'}
                ico={result.Images.ApplicationRate}
                value={table[y][x].Rate}
                units={t("unit_rate")}
                onIncrease={this.increaseY}
                onDecrease={() => this.decreaseY()}
                increaseDisabled={(y >= table.length - 1 || table[y + 1][x] === null)}
                decreaseDisabled={(y <= 0 || table[y - 1][x] === null)}
                reset={() => this.resetXY("y")}
              />

            </div>
            <div className="flex flex-center">
              <ButtonsControl
                header={t('results-overall-driving-speed-title')}
                title={"1"}
                ico={result.Images.DrivingSpeed}
                value={table[y][x].Speed.split(" ")[0]}
                units={t("unit_speed")}
                onIncrease={() => this.increaseX()}
                onDecrease={() => this.decreaseX()}
                increaseDisabled={(x >= table[y].length - 1 || table[y][x + 1] === null)}
                decreaseDisabled={(x <= 0 || table[y][x - 1] == null)}
                reset={() => this.resetXY("x")}
              />
            </div>
          </div>
        }
        {
          !machine.HasSpout &&
          <div style={{marginTop: 10}}>
            <div>
              <div className={'light-small-title truncate-text mb-8'}>
                {t('results-overall-application-type-title')}
              </div>
              {
                !machine.HasSpout &&
                  <div style={{height: 40}}>
                    <ApplicationTypeOptionSelector
                      applicationType={applicationType}
                      t={t}
                      onChange={(v) => this.onChangeAppType(v)}
                    />
                  </div>
              }
            </div>
          </div>
        }
      </ExpendableCard>
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    table: selectors.getCurrentTable(state),
    baseTablePos: selectors.getBaseTablePos(state),
    tablePos: selectors.getCurrentTablePos(state),
  };
};

const actions = {
  setTablePosition,
  setApplicationType,
};

ResultOverall = connect(mapStateToProps, actions)(ResultOverall);

export default ResultOverall;
