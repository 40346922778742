import { createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { save, load } from "redux-localstorage-simple";

import reducer from './reducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [ thunk ];

if (process.env.NODE_ENV !== 'production') {
  middleware.push(createLogger());
}

let loadMethod = (settings) => { console.log('not caching anything'); };
const needsCache = (typeof window['DATA'].cache_enabled === 'boolean')
  ? window['DATA'].cache_enabled
  : (process.env.REACT_APP_LOCALSTORAGE === 'true');

const brandStore = (brand) => {
  if(needsCache) {
    middleware.push(save({
      namespace: brand,
      debounce: 500,
      //states: ["app", "history"]
    }));
    loadMethod = load
  }
  return createStore(
    reducer,
    loadMethod({ namespace: brand }),
    composeEnhancers(applyMiddleware(...middleware))
  );
}
let store = null;

export const getStore = (brand) => {
  if(store === null) {
    store = brandStore(brand);
  }
  return store;
}
