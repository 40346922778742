import * as constants from '../constants';
import * as lactions from './locales';
import * as sactions from './settings';
import * as mactions from './machines';
import * as Api from '../../api';
import {
  getApiVersion,
  getApiDatabaseVersion,
  getCurrentUnits,
  getCurrentLanguageCode,
  getBrand,
} from '../selectors';
import {fetchIcons} from './icons';

const initializeAppStarted = (brand) => ({
  type: constants.INITIALIZE_APP_STARTED,
  payload: {brand}
});

const initializeAppSuccess = () => ({
  type: constants.INITIALIZE_APP_SUCCESS,
});

export const initializeApp = (brand) => (dispatch, getState) => {
  dispatch(initializeAppStarted(brand));
  dispatch(detectPlatform());
  dispatch(fetchIcons());
  return dispatch(lactions.fetchLanguages())   
    .then(()=> dispatch(lactions.activateLanguage()))
    .then(()=> dispatch(lactions.fetchLegalLinks()))
    .then(()=> dispatch(initializeAppSuccess()));
};

const checkApiVersionStarted = () => ({
  type: constants.CHECK_API_VERSION_STARTED
});

const checkApiVersionSuccess = (payload) => ({
  type: constants.CHECK_API_VERSION_SUCCESS,
  payload
});

const ApiVersionOutOfDate = ({unit}) => ({
  type: constants.API_OUT_OF_DATE,
  payload: {unit}
});

export const checkApiVersion = () => (dispatch, getState) => {
  dispatch(preloadStarted());
  dispatch(checkApiVersionStarted());
  dispatch(fetchIcons());
  let apiVersion = getApiVersion(getState());
  let dbVersion = getApiDatabaseVersion(getState())
  return Api.getApiVersion()
    .then((res) => {
      const versionInfo = {
        apiDatabaseVersion: res.find((item) => item["Component"] === "Database").Version,
        apiVersion: res.find((item) => item["Component"] === "API").Version,
      };

      dispatch(checkApiVersionSuccess(versionInfo));

      if(apiVersion === null || (versionInfo.apiVersion === apiVersion)) {
        if(dbVersion === null || (versionInfo.apiDatabaseVersion === dbVersion)) {
          return dispatch(preloadFinished());
        }
      }
      let unit = getCurrentUnits(getState());
      dispatch(ApiVersionOutOfDate({unit}));
      dispatch(lactions.fetchLanguages())
      .then(()=> dispatch(lactions.activateLanguage()))
      .then(()=> dispatch(lactions.fetchTranslations()))      
      .then(()=> dispatch(lactions.fetchLegalLinks()))
      .then(()=> dispatch(sactions.fetchSettings()))
      .then(()=> dispatch(mactions.fetchMachines()))
      .then(()=> {
        dispatch(preloadFinished());
      })
    })
    .catch(err => dispatch(trackError(err))
  );
};

export const trackError = (error) => (dispatch) => {
  let {status, statusText} = error;
  dispatch({
    type: constants.API_ERROR,
    payload: {status, statusText}
  })
  throw error;
};

export const preloadStarted = () => ({
  type: constants.PRELOAD_STARTED
})
export const preloadFinished = () => ({
  type: constants.PRELOAD_FINISHED
})

export const showModal = (message) => ({
  type: constants.MODAL_SHOW,
  payload: message
});

export const hideModal = () => ({
  type: constants.MODAL_HIDE
});

export const acceptDisclaimer = () => ({
  type: constants.ACCEPT_DISCLAIMER
});

export const closeAppPopup = () => ({
  type: constants.CLOSE_APP_POPUP
});

export const detectPlatform = () => {
  var parser = require('ua-parser-js');
  let agent = parser(navigator.userAgent);
  let platform = constants.PLATFORM_DESKTOP;
  let browser = agent.browser.name;
  if(agent.os.name.match(/Android/i)) {
    platform = constants.PLATFORM_ANDROID;
  } else if(agent.os.name.match(/iOS/i)) {
    platform = constants.PLATFORM_IOS;
  }

  let webview = RegExp('(WebView|WebKit)', 'i').test(browser);

  return {
    type: constants.DETECT_PLATFORM,
    payload: {platform, webview, browser}
  }
};

export const showHelp = (section) => ({
  type: constants.HELP_SHOW,
  payload: section
});

export const hideHelp = () => ({
  type: constants.HELP_HIDE
});

export const fetchAppDownloadInfo = () => (dispatch, getState) => {
  dispatch(preloadStarted());
  const state = getState();
  const lang = getCurrentLanguageCode(state);
  const brand = getBrand(state);

  return Api.getAppDownloadInfo(brand, lang)
    .then((res) => {
      dispatch({
        type: constants.FETCH_APP_DOWNLOAD_INFO_SUCCESS,
        payload: res
      })
    })
    .catch(err => dispatch(trackError(err))
    );
};
