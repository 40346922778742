import React from 'react';
import Modal from './modal';


const InputModal = (
  {
    t,
    onSubmit,
    onChange,
    onClose,
    placeholder,
  }
  ) => {

  return (
    <Modal
      hideButton={true}
      closeTxt={t('button_close')}
      onClose={onClose}
    >
      <form onSubmit={onSubmit}>
        <div
          style={{
            display: 'flex',
            justifyContents: 'center',
            alignItems: 'center',
            flexDirection: "column"
          }}
        >
          <div
            style={{
              alignSelf: 'flex-start',
            }}
          >
            <div
              style={{
                color: "#333338",
                fontSize: 16,
                fontWeight: "bold",
                marginBottom: 10,
              }}
            >
              {t('save_advice_dialog_title')}
            </div>
            <div
              style={{
                color: "#333338",
                fontSize: 13,
                marginBottom: 30,
              }}
            >
              {t('save_advice_dialog_description')}
            </div>
          </div>
          <div className={"flex flex-column mb-44 width-full"}>
            <label className={"dark-smaller-title mb-10"} htmlFor="advice-dialog-advice-name">
              {t('save_advice_dialog_input_label')}
            </label>
            <input
              id={"advice-dialog-advice-name"}
              className={"form-input"}
              onChange={onChange}
              placeholder={placeholder}
              type="text"
              maxLength="255"
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: "center",
              alignItems: "center",
              width: "100%"
            }}
          >
            <button
              type="button"
              onClick={onClose}
              className="btn-primary"
              style={{
                width: "40%",
                backgroundColor: "#979797",
                color: "#FFFFFF",
                marginRight: 15
              }}
            >
              {t('cancel')}
            </button>
            <button
              type="submit"
              style={{
                width: "40%",
                margin: 0,
              }}
              onClick={onSubmit}
              className="btn-primary"
            >
              {t('save_advice')}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
};

export default InputModal;
