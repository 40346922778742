import * as constants from '../constants';
import * as Api from '../../api';
import {trackError, showModal, preloadStarted, preloadFinished} from './app';

import * as selectors from '../../store/selectors';

export const newAdvice = () => (dispatch, getState) => {
  let unit = selectors.getCurrentUnits(getState());
  let machines = selectors.getMachines(getState());
  let machine = selectors.getCurrentMachine(getState());
  let machineId = machine ? machine.Id : null;
  if(!(machineId in machines)) {
    machineId = null
  }
  return dispatch({
    type: constants.ADVICE_NEW,
    payload: {
      unit,
      machineId
    }
  });
}

export const activeMachine = (id) => ({
  type: constants.ADVICE_ACTIVE_MACHINE,
  payload: {id}
});

export const updateField = (name, value, machineId) => ({
  type: constants.ADVICE_UPDATE_FIELD,
  payload: {name, value, machineId}
});

export const updateFieldWithoutReset = (name, value) => ({
  type: constants.ADVICE_UPDATE_FIELD_NO_RESET,
  payload: {name, value}
})

const validateFieldStarted = () => ({
  type: constants.VALIDATE_FIELD_STARTED,
});
const validateFieldSuccess = () => ({
  type: constants.VALIDATE_FIELD_SUCCESS,
});
const validateFieldFailed = (payload) => ({
  type: constants.VALIDATE_FIELD_FAILED,
  payload
});

export const validateField = () => (dispatch, getState) => {
  dispatch(preloadStarted());
  dispatch(validateFieldStarted());
  let data = {...selectors.getCurrentField(getState())},
      machine = selectors.getCurrentMachineId(getState()),
      units = selectors.getCurrentUnits(getState());

  return Api.validateField(units, machine, data).then((r) => {
    if (r === true) {
      dispatch(preloadFinished());
      return dispatch(validateFieldSuccess(r));
    }
    dispatch(showModal({
      title: 'fieldsettings_result_in_invalid_flow_title',
      text: 'fieldsetings_result_in_invalid_flow'
    }));
    dispatch(preloadFinished());
    return dispatch(validateFieldFailed(r));
  });
};

export const activeShape = (id) => ({
  type: constants.ADVICE_ACTIVE_SHAPE,
  payload: { id }
});
export const activeShapeAll = (id) => ({
  type: constants.ADVICE_ACTIVE_SHAPE_ALL,
  payload: { id }
});

export const updateProps = (name, value, isValid) => {
  return {
    type: constants.ADVICE_UPDATE_PROPS,
    payload: { name, value, isValid }
  }
};

export const validFertilizerProps = () => ({
  type: constants.ADVICE_VALID_PROPS
});

const fetchApplicationsStarted = () => ({
  type: constants.FETCH_APPLICATIONS_STARTED,
});
const fetchApplicationsSuccess = (applications) => ({
  type: constants.FETCH_APPLICATIONS_SUCCESS,
  payload: applications
});
export const fetchApplications = () => (dispatch, getState) => {
  dispatch(fetchApplicationsStarted());
  let state = getState(),
      units = selectors.getCurrentUnits(state),
      langs = selectors.getCurrentLanguageCode(state),
      machine = selectors.getCurrentMachineId(state),
      field = selectors.getCurrentField(state),
      properties = selectors.getCurrentValidProperties(state),
      shape = selectors.getCurrentShapeId(state);
  dispatch(preloadStarted());
  return Api.getApplications(langs, units, machine, shape, field, properties)
    .then((r) => {
      dispatch(preloadFinished());
      return dispatch(fetchApplicationsSuccess(r))
    })
    .catch(err => dispatch(trackError(err))
  );
}

export const activeApplication = (vanesetId, fertiliserId) => ({
  type: constants.ADVICE_ACTIVE_APPLICATION,
  payload: {vanesetId, fertiliserId}
});

const isFineDosageStarted = () => ({
  type: constants.FINE_DOSAGE_STARTED,
});
const isFineDosageSuccess = (response) => ({
  type: constants.FINE_DOSAGE_SUCCESS,
  payload: {response}
});

export const isFineDosage = () => (dispatch, getState) => {
  dispatch(isFineDosageStarted());
  let state = getState(),
      units = selectors.getCurrentUnits(state),
      machine = selectors.getCurrentMachineId(state),
      field = selectors.getCurrentField(state),
      canTopDress = selectors.machineCanTopDress(state, machine);
  dispatch(preloadStarted());
  return Api.isFineDosage(units, machine, field, canTopDress)
    .then((r) => {
      dispatch(preloadFinished());
      return dispatch(isFineDosageSuccess(r))
    })
    .catch(err => dispatch(trackError(err))
  );
}

export const useKit = (status) => ({
  type: constants.ADIVCE_USE_KIT,
  payload: {status}
})

const fetchResultStarted = () => ({
  type: constants.FETCH_RESULT_STARTED,
});

const fetchResultSuccess = (result) => ({
  type: constants.FETCH_RESULT_SUCCESS,
  payload: {result}
});

export const fetchResult = () => (dispatch, getState) => {
  dispatch(fetchResultStarted());
  let state = getState(),
      units = selectors.getCurrentUnits(state),
      langs = selectors.getCurrentLanguageCode(state),
      machine = selectors.getCurrentMachineId(state),
      field = selectors.getCurrentField(state),
      fertilizer = selectors.getCurrentFertilizerId(state),
      VaneSetId = selectors.getCurrentVanesetId(state),
      kitUsed = selectors.isCurrentKitUsed(state);
  kitUsed = (kitUsed===null) ? false : kitUsed;
  dispatch(preloadStarted());
  return Api.getResult(langs, units, machine, fertilizer, VaneSetId, kitUsed, field)
    .then((r) => {
      dispatch(preloadFinished());
      return dispatch(fetchResultSuccess(r));
    })
    .catch(err => dispatch(trackError(err)));
}

const fetchPdfStarted = () => ({
  type: constants.FETCH_PDF_STARTED,
});
const fetchPdfSuccess = ({DownloadURL}) => (dispatch, getState) =>{
  let adviceId = selectors.getCurrentAdviceId(getState());
  return dispatch({
    type: constants.FETCH_PDF_SUCCESS,
    payload: {DownloadURL, adviceId}
  });
};
export const fetchPdf = () => (dispatch, getState) => {
  dispatch(fetchPdfStarted());
  let state = getState(),
      units = selectors.getCurrentUnits(state),
      langs = selectors.getCurrentLanguageCode(state),
      machine = selectors.getCurrentMachineId(state),
      field = selectors.getCurrentField(state),
      fertilizer = selectors.getCurrentFertilizerId(state),
      VaneSetId = selectors.getCurrentVanesetId(state),
      kitUsed = selectors.isCurrentKitUsed(state);
  dispatch(preloadStarted());
  return Api.getPdf(langs, units, machine, fertilizer, VaneSetId, kitUsed, field)
    .then((r) => {
      dispatch(preloadFinished());
      return dispatch(fetchPdfSuccess(r));
    })
    .catch(err => dispatch(trackError(err))
  );
}

export const setTablePosition = (x, y) => ({
  type: constants.SET_TABLE_POSITION,
  payload: {x, y}
});

export const setApplicationType = (applicationType) => ({
  type: constants.SET_APPLICATION_TYPE,
  payload: {applicationType }
});

const fetchSharedResultStarted = (data) => ({
  type: constants.FETCH_SHARED_CHART_STARTED,
  payload: data
});

const fetchSharedResultSuccess = (result) => ({
  type: constants.FETCH_SHARED_CHART_SUCCESS,
  payload: {result}
});

const fetchShapeRangeStarted = () => ({
  type: constants.FETCH_SHAPE_RANGE_STARTED
});

const fetchShapeRangeSuccess = (result) => ({
  type: constants.FETCH_SHAPE_RANGE_SUCCESS,
  payload: {result}
});

export const fetchShapeRanges = () => async (dispatch, getState) => {
  const state = getState();
  const units = state.locales.current.units;
  const machineId = state.currentAdvice.machine;
  
  dispatch(fetchShapeRangeStarted());
  try
  {
    let r = await Api.getShapeRanges(machineId, units);
    return dispatch(fetchShapeRangeSuccess(r));
  }
  catch (err) {
    return dispatch(trackError(err));
  }
}

export const fetchSharedResult = (data) => async (dispatch, getState) => {
  const state = getState();
  const lang = state.locales.current.code;
  const units = state.locales.current.units;
  const field = { 
    DrivingSpeed: data.DrivingSpeed,
    WorkingWidth: data.WorkingWidth,
    ApplicationRate: data.ApplicationRate,
    applicationType: data.applicationType,
  };

  dispatch(fetchSharedResultStarted(data));
  dispatch(preloadStarted());

  var machine = selectors.getMachine(state, data.machine);
  if(!machine)
  {
    machine = await Api.getMachine(data.machine, units);  
    dispatch({
      type: constants.FETCH_MACHINE_SUCCESS,
      payload: machine
    });
  }
  dispatch(activeMachine(data.machine));
  

  dispatch(isFineDosageSuccess(data.fineDosing === true));

  try {
    const r = await Api.getResult(
      lang,
      units,
      data.machine,
      data.fertilizer,
      data.vanes,
      data.kitUsed,
      field
    );
    dispatch(preloadFinished());
    return dispatch(fetchSharedResultSuccess(r));
  }
  catch (err) {
    return dispatch(trackError(err));
  }
}