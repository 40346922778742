import * as constants from '../constants';

const initial = {
  id: null,
  inHistory: false,
  step: 1,
  machine: null,

  WorkingWidth: 0,
  ApplicationRate: 0,
  DrivingSpeed: 0,
  applicationType: 'NoTopDress',
  validField: false,

  shapeRanges: null,

  shapeId: null,
  granuleSizeMinus2: 0,
  granuleSizeB23: 0,
  granuleSizeB34: 0,
  granuleSize4:0,
  adviceDensity: 0,

  applications: null,
  widerSearchCriteriaUsed: false,
  fineDosage: null,
  kitUsed: null,
  applicationId: null,
  fertiliserId: null,
  vanesetId: null,

  advicePossible: null,
  sharedAdvice: false,
  result: null,
  baseResultTableX: 0,
  baseResultTableY: 0,
  resultTableX: 0,
  resultTableY: 0,
  resultPdf: null,
  warningTranslationKey: null,
  adviceDrivingSpeed: null,

  isValid: true,

  name: null,
};

const getTablePosition = (table) => {
  for (let y = 0; y < table.length; y++) {
    for (let x = 0; table[y] && x < table[y].length; x++) {
      if (table[y][x] && table[y][x].Active) {
        return {x, y};
      }
    }
  }
}

const currentAdvice = (state = initial, action) => {
  switch (action.type) {
    case constants.ACTIVATE_LANGUAGE: {
      return Object.assign({}, initial);
    }
    case constants.API_OUT_OF_DATE: {
      let {unit} = action.payload;
      return Object.assign({}, initial, {
        WorkingWidth: (unit===constants.METRIC) ? 12 : 36,
        ApplicationRate: (unit===constants.METRIC) ? 200 : 450,
        DrivingSpeed: (unit===constants.METRIC) ? 8 : 6
      });
    }
    case constants.FAVOURITE_LOAD_ADVICE: {
      return Object.assign({}, action.payload);
    }
    case constants.ADD_DEFAULT_SETTINGS: {
      let {unit} = action.payload;
      return Object.assign({}, state, {
        WorkingWidth: (unit===constants.METRIC) ? 12 : 36,
        ApplicationRate: (unit===constants.METRIC) ? 200 : 450,
        DrivingSpeed: (unit===constants.METRIC) ? 8 : 6
      });
    }
    case constants.ADVICE_NEW: {
      let {machineId} = action.payload;
      let un = action.payload.unit;
      let step = (machineId!==null) ? 2 : 1;
      return Object.assign({}, initial, {
        WorkingWidth: (un===constants.METRIC) ? 12 : 36,
        ApplicationRate: (un===constants.METRIC) ? 200 : 450,
        DrivingSpeed: (un===constants.METRIC) ? 8 : 6,
        machine: machineId,
        step
      });
    }
    case constants.ADVICE_ACTIVE_MACHINE: {
      return Object.assign({}, state, {
        machine: action.payload.id,
        validField: false,
        step: 2,
        id: null,
        applications: null,
        applicationId: null,
        fertiliserId: null,
        vanesetId: null,
        result: null,
        resultPdf: null,
        advicePossible: null,
        fineDosage: null,
        
        granuleSizeMinus2: 0,
        granuleSizeB23: 0,
        granuleSizeB34: 0,
        granuleSize4:0,
        adviceDensity: 0
      });
    }

    case constants.FETCH_SHARED_CHART_STARTED: {
      let data = action.payload;
      let distribution = data.distribution.split('-');

      return Object.assign({}, initial, {
        machine: data.machine,
        step: 5,

        WorkingWidth: Number(data.WorkingWidth),
        ApplicationRate: Number(data.ApplicationRate),
        DrivingSpeed: Number(data.DrivingSpeed),
        applicationType: data.applicationType,
        validField: true,
        
        shapeId: data.shapeId.split('-').map(Number),
        shapeRanges: null,
        granuleSizeMinus2: distribution[0],
        granuleSizeB23: distribution[1],
        granuleSizeB34: distribution[2],
        granuleSize4: distribution[3],
        adviceDensity: Number(data.density),
        
        applications: null,
        widerSearchCriteriaUsed: false, // dont care
        fineDosage: data.fineDosage,
        kitUsed: null,
        applicationId: null,
        fertiliserId: Number(data.fertilizer),
        vanesetId: Number(data.vanes),
      });
    }

    case constants.ADVICE_UPDATE_FIELD: {
      let {name, value} = action.payload;
      return Object.assign({}, state, {
        validField: false,
        [name]: value,
        applications: null,
        applicationId: null,
        fertiliserId: null,
        vanesetId: null,
        result: null,
        resultPdf: null,
        shapeRanges: null,
        advicePossible: null,
        step: 2,
        id: null,
        fineDosage: null
      });
    }
    case constants.ADVICE_UPDATE_FIELD_NO_RESET: {
      let{name, value} = action.payload;
      return {
        ...state,
        [name]: value
      }
    }
    case constants.VALIDATE_FIELD_SUCCESS: {
      return Object.assign({}, state, {
        validField: true,
        step: 3,
        id: null
      });
    }
    case constants.ADVICE_ACTIVE_SHAPE: {
      if(state.shapeId===action.payload.id) {
        return state;
      }
      return Object.assign({}, state, {
        shapeId: action.payload.id,
        step: 3,
        applications: null,
        kitUsed: null,
        applicationId: null,
        fertiliserId: null,
        vanesetId: null,
        result: null,
        resultPdf: null,
        advicePossible: null,
        widerSearchCriteriaUsed: false,
        id: null
      });
    }
    case constants.ADVICE_ACTIVE_SHAPE_ALL: {
      return Object.assign({}, state, {
        shapeId: action.payload.id,
        granuleSizeMinus2: 0,
        granuleSizeB23: 0,
        granuleSizeB34: 0,
        granuleSize4:0,
        adviceDensity: 0,
        kitUsed: null,
        widerSearchCriteriaUsed: false,
        id: null
      });
    }
    case constants.ADVICE_UPDATE_PROPS: {
      let {name, value, isValid} = action.payload;
      return Object.assign({}, state, {
        [name]: +value,
        step: 3,
        applications: null,
        kitUsed: null,
        applicationId: null,
        fertiliserId: null,
        vanesetId: null,
        result: null,
        resultPdf: null,
        advicePossible: null,
        widerSearchCriteriaUsed: false,
        id: null,
        isValid
      });
    }
    case constants.ADVICE_VALID_PROPS: {
      return Object.assign({}, state, {
        step: 4,
        id: null,
        isValid: true
      });
    }
    case constants.FETCH_APPLICATIONS_SUCCESS: {
      let VaneSets = null;
      if('VaneSets' in action.payload) {
        VaneSets = action.payload.VaneSets.map(
          (vs)=> {
            if(!('Applications' in vs)) {
              return vs;
            }
            let bm = [];
            let rest = [];
            vs.Applications.forEach((ite)=> {
              if(ite.IsBestMatch===true) {
                bm.push(ite)
              } else {
                rest.push(ite)
              }
            })
            vs.Applications = [...bm, ...rest];
            return vs;
          }
        );
      }
      return Object.assign({}, state, {
        widerSearchCriteriaUsed: action.payload.WiderSearchCriteriaUsed,
        applications: VaneSets,
        id: null
      });
    }
    case constants.ADVICE_ACTIVE_APPLICATION: {
      let {fertiliserId, vanesetId} = action.payload;
      return Object.assign({}, state, {
        fertiliserId,
        vanesetId,
        result: null,
        advicePossible: null,
        fineDosage: null,
        kitUsed: null,
        id: null,
        resultPdf: null
      });
    }
    case constants.FINE_DOSAGE_SUCCESS: {
      let {response} = action.payload;
      return Object.assign({}, state, {
        fineDosage: response,
        step: 5,
      });
    }
    case constants.ADIVCE_USE_KIT: {
      let {status} = action.payload;
      return Object.assign({}, state, {
        kitUsed: status
      });
    }
    case constants.FETCH_SHARED_CHART_SUCCESS:
    case constants.FETCH_RESULT_SUCCESS: {
      let {Advice, AdvicePossible, WarningTranslationKey, AdvicedDrivingSpeed} = action.payload.result;
      let resultTableX = 0;
      let resultTableY = 0;
      let id = new Date().getTime();

      if(AdvicePossible === false) {
        return {
          ...state,
          advicePossible: false,
          warningTranslationKey: WarningTranslationKey,
          adviceDrivingSpeed: AdvicedDrivingSpeed,
        }
      }
      if(Advice) {
        if(Advice.AdvicePossible===false) {
          return Object.assign({}, state, {
            advicePossible: false
          });
        }
        if(Advice.Table)
        {
          var position = getTablePosition(Advice.Table);
          resultTableX = position.x;
          resultTableY = position.y;
        }
      }
      return Object.assign({}, state, {
        result: Advice && {...Advice},
        baseResultTableX: resultTableX,
        baseResultTableY: resultTableY,
        resultTableX,
        resultTableY,
        id,
        advicePossible: true,
        resultPdf: null
      });
    }
    case constants.FETCH_PDF_SUCCESS: {
      let {DownloadURL} = action.payload;
      return Object.assign({}, state, {
        resultPdf: DownloadURL
      });
    }

    case constants.SET_TABLE_POSITION: {
      const {x, y} = action.payload;

      return Object.assign({}, state, {
        resultTableX: x,
        resultTableY: y,
      });
    }

    case constants.SET_APPLICATION_TYPE: {
      const {applicationType} = action.payload;

      return Object.assign({}, state, {
        applicationType: applicationType
      });
    }

    case constants.FETCH_SHAPE_RANGE_SUCCESS: {
      
      return Object.assign({}, state, {
        shapeRanges: action.payload.result
      });
    }

    default: {
      return state;
    }
  }
}
export default currentAdvice;
