import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';

import {getStore} from './store/configureStore';
import App from './containers/app';
import {getDefaultBrand} from './utils';

const basename = window['DATA'].router_path || '';

const defaultBrand = getDefaultBrand(window.location.href);

ReactDOM.render(
    <BrowserRouter basename={basename}>
      <Switch>
        <Route path="/cleancache" render={()=> {
          localStorage.clear();
          window.location.href = '/';
          return null;
        }} />
        <Route path="/:brand" render={(props)=> {
          const {brand} = props.match.params;
          const store = getStore(brand);
          return <Provider store={store}>
            <App />
          </Provider>
        }} />
        <Route render={() => (<Redirect to={`/${defaultBrand}`} />)} />
      </Switch>
    </BrowserRouter>,
  document.getElementById('root')
)
