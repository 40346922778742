import React, {Component} from 'react';
import {connect} from 'react-redux';

import {fetchSharedResult} from '../store/actions/currentadvice';


class SharedAdvice extends Component {
  componentDidMount() {
    const {history} = this.props;
    const params = Object.fromEntries(new URLSearchParams(history.location.search));
    if(params)
      this.fetchResult(params);   
  }

  componentWillUnmount() {
  }

  redirectTo(step) {
    let {match, history} = this.props;
    history.push(`/${match.params.brand}/advice/${step}/`);

  }
  componentWillReceiveProps(nextProps, nextContext) {
    if(nextProps.result) {
      this.redirectTo(5);
    }
  }  

  fetchResult = (data) => {
    this.props.fetchSharedResult(data);
  };

  render() {

    if(!this.props.result)
      return <div></div>

    return <div></div>
  }
}

const mapStateToProps = (state) => {
  return {
    result: state.currentAdvice.result,
  };
};

const actions = {
  fetchSharedResult
};

SharedAdvice = connect(mapStateToProps, actions)(SharedAdvice);

export default SharedAdvice;
