import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Switch, Route} from 'react-router';

import {getCurrentStep} from '../store/selectors';

import MachinesList from './machineslist';
import FieldSettings from './fieldsettings';
import ProductDetails from './productdetails';
import Applications from './applications';
import Results from './results';


import './scss/advice.scss';


class Advice extends Component {
  state = {
    adviceBarHidden: false,
  };

  componentWillReceiveProps(props) {
    this.validateUrl(props);
  }

  componentWillMount() {
    this.validateUrl(this.props);
  }

  validateUrl(props) {
    let {currentStep, match} = props;
    if(match.params.step > currentStep) {
      this.redirectTo(currentStep);
    }
  }

  redirectTo(step) {
    let {match, history} = this.props;
    history.push(`/${match.params.brand}/advice/${step}/`);

  }

  componentDidUpdate(prevProps) {
    if(this.props.location !== prevProps.location) {
      this.onRouteChanged(this.props.location);
    }
  }

  toggleHideAdviceBar = (state) =>
      this.setState({
        ...state,
        adviceBarHidden: state
      });

  onRouteChanged() {
    const selectedVaneSetNode = document.querySelector('.selected_vaneset_node');
    const selectedApplicationNode = document.querySelector('.selected_application_node');

    if(selectedVaneSetNode) {
      selectedVaneSetNode.scrollIntoView({inline: "center"});
    }

    if(selectedApplicationNode) {
      selectedApplicationNode.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  render() {
    const {match, currentStep} = this.props;
    return <div className='advice'>
      <Switch>
        {(currentStep > 1) &&
        <Route
          path={`${match.path}2/`}
          render={(props) =>
            <FieldSettings
              adviceBarHidden={this.state.adviceBarHidden}
              toggleHideAdviceBar={this.toggleHideAdviceBar}
              {...props}
            />
          }
        />
        }
        {(currentStep > 2) &&
        <Route
          path={`${match.path}3/`}
          render={(props) =>
            <ProductDetails
              adviceBarHidden={this.state.adviceBarHidden}
              toggleHideAdviceBar={this.toggleHideAdviceBar}
              {...props}
            />
          }
        />
        }
        {(currentStep > 3) &&
        <Route
          path={`${match.path}4/`}
          render={(props) =>
            <Applications
              adviceBarHidden={this.state.adviceBarHidden}
              toggleHideAdviceBar={this.toggleHideAdviceBar}
              {...props}
            />
          }
        />
        }
        {(currentStep > 4) && <Route path={`${match.path}5/`} component={Results}/>}
        <Route render={(props) =>
          <MachinesList
            adviceBarHidden={this.state.adviceBarHidden}
            toggleHideAdviceBar={this.toggleHideAdviceBar}
            {...props}
          />
        }
        />
      </Switch>
    </div>;
  }
}


const mapStateToProps = (state) => {
  return {
    currentStep: getCurrentStep(state)
  };
};
const actions = {};

Advice = connect(mapStateToProps, actions)(Advice);

export default Advice;
