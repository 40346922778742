import * as constants from '../constants';
import * as Api from '../../api';
import * as selectors from '../../store/selectors';
import {trackError, preloadStarted, preloadFinished} from './app';


const fetchOtherappsStarted = () => ({
  type: constants.FETCH_OTHERAPPS_STARTED,
});
const fetchOtherappsSuccess = (apps) => ({
  type: constants.FETCH_OTHERAPPS_SUCCESS,
  payload: apps
});
export const fetchOtherapps = () => (dispatch, getState) => {
  dispatch(preloadStarted());
  dispatch(fetchOtherappsStarted());
  let lang = selectors.getCurrentLanguageCode(getState());
  let brand = selectors.getBrand(getState());
  let plataform = selectors.getPlatform(getState());
  return Api.getOtherApps(lang, brand, plataform)
  .then((r) => {
    dispatch(preloadFinished());
    return dispatch(fetchOtherappsSuccess(r));
  })
  .catch(err => dispatch(trackError(err)));
}

