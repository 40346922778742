import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as selectors from '../store/selectors';
import {fetchRawTranslations, updateRawTranslations} from '../store/actions/locales';
import IconButton from '../components/iconbutton';

import './scss/translationsEditor.scss';

class TranslationsEditor extends Component {
    state = {
        changes: 0
    }

    componentDidMount() {
        if(this.props.rawTranslations.length === 0)
        {
            this.refresh();
        }
    }

    textChange(event, trans, raw)
    {
        trans.LocalText = event.target.value;
        trans.HasChanged = true;
        this.setState({rawTranslations: raw, changes: this.props.rawTranslations.filter(t => t.HasChanged).length});
    }

    componentWillReceiveProps(props) {
        if(props.rawTranslations === null || (props.rawTranslations.length === 0 && this.props.rawTranslations.length > 0)) {
            this.refresh();
        }
    }

    resetChanges()
    {
        this.setState({changes: 0});
    }

    refresh() {
        this.props.fetchRawTranslations();
        this.resetChanges();
    }

    save = () => {
        this.props.updateRawTranslations();
        this.resetChanges();
    }

    render () {
        let rawTranslations = (this.props.rawTranslations) ? this.props.rawTranslations : [];


        if(rawTranslations===null || rawTranslations.length === 0){
            return null;
        }
        
        return <div className={'container editor'}>
            <div className={'editor_header'}>             
                <h2>Translation editor</h2>  
                <p>Here you can edit the translations. Use the flag in the top right corner to change your language. Don't forget to press the save button when you're done.</p> 
                <IconButton

                    title={'Save translations'}
                    onClick={this.save}
                />
                <p>Unsaved changes: {this.state.changes}</p>
                </div>
            <div className={'editor_list_container'}>
            {
                rawTranslations.map((trans, i) => {
                    let inputState = trans.LocalText === '' ? "empty" : trans.HasChanged ? "changed" : "";

                    return <div className={'editor_list_item'} style={{display: 'block'}}>
                        <div className={'editor_list_item_header ' + inputState}>
                            <span>{trans.Id} - {trans.Key}</span>
                            <span style={{
                            float: 'right'
                        }}>{inputState}</span>
                        </div>
                        <div style={{
                            float: 'bottom',
                            width: '100%',
                            display: 'flex',
                            padding: '5px'
                        }}>
                            <div style={{width:'50%', float: 'left'}}>
                                <p>{trans.EnglishText}</p>
                            </div>
                            <div style={{width:'50%', float: 'right'}} >                            
                                <textarea style={{width:'100%', height: '100%', resize: 'vertical'}} type="text" value={trans.LocalText} 
                                    onChange={(event) => this.textChange(event, trans, rawTranslations)} />
                            </div>
                        </div>
                    </div>
                })
            }   
            </div>         
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        rawTranslations: selectors.getRawTranslations(state)
    }
}

const actions = {
    fetchRawTranslations,
    updateRawTranslations,
};
  
TranslationsEditor = connect(mapStateToProps, actions)(TranslationsEditor);

export default TranslationsEditor;