import React from 'react';
import './scss/optionselector.scss';


const OptionSelector = ({options, value, onChange}) => {
  const renderStyles = (index) => {
    if(index===0) {
      return {
        borderRadius: '4px 0 0 4px',
        marginRight: '-1px'
      }
    } else if(index===(options.length-1)) {
      return {
        borderRadius: '0 4px 4px 0',
        marginLeft: '-1px'
      }
    }
    return {}
  };

  return (
    <div className="optionselector">
      {options.length && options.map((option, index) =>
        <label
          key={option.name}
          className={`${value===option.value && 'selected'}`}
          style={renderStyles(index)}
          onClick={() => {
            onChange && onChange(option.value);
          }}
        >
          {option.name}
        </label>
      )}
    </div>
  )
};

export default OptionSelector;
