import * as constants from '../store/constants';

export function formatDate(time) {
  let dt = new Date(time);
  let date = ('0' + dt.getDate()).slice(-2) + '/' +
    ('0' + (dt.getMonth()+1)).slice(-2) + '/' +
    dt.getFullYear()
  return date;
}

export function isEmpty(string) {
  return string.length ===0
}

export function formatHour(time) {
  let dt = new Date(time);
  let hour = ('0' + dt.getHours()).slice(-2) + ':' +
    ('0' + dt.getMinutes()).slice(-2);
  return hour;
}

export function bodyClassList() {
  return document.getElementsByTagName("body")[0].classList
}

export function getDefaultBrand(url) {
  if (url.indexOf('vicon')!==-1) {
    return constants.BRANDS[1];
  } else if(url.indexOf('kubota')!==-1) {
    return constants.BRANDS[2];
  } else if(url.indexOf('accord')!==-1) {
    return constants.BRANDS[0];
  } else if(url.indexOf('streutabellen.de')!==-1) {
    return constants.BRANDS[1];
  }
  return constants.BRANDS[0];
}

export function existAndNotEmpty(list, key) {
  return list && key in list && list[key] !== ''
}

export function secondsToHms(t) {
  const d = Number(t);
  const h = Math.floor(d / 3600);
  const m = Math.floor(d % 3600 / 60);
  const s = Math.floor(d % 3600 % 60);

  const hDisplay = h > 0 ? h : "";
  const mDisplay = m > 0 ? `${m}` : (h > 0 || s > 0 ? "00:" : "");
  const sDisplay = s > 0 ? s : (m > 0 || h > 0 ? "00" : "");
  return hDisplay + mDisplay + sDisplay;
}

export function findIndex(array, comparison) {
  let index = -1;
  array.map(el => {
    if (el === comparison) {
      index++;
      return true;
    }
    return false;
  });
  return index
}
