import React, { Component } from 'react';
export class FavouritesHelp extends Component {
  render() {
    let { t } = this.props;

    return <div>
      <h2>
        {t("favourites_help_title")}
      </h2>
      <p>
        {t("favourites_help_text")}
      </p>
    </div>;
  }
}

export class MachineHelp extends Component {
    render() {
      let { t } = this.props;
  
      return <div>
        <h2>
          {t("machine_info_title")}
        </h2>
        <p>
          {t("machine_info_text")}
        </p>
      </div>;
    }
  }
  