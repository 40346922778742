import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as selectors from '../store/selectors';
import * as constants from '../store/constants';

import './scss/help.scss';
import Modal from './modal';
import { AdviceHelp } from './help/AdviceHelp';
import { FavouritesHelp, MachineHelp } from './help/FavouritesHelp';
import { PropertiesHelp } from './help/PropertiesHelp';
import { FieldHelp } from './help/FieldHelp';
import { ShapeHelp } from './help/ShapeHelp';

export class HelpIconNode extends Component {

  render() {    
    let {icon, title, text} = this.props;

    return <div><span>
      {
        icon && <img src={icon} alt={title} className={'icon'} />
      }
      <h3>{title}</h3>
    </span>
    <p>
      {text}
    </p></div>
  }
}

class Help extends Component {
  state = {section: 0, onText: false};

  componentDidMount() {
    document.getElementsByTagName("body")[0].classList.add('hideScroll');
  }

  componentWillUnmount() {
    document.getElementsByTagName("body")[0].classList.remove('hideScroll');
  }

  showMenu(section) {
    this.setState({section, onText: true});
  }

  goBack() {
    this.setState({onText:false});
  }

  render() {
    let {hideHelp, t, helpSection, icons} = this.props;
    let {section, onText} = this.state;

    if(!helpSection || helpSection === '')
    {
    return <div className={'help'+((onText===true) ? ' help--ontext' : '')}>
      <button
        onClick={()=> hideHelp()}
        className='help__close'
      >
        <svg width="15" height="15" viewBox="0 0 19.43 20.04"><use xlinkHref="#cross" /></svg>
      </button>

      <div className='help__wrapper'>
        
        <div className='help__menu'>
          <h2>{t('menu_help')}</h2>

          <ul>
            <li
              className={(section===0) ? 'active': ''}
              onClick={()=>this.showMenu(0)}>{t('page_title_machine_selection')}</li>
            <li
              className={(section===1) ? 'active': ''}
              onClick={()=>this.showMenu(1)}>{t('page_title_field_settings')}</li>
            <li
              className={(section===2) ? 'active': ''}
              onClick={()=>this.showMenu(2)}>{t('page_title_fertilizer_settings')}</li>
            <li
              className={(section===3) ? 'active': ''}
              onClick={()=>this.showMenu(3)}>{t('page_title_advice')}</li>
          </ul>
        </div>

        <div className='help__text'>
          <div className="help-content">
          {(() => {
            switch(section)
            {
              case 0:
                return <MachineHelp t={t} icons={icons}  />

              case 1:
                return <FieldHelp t={t} icons={icons}  />

              case 2:
                return <PropertiesHelp t={t} icons={icons}  />

              case 3:
              default:
                return <AdviceHelp t={t} icons={icons}  />              
            }
          })()
          }
          </div>
        </div>
      </div>
      <div onClick={()=> hideHelp()} className='help__overlay'></div>
    </div>
    }
    else {
      return <Modal onClose={()=> hideHelp()} closeTxt={t('button_close')} >
        <div className="help-content">
          
        {(() => {
            switch(helpSection)
            {
              case "Favourites":
                return <FavouritesHelp t={t} icons={icons} />

              case constants.HELP_PAGES.FIELD:
                return <FieldHelp t={t} icons={icons} />

              case constants.HELP_PAGES.PROPERTIES:
                return <PropertiesHelp t={t} icons={icons} />

              case constants.HELP_PAGES.SHAPE:
                return <ShapeHelp t={t} icons={icons} />

              case "":
              default:
                return <AdviceHelp t={t} icons={icons}  />              
            }
          })()
          }
          </div>
      </Modal>
    }
  }
}


const mapStateToProps = (state) => {
  return {
    t: selectors.getTranslations(state),
    icons: selectors.getIcons(state),
    helpSection: selectors.getHelpSection(state),
  };
};

const actions = {
};

Help = connect(mapStateToProps, actions)(Help);

export default Help;
