import React, { Component } from 'react';
import { HelpIconNode } from '../help';

export class ShapeHelp extends Component {
  render() {
    let { icons, t } = this.props;

    return <div>      
      <h2>
        {t('page_title_fertilizer_settings')}
      </h2>
      <p>
        {t("help-fertiliser-shape-description")}
      </p>
    </div>
  }
}