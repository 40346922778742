import icoFlag from './flag_icon.svg';
import icoFlagSuccess from './flag_icon_success.svg';
import icoSearch from './search_icon.svg';
import icoSearchBlue from './search_icon_blue.svg';
import w0 from './water_level_0.png';
import w4 from './water_level_4.png';
import w5 from './water_level_5.png';
import w8 from './water_level_8.png';
import w10 from './water_level_10.png';
import arrowLeft from './arrow_left.svg';
import trash from "./trash_icon.svg";
import star from "./star_icon.svg";
import play from "./play_button.svg";
import starBig from "./star_big.svg";
import starBigWhite from "./star_big_white.svg";
import file from "./file_icon.svg";
import email from "./email_icon.svg";
import downArrow from "./arrow_down.svg";
import reload from "./reload_icon.png";
import alert from "./alert_icon.png";
import qrcode from "./qrcode_icon.svg";
import sort from "./sort_icon.svg";
import share from "./share_icon.svg";
import barcode from "./barcode_icon.svg";


export default {
  icoFlag,
  icoFlagSuccess,
  arrowLeft,
  trash,
  star,
  play,
  icoSearch,
  icoSearchBlue,
  starBig,
  starBigWhite,
  file,
  email,
  downArrow,
  reload,
  alert,
  inclinations: {0: w0, 4: w4, 5:w5, 8:w8, 10:w10},
  qrcode,
  barcode,
  sort,
  share
}
