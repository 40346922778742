export const BRANDS = ['kverneland', 'vicon', 'kubota'];
export const METRIC = 'Metric';
export const IMPERIAL = 'Imperial';

export const PLATFORM_ANDROID = 'Android_Generic';
export const PLATFORM_IOS = 'iOS_Generic';
export const PLATFORM_DESKTOP = 'Desktop';
export const DETECT_PLATFORM = 'detect_platform';

export const PRELOAD_STARTED = 'preload_started';
export const PRELOAD_FINISHED = 'preload_finished';

export const ACCEPT_DISCLAIMER = 'accept_disclaimer';
export const ACCEPT_GDPR_DISCLAIMER = 'accept_gdpr_disclaimer';

export const MODAL_SHOW = 'modal_show';
export const MODAL_HIDE = 'modal_hide';

export const INITIALIZE_APP_STARTED = 'initialize_app_started';
export const INITIALIZE_APP_SUCCESS = 'initialize_app_success';

export const API_ERROR = 'api_error';

export const FETCH_LANGUAGES_STARTED = 'fetch_languages_started';
export const FETCH_LANGUAGES_SUCCESS = 'fetch_languages_success';

export const ACTIVATE_DEFAULT_LANGUAGE = 'activate_default_language';
export const ACTIVATE_LANGUAGE = 'activate_language';

export const UNITS_CHANGED = 'units_changed';

export const ADD_DEFAULT_SETTINGS = 'add_default_settings';

export const FETCH_TRANSLATIONS_STARTED = 'fetch_translations_started';
export const FETCH_TRANSLATIONS_SUCCESS = 'fetch_translations_success';

export const FETCH_RAW_TRANSLATIONS_STARTED = 'fetch_raw_translations_started';
export const FETCH_RAW_TRANSLATIONS_SUCCESS = 'fetch_raw_translations_success';

export const UPDATE_RAW_TRANSLATIONS_STARTED = 'update_raw_translations_started';
export const UPDATE_RAW_TRANSLATIONS_SUCCESS = 'update_raw_translations_success';

export const FETCH_ICONS_STARTED = 'fetch_icons_started';
export const FETCH_ICONS_SUCCESS = 'fetch_icons_success';

export const FETCH_SETTINGS_STARTED = 'fetch_settings_started';
export const FETCH_SETTINGS_SUCCESS = 'fetch_settings_success';

export const FETCH_MACHINES_STARTED = 'fetch_machines_started';
export const FETCH_MACHINES_SUCCESS = 'fetch_machines_success';
export const FETCH_MACHINE_STARTED = 'fetch_machine_started';
export const FETCH_MACHINE_SUCCESS = 'fetch_machine_success';
export const FETCH_MACHINE_BY_SERIAL_STARTED = 'fetch_machine_by_serial_started';
export const FETCH_MACHINE_BY_SERIAL_SUCCESS = 'fetch_machine_by_serial_success';
export const CLEAR_MACHINE_SEARCH = 'clear_machine_search';
export const MACHINE_SELECTED = 'machine_selected';

export const ADVICE_NEW = 'advice_new';
export const ADVICE_ACTIVE_MACHINE = 'advice_active_machine';
export const ADVICE_UPDATE_FIELD = 'advice_update_field';
export const ADVICE_UPDATE_FIELD_NO_RESET = 'advice_update_field_no_reset';
export const ADVICE_ACTIVE_SHAPE = 'advice_active_shape';
export const ADVICE_ACTIVE_SHAPE_ALL = 'advice_active_shape_all';
export const ADVICE_UPDATE_PROPS = 'advice_update_props';
export const ADVICE_VALID_PROPS = 'advice_valid_props';
export const ADVICE_ACTIVE_APPLICATION = 'advice_active_application';
export const ADIVCE_USE_KIT = 'adivce_use_kit';

export const CLOSE_APP_POPUP = 'close_app_popup';

export const FINE_DOSAGE_STARTED = 'fine_dosage_started';
export const FINE_DOSAGE_SUCCESS = 'fine_dosage_success';

export const VALIDATE_FIELD_STARTED = 'validate_field_started';
export const VALIDATE_FIELD_SUCCESS = 'validate_field_success';
export const VALIDATE_FIELD_FAILED = 'validate_field_failed';

export const FETCH_SHAPES_STARTED = 'fetch_shapes_started';
export const FETCH_SHAPES_SUCCESS = 'fetch_shapes_success';

export const FETCH_APPLICATIONS_STARTED = 'fetch_applications_started';
export const FETCH_APPLICATIONS_SUCCESS = 'fetch_applications_success';

export const FETCH_SHARED_APPLICATIONS_SUCCESS = 'fetch_shared_applications_success';

export const FETCH_RESULT_STARTED = 'fetch_result_started';
export const FETCH_RESULT_SUCCESS = 'fetch_result_success';

export const FETCH_PDF_STARTED = 'fetch_pdf_started';
export const FETCH_PDF_SUCCESS = 'fetch_pdf_success';

export const FETCH_SHARED_ADVICE_STARTED = 'fetch_shared_advice_started';
export const FETCH_SHARED_ADVICE_SUCCESS = 'fetch_shared_advice_success';
export const CLEAR_SHARED_RESULT_STATE = 'clear_shared_result_state';

export const FETCH_AUTOSETAPP_STARTED = 'fetch_autosetapp_started';
export const FETCH_AUTOSETAPP_SUCCESS = 'fetch_autosetapp_success';

export const FAVOURITE_STORE_ADVICE = 'favourite_store_advice';
export const FAVOURITE_LOAD_ADVICE = 'favourite_load_advice';
export const FAVOURITE_REMOVE_ADVICE = 'favourite_remove_advice';

export const FETCH_OTHERAPPS_STARTED = 'fetch_otherapps_started';
export const FETCH_OTHERAPPS_SUCCESS = 'fetch_otherapps_success';

export const FETCH_RESSELLERS_STARTED = 'fetch_ressellers_started';
export const FETCH_RESSELLERS_SUCCESS = 'fetch_ressellers_success';

export const FETCH_VIDEOS_STARTED = 'fetch_videos_started';
export const FETCH_VIDEOS_SUCCESS = 'fetch_videos_success';

export const FETCH_SPREADERVIDEOS_STARTED = 'fetch_spreadervideos_started';
export const FETCH_SPREADERVIDEOS_SUCCESS = 'fetch_spreadervideos_success';

export const FETCH_FAQ_STARTED = 'fetch_faq_started';
export const FETCH_FAQ_SUCCESS = 'fetch_faq_success';

export const HELP_SHOW = 'help_show';
export const HELP_HIDE = 'help_hide';

export const FETCH_SHARED_CHART_STARTED = 'fetch_shared_chart_started';
export const FETCH_SHARED_CHART_SUCCESS = 'fetch_shared_chart_success';

export const FETCH_SHAPE_RANGE_STARTED = 'fetch_shape_ranges_started';
export const FETCH_SHAPE_RANGE_SUCCESS = 'fetch_shape_ranges_success';

export const FETCH_LEGAL_LINKS_STARTED = 'fetch_legal_links_started';
export const FETCH_LEGAL_LINKS_SUCCESS = 'fetch_legal_links_success';

export const HELP_PAGES = {
    ADVICE: "Advice",
    ADVICE_BORDER: "Advice Border",
    
    FIELD: "Field",
    MACHINE: "Machine",
    SHAPE: "Shape",
    PROPERTIES: "Properties",
    APPLICATIONS: "Applications",
}

export const CHECK_API_VERSION_STARTED = 'check_api_version_started';
export const CHECK_API_VERSION_SUCCESS = 'check_api_version_success';
export const API_OUT_OF_DATE = 'api_out_of_date';

export const SET_TABLE_POSITION = 'set_table_position';
export const SET_APPLICATION_TYPE = 'set_application_type';
export const SET_SHARED_TABLE_POSITION = 'set_shared_table_poisition';

export const FETCH_APP_DOWNLOAD_INFO_SUCCESS = 'fetch_app_download_info_success';
