export const isFetchingShapes = (state) => {
  return state.shapes.fetching;
};

export const getShapes = (state) => {
  let obj =  state.shapes.byId;
  obj = (obj) ? obj : [];
  return Object.keys(obj).map((key) => obj[key]);
};

export const getShapeName = (state, id) => {
  const shapes = getShapes(state);
  return id && id.length === 1 && shapes && shapes.length > 0 ? shapes.filter(shape => shape.Id===id[0])[0].Key : null;
};
