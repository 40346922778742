import * as constants from '../constants';
import * as Api from '../../api';
import {preloadFinished} from './app';
import {getCurrentUnits} from '../selectors';


const fetchSettingsStarted = () => ({
  type: constants.FETCH_SETTINGS_STARTED,
});
const fetchSettingsSuccess = (payload) => ({
  type: constants.FETCH_SETTINGS_SUCCESS,
  payload
});
export const fetchSettings = () => (dispatch, getState) => {
  let units = getCurrentUnits(getState());
  dispatch(fetchSettingsStarted());
  return Api.getSettings(units)
    .then((r) => {
      dispatch(fetchSettingsSuccess(r))
      return dispatch(preloadFinished());
    });
}
