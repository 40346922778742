import React, {Component} from 'react';
import {connect} from 'react-redux';

import FertilizerProps from './fertilizerprops';
import ProductProps from './productprops';
import Advicebar from '../components/advicebar';
import { validFertilizerProps } from '../store/actions/currentadvice';
import * as selectors from '../store/selectors';
import {showModal} from '../store/actions/app';

class ProductDetails extends Component {
  state = {
    stage: 1,
    multipleShapesMsgShown: false
  };

  onSubmit() {
    let {match, history, UsesFractioning} = this.props;
    const { stage, multipleShapesMsgShown} = this.state;
    if(stage === 1 && this.hasMultipleShapes() && !multipleShapesMsgShown) {
      this.props.showModal({
        title: "attention-shapes-title",
        text: "attention-shapes-message"
      });      
      this.setState((curState) => {
        return {
          ...curState,
          multipleShapesMsgShown: true
        }
      })
    }
    
    if(stage === 1 && UsesFractioning) {
      this.setState((curState) => {
        return {
          ...curState,
          stage: 2,          
          multipleShapesMsgShown: false
        }
      })
    } else {
      this.props.validFertilizerProps();
      history.push(`/${match.params.brand}/advice/4/`);
    }
  }

  hasMultipleShapes() {
    const {shapeId} = this.props;    
    return (shapeId && shapeId.length > 1);
  }

  isValid() {
    const {isValid, shapeId} = this.props;

    if(this.state.stage === 1) {
      return (shapeId && shapeId.length > 0);
    }
    return isValid;
  }

  onBack() {
    this.setState((curState) => {
      return {
        ...curState,
        stage: 1
      }
    });
  }

  render() {
    const { stage } = this.state;
    const { t, toggleHideAdviceBar, adviceBarHidden } = this.props;

    return <div className='container'>
      {
        (stage === 2) &&
        <a
          onClick={() => this.onBack()}
          className="machines__breadcrumb"
        >
          &lt; {t('button_back')}
        </a>
      }
      {
        stage === 1
          ? <FertilizerProps />
          : <ProductProps toggleHideAdviceBar={toggleHideAdviceBar} />
      }
      <Advicebar
        currentStep={3}
        onNext={()=> this.onSubmit()}
        nextEnabled={this.isValid()}
        hidden={adviceBarHidden}
      />
    </div>
  }
}

const mapStateToProps = (state) => {
  const machineId = selectors.getCurrentMachineId(state);
  const machines = selectors.getMachines(state);

  return {
    shapeId: selectors.getCurrentShapeId(state),
    t: selectors.getTranslations(state),
    isValid: selectors.getCurrentIsValid(state),
    UsesFractioning: machines[machineId].UsesFractioning,
  };
};

const actions = {
  validFertilizerProps,
  showModal,
};

ProductDetails = connect(mapStateToProps, actions)(ProductDetails);

export default ProductDetails;
