import * as constants from '../constants';


const initial = {};

const settings = (state = initial, action) => {
  switch (action.type) {
    case constants.FETCH_SETTINGS_SUCCESS: {
      let settings = {};
      action.payload.forEach((s) => {
        settings[s.Key] = s.Value;
      });
      return Object.assign({}, state, settings);
    }
    case constants.API_OUT_OF_DATE:
    case constants.ACTIVATE_LANGUAGE: {
      return initial;
    }
    default: {
      return state;
    }
  }
}
export default settings;
