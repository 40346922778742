import React, {Component} from 'react';
import {connect} from 'react-redux';
import {updateProps} from '../store/actions/currentadvice';
import * as selectors from '../store/selectors';
import * as constants from '../store/constants';
import {showHelp} from '../store/actions/app';
import InputRange from '../components/inputrange';
import GraphicBar from '../components/graphicbar';
import { METRIC } from '../store/constants';

function calcDensityValue(units, densityLimits, value) {
  if (units === METRIC) {
    if (value >= (densityLimits.min * 1000) && value <= densityLimits.max * 1000) {
      return value / 1000;
    }
    return value;
  }
  return value;
}

function isValidProductProps(sum, densityLimits, targetDensityValue) {
  return (sum === 0 || sum === 100) &&
    (
      targetDensityValue === 0 ||
      (targetDensityValue >= densityLimits.min && targetDensityValue <= densityLimits.max)
    );
}

class ProductProps extends Component {

  state = {sum: 0, errorSum: false};

  componentWillReceiveProps(props) {
    this.calcSum(props.properties);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {isValid, properties, densityLimits, units} = nextProps;
    const {sum} = nextState;
    const targetDensityValue = calcDensityValue(units, densityLimits, properties.adviceDensity);

    if(isValid !== isValidProductProps(sum, densityLimits, targetDensityValue)) {
      this.props.updateProps(
        null,
        null,
        isValidProductProps(sum, densityLimits, targetDensityValue)
      );
    }

    return true;
  }

  componentDidMount() {
    this.calcSum(this.props.properties);
  }

  calcSum(props) {
    let sum = 0;
    ['granuleSizeMinus2', 'granuleSizeB23',
      'granuleSizeB34', 'granuleSize4']
      .forEach((i)=> sum+=Number(props[i]));
    let sumError = true;
    let statusSum = 'error';
    if(sum===0 || sum===100) {
      sumError = false;
      statusSum = '';
    }
    statusSum = (sum===100) ? 'success' : statusSum;
    this.setState({sumError, sum, statusSum});
  }

  handleChange(f,v) {
    const { properties, densityLimits, units } = this.props;
    const { sum } = this.state;
    const targetDensityValue = calcDensityValue(
      units,
      densityLimits,
      (f === 'adviceDensity') ? v : properties.adviceDensity);

    this.props.updateProps(f, v, isValidProductProps(sum, densityLimits, targetDensityValue));
  }

  render() {
    const {
      densityLimits,
      properties,
      t,
      showHelp,
      toggleHideAdviceBar,
      icons
    } = this.props;
    const {sum, statusSum} = this.state;

    return <div className='fertilizerprops'>
      <h2 className='advice__title'>
        {t('page_title_density_and_fractioning_settings')}
        <a
          className='advice__title__help'
          onClick={() => showHelp(constants.HELP_PAGES.PROPERTIES)}
        ><svg viewBox="0 0 30 30"><use xlinkHref="#help" /></svg></a>
      </h2>
      <div className='fertilizerprops__fields'>
        <div className='fertilizerprops__density'>
            <InputRange
              icon={icons.Density}
              maxLength={5}
              label={t('advice_density')}
              min={densityLimits.min}
              max={densityLimits.max}
              canBeZero={true}
              help={t('unit_density')}
              errorMsg={`${t('fractioning_invalid_density')}
                ${densityLimits.min} - ${densityLimits.max}`}
              defaultValue={+properties.adviceDensity}
              onChange={(v)=> this.handleChange('adviceDensity', v)}
              modifyBeforeUpdate={(value) => {
                const { densityLimits, units } = this.props;
                return calcDensityValue(units, densityLimits, value);
              }}
              toggleHideAdviceBar={toggleHideAdviceBar}
            />
          <div
            style={{
              overflowWrap: 'break-word',
              width: '50%',
              padding: '0 16px',
              fontSize: 12,
              color: '#333338',
              fontWeight: 'normal',
              paddingTop: 38,
            }}
          >
            {t('page_title_density_and_fractioning_density_value_question')}
          </div>
        </div>
        <div style={{width: "100%"}}>
          <div className={`inputrange inputrange--disabled ${statusSum}`}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div
                className="truncate-text inputrange__label"
              >
                {t('page_title_density_and_fractioning_label_distribution')}
              </div>
              <div
                style={{
                  color:
                    sum === 100
                      ? "#007B3B"
                      : sum <= 100 ? "#4B69FB" : "#d31822",
                  fontSize: 14,
                  fontWeight: "bold",
                  fontStyle: "oblique",
                  paddingRight: ".15em",
                }}
                className="truncate-text"
              >
                {
                  sum === 100
                  ? `${100}%`
                  : sum <= 100
                    ? `${100 - sum}% ${t('page_title_density_and_fractioning_sum_left')}`
                    : `${sum}% ${t('page_title_density_and_fractioning_sum_extra')}`
                }
              </div>
            </div>
          </div>
          <div style={{height: 77}}>
            <GraphicBar
              v1={properties.granuleSizeMinus2}
              v2={properties.granuleSizeB23}
              v3={properties.granuleSizeB34}
              v4={properties.granuleSize4}
              status={statusSum}
            />
          </div>
          <div
            className={`${sum > 100 ? "sum-error" : ""}`}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: -5,
              marginBottom: 40
            }}
          >
            <div style={{ width: "23%"}}>
              <InputRange
                label={'< 2.0 mm'}
                maxLength={4}
                min={0}
                max={100}
                canBeZero={true}
                help={'%'}
                errorMsg={''}
                defaultValue={properties.granuleSizeMinus2}
                onChange={(v)=> this.handleChange('granuleSizeMinus2', v)}
                toggleHideAdviceBar={toggleHideAdviceBar}
              />
            </div>
            <div style={{ width: "23%"}}>
              <InputRange
                label={'2.0 - 3.3 mm'}
                maxLength={4}
                min={0}
                max={100}
                canBeZero={true}
                help={'%'}
                errorMsg={''}
                defaultValue={properties.granuleSizeB23}
                onChange={(v) => this.handleChange('granuleSizeB23', v)}
                toggleHideAdviceBar={toggleHideAdviceBar}
              />
            </div>
            <div style={{ width: "23%"}}>
              <InputRange
                label={'3.3 - 4.75 mm'}
                maxLength={4}
                min={0}
                max={100}
                canBeZero={true}
                help={'%'}
                errorMsg={''}
                defaultValue={properties.granuleSizeB34}
                onChange={(v) => this.handleChange('granuleSizeB34', v)}
                toggleHideAdviceBar={toggleHideAdviceBar}
              />
            </div>
            <div style={{ width: "23%"}}>
              <InputRange
                label={'> 4.75 mm'}
                maxLength={4}
                min={0}
                max={100}
                canBeZero={true}
                help={'%'}
                errorMsg={''}
                defaultValue={properties.granuleSize4}
                onChange={(v)=> this.handleChange('granuleSize4', v)}
                toggleHideAdviceBar={toggleHideAdviceBar}
              />
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    properties: selectors.getCurrentProperties(state),
    densityLimits: selectors.getDensityLimits(state),
    t: selectors.getTranslations(state),
    units: selectors.getCurrentUnits(state),
    icons: selectors.getIcons(state),
    isValid: selectors.getCurrentIsValid(state),
  };
};

const actions = {
  updateProps,
  showHelp,
};

ProductProps = connect(mapStateToProps, actions)(ProductProps);

export default ProductProps;
