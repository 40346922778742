import React, { Component } from 'react';
import PropTypes from 'prop-types';

import icons from './img/icons';

class FaqItem extends Component {
  state = {
    isExpanded: false
  };

  handleClick = () => {
    this.setState({
      ...this.state,
      isExpanded: !this.state.isExpanded
    });
  };

  render() {
    const { question, answer, id } = this.props;
    const { isExpanded } = this.state;
    const maxHeight = window.innerWidth <= 500 ? 500 : 160;

    return (
      <div
        className="faq__item"
        
        ga-on="click"
        ga-event-category="FAQ"
        ga-event-action="read"
        ga-event-label={id}

        onClick={this.handleClick}
      >
        <div
          className="faq__item__question"
        >
          <h2>{question}</h2>
          <img
            src={icons.downArrow}
            alt=""
            style={{
              transform: `${isExpanded ? "rotate(180deg)" : ""}`,
            }}
          />
        </div>
        <div
          className="faq__item__answer"
          style={{
            maxHeight: isExpanded ? maxHeight : 0,
          }}
        >
          <p>{answer}</p>
        </div>
      </div>
    );
  }
}

FaqItem.propTypes = {
  id: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
};

export default FaqItem;
