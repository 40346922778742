import * as constants from '../constants';

const spreaderVideos = (state = null, action) => {
  switch (action.type) {
    case constants.FETCH_SPREADERVIDEOS_SUCCESS: {
      return [...action.payload];
    }
    case constants.API_OUT_OF_DATE:
    case constants.ACTIVATE_LANGUAGE: {
      return null;
    }
    default: {
      return state;
    }
  }
};

export default spreaderVideos;
