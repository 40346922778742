import React, { Component } from 'react';
import { HelpIconNode } from '../help';
export class PropertiesHelp extends Component {
  render() {
    let { icons, t } = this.props;

    return <div>
      <h2>
        {t("help-fertiliser-properties-header")}
      </h2>
      <p>
        {t("help-fertiliser-properties-description")}
      </p>

      <HelpIconNode
        icon={icons.Density}
        title={t('advice_density')}
        text={t('help-fertiliser-density-description')} />

      <HelpIconNode
        icon={icons.Distribution}
        title={t('page_title_density_and_fractioning_label_distribution')}
        text={t('help-fertiliser-distribution-description')} />
    </div>;
  }
}
