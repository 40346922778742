import * as constants from '../constants';
import * as Api from '../../api';
import * as selectors from '../../store/selectors';
import {trackError, preloadStarted, preloadFinished} from './app';


const fetchRessellersStarted = () => ({
  type: constants.FETCH_RESSELLERS_STARTED,
});
const fetchRessellersSuccess = (ressellers) => ({
  type: constants.FETCH_RESSELLERS_SUCCESS,
  payload: ressellers
});
export const fetchRessellers = () => (dispatch, getState) => {
  dispatch(preloadStarted());
  dispatch(fetchRessellersStarted());
  let lang = selectors.getCurrentLanguageCode(getState()),
    units = selectors.getCurrentUnits(getState()),
    brand = selectors.getBrand(getState());
  return Api.getRessellers(lang, brand, units)
  .then((r) => {
    dispatch(preloadFinished());
    return dispatch(fetchRessellersSuccess(r));
  })
  .catch(err => dispatch(trackError(err)));
}

