import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as selectors from '../store/selectors';
import * as utils from '../utils';

import './scss/about.scss';

import HeaderImage from '../components/headerimage';

class About extends Component {

  componentDidMount() {
    utils.bodyClassList().add('whiteBackground');
  }

  componentWillUnmount() {
    utils.bodyClassList().remove('whiteBackground');
  }

  render() {
    const {t, match, apiVersion, dbVersion, platform, webview, browser} = this.props;

    return <div className='about'>
      <HeaderImage t={t} title="about_page_title" brand={match.params.brand}/>
      <div className='container about__content'>
        <div className="about__content__versions">
          <h2>{t('about_version_header')}</h2>
          <span>{`${t('about_front_version')} (0.2.0)`}</span>
          <span>{`${t('about_db_version')} (${dbVersion})`}</span>
          <span>{`${t('about_api_version')} (${apiVersion})`}</span>
        </div>
        <h2>{t('about_important_header')}</h2>
        <div
          className='about__text'
          dangerouslySetInnerHTML={{__html: t('disclaimer_body')}}
        />
      </div>
    </div>;
  }
}

const mapStateToProps = (state) => {
  return {
    t: selectors.getTranslations(state),
    apiVersion: selectors.getApiVersion(state),
    dbVersion: selectors.getApiDatabaseVersion(state),
  };
};
const actions = {};
About = connect(mapStateToProps, actions)(About);
export default About;
