import * as constants from '../constants';
import * as Api from '../../api';
import * as selectors from '../../store/selectors';
import {trackError, preloadStarted, preloadFinished} from './app';


const fetchFAQStarted = () => ({
  type: constants.FETCH_FAQ_STARTED,
});

const fetchFAQSuccess = (faqs) => ({
  type: constants.FETCH_FAQ_SUCCESS,
  payload: faqs
});

export const fetchFAQ = () => (dispatch, getState) => {
  dispatch(preloadStarted());
  dispatch(fetchFAQStarted());
  const brand = selectors.getBrand(getState());
  const units = selectors.getCurrentUnits(getState());
  const lang = selectors.getCurrentLanguageCode(getState());

  return Api.getFAQ(brand, units, lang)
    .then((r) => {
      dispatch(preloadFinished());
      return dispatch(fetchFAQSuccess(r));
    })
    .catch(err => dispatch(trackError(err)));
};
