export const getRateLimits = (state) => {
  let min = state.settings.rateMin;
  let max = state.settings.rateMax;
  return {min, max};
}

export const getSpeedLimits = (state) => {
  let min = state.settings.speedMin;
  let max = state.settings.speedMax;
  return {min, max};
}


export const getDensityLimits = (state) => {
  let min = state.settings.densityMin;
  let max = state.settings.densityMax;
  return {min, max};
}
