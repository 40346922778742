import React, { Component } from 'react';

import {ComboBox} from './combobox';

import './scss/tableapplications.scss';


class TableApplications extends Component {
  state = {
    orderBy: null
  }

  /*
  * Returns an average number from the fractioning/distribution. The number will reach from 0(small granules) to 300(big granules).
  * Returns -1 if the fractioning wasn't properly formatted.
  */
  getAverageFractioningNumber(fractioning)
  {
    var splitted = fractioning.split("-").map(Number);

    if(splitted.length < 4)
      return -1;


    return splitted[1] + splitted[2] * 2 + splitted[3] * 3;
  }

  getOptions(t, usesFractioning, isFiltered) {
    if(usesFractioning && isFiltered)
    {
      return [{Key: "BestMatch", Label: t("fertiliser_list_match_label")}, 
        {Key: "Shape", Label: t("title_shapes")},  
        {Key: "Distribution", Label: t("page_title_density_and_fractioning_label_distribution")},  
        {Key: "Density", Label: t("advice_density")},  
        {Key: "Name", Label: t("results-overall-product-title")}];
    }
    else if(usesFractioning)
    {      
      return [{Key: "Shape", Label: t("title_shapes")},  
        {Key: "Distribution", Label: t("page_title_density_and_fractioning_label_distribution")},  
        {Key: "Density", Label: t("advice_density")},  
        {Key: "Name", Label: t("results-overall-product-title")}];
    }
    else
    {
      return [{Key: "Name", Label: t("results-overall-product-title")}];
    }
  }

  compareStrings(stringA, stringB) {    
    var upperA = stringA.toUpperCase();
    var upperB = stringB.toUpperCase();
    if(upperA > upperB)
      return 1;          
    if(upperB > upperA)
      return -1;          
    return 0;
  }

  getSortCompareFunction(orderByKey) {    
    switch (orderByKey) { 
      case "BestMatch":
        return (a, b) => b.MatchScore - a.MatchScore;

      case "Density":
        return (a, b) => a.Density - b.Density;

      case "Distribution":
        return (a, b) => 
        {
          var fA = this.getAverageFractioningNumber(a.Fractioning);
          var fB = this.getAverageFractioningNumber(b.Fractioning);
          return fA - fB;
        };

      case "Shape":
        return (a, b) => this.compareStrings(a.Shape, b.Shape);

      case "Name":
        return (a, b) => this.compareStrings(a.Name, b.Name);

      //should never trigger.
      default:
        return (a, b) => 0;
    }
  }

  render() {
    const { activeApplication, active, list, t, UsesFractioning } = this.props;
    const isFiltered = list.filter(a => a.MatchScore > 0).length > 0;

    var options = this.getOptions(t, UsesFractioning, isFiltered);
    if(!this.state.orderBy || !options.some(o => o.Key === this.state.orderBy.Key))
    {
      this.setState({...this.state, orderBy: options[0]});
      return null;
    }

    const compareFunction = this.getSortCompareFunction(this.state.orderBy.Key);
      //...list because javascript sucks. Protects the list of mutilation.
    const orderedList = [...list].sort(compareFunction);

    return <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column',
        width: '100%',
        marginTop: 15
      }}
    >
      <p className="list-label">
        {`${t('fertilizer_list_results_label')} (${list.length})`}
      </p>
      
      <ComboBox options={options} 
        value={this.state.orderBy}
        onChange={(v) => this.setState({...this.state, orderBy: v})} />

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexFlow: 'column',
          width: '100%',
        }}
      >
        {
          orderedList.map((ele, i) => {
            // let classes = (active === ele.Id) ? ' tableapplications__row--active' : '';
            // classes = (ele.IsBestMatch) ? (classes + ' bestMatch') : classes;
            // background: url(../img/icons/ico_best_match.png) no-repeat;
            // background-size: cover;
            // width: 25px;
            // height: 22px;
            const isActive = (active === ele.Id);

            return <div key={ele.Id} style={{width: '100%'}}>
              
              {
                i !== 0 && <div className='applications__divider' />
              }
              <div
                className={`applications__item`}
                onClick={() => activeApplication(ele.FertilizerId)}
              >
                
                <div className={`applications__item__colls`}>
                  <img
                    src={ele.ImageUrl}
                    alt={ele.Name}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginLeft: 12,
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div
                        className={"truncate-text"}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%'
                        }}
                      >
                        <div
                          className={"truncate-text"}
                          style={{
                            color: '#333338',
                            fontSize: 16,
                            fontWeight: 'bold',
                            letterSpacing: 0,
                            width: 'calc(100% - 10px)',
                          }}
                        >
                          {ele.Name}
                        </div>
                        <div
                          className={"truncate-text"}
                          style={{
                            color: '#333338',
                            fontSize: 13,
                            fontWeight: 400,
                            letterSpacing: 0,
                            width: '100%'
                          }}
                        >
                          <div>{ele.Producer}</div>
                          <div>{ele.Shape}</div>
                          {/*<div style={{ fontWeight: 'normal' }}>{ele.Producer}</div>*/}
                          {
                            UsesFractioning &&
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                width: '100%',
                                fontWeight: 'normal'
                              }}
                            >
                              <div style={{ fontWeight: 'normal', marginRight: 15 }}>
                                {ele.Fractioning}
                              </div>
                              <div style={{ fontWeight: 'normal' }}>
                                {`${ele.Density} ${t('unit_density')}`}
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      {
                        (ele.MatchScore > 0) && <div
                          style={{
                            position: 'absolute',
                            top: 3,
                            right: 8,
                            color: "#007B3B",
                            fontSize: 10,
                            fontStyle: "oblique",
                            fontWeight: "bold",
                            minWidth: 30,
                          }}
                        >
                          <div className="star-ratings">
                            <div className="fill-ratings" style={{width: ele.MatchScore + '%'}}>
                              <span>★★★★★</span>
                            </div>
                            <div className="empty-ratings">
                              <span>★★★★★</span>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>;
          })
        }
      </div>
    </div>;
  }
}

export default TableApplications;
