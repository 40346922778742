import React from 'react';
import {Link} from 'react-router-dom';

import icons from './img/icons';

export const Step1 = ({stepClass, t, machineName, match:{params}}) =>
  <Link
    className={stepClass}
    to={`/${params.brand}/advice/1/`}
  >
    <span>1</span>
    <h3>{t('selected_machine')}</h3>
    <p>{machineName}</p>
  </Link>;

export const Step2 = ({stepClass, t, field, stepCompleted, hasSpout, match,
  applicationType}) =>
  <Link
    className={stepClass}
    to={`/${match.params.brand}/advice/2/`}
  >
    <span>2</span>
    <h3>{t('field_settings_info_title')}</h3>
    {(stepCompleted>2) && <p>
      {field.WorkingWidth} {t('unit_distance')}{' - '}
      {field.ApplicationRate} {t('unit_rate')}{' - '}
      {field.DrivingSpeed} {t('unit_speed')}
    </p>}
    {(stepCompleted>2 && hasSpout===false) &&
      <p>{t(applicationType)}</p>}
  </Link>;

export const Step3 = ({stepClass, t, properties, stepCompleted, shapeName, match}) => {
  let textProps = '';
  let hasDensity = 'adviceDensity' in properties;
  let hasFract = 'granuleSizeMinus2' in properties;

  if(hasDensity) {
    textProps = ` ${properties.adviceDensity} ${t('unit_density')}`;
    if(hasFract)
      textProps += ' - ';
  }

  if(hasFract) {
    textProps += `${properties.granuleSizeMinus2}-${properties.granuleSizeB23}-${properties.granuleSizeB34}-${properties.granuleSize4}`;
  }

  return <Link
    className={stepClass}
    to={`/${match.params.brand}/advice/3/`}
  >
    <span>3</span>
    <h3>{t('page_title_fertilizer_settings')}</h3>
    {(stepCompleted>3) && <p>{textProps}</p>}
    {(stepCompleted>3) && <p>{shapeName}</p>}
  </Link>
};

export const Step4 = ({stepClass, match, t, application, stepCompleted, result}) => {
  var name = '';

  if(result)
    name = result.FertiliserName;

  if(application)
    name = application.Name;

  return <Link
    className={stepClass}
    to={`/${match.params.brand}/advice/4/`}
  >
    <span>4</span>
    <h3>{t('page_title_fertilizer_list')}</h3>
    {(stepCompleted>4) && <p>{name}</p>}
  </Link>;
}

export const Step5 = ({stepClass, t, match, currentStep}) =>
  <Link
    className={stepClass}
    to={`/${match.params.brand}/advice/5/`}
  >
    <img alt='flag icon' src={currentStep===5? icons.icoFlagSuccess : icons.icoFlag} />
    <h3>{t('advice_info_title')}</h3>
  </Link>;
