import React from 'react';

import './scss/adviceresultitem.scss';

const AdviceResultItem = ({header, value, units, ico, isImageValueType, isValueCondensed = true}) => {
  return <div className={'advice-result-item'}>
    <div className={'icon-wrapper'}>
      <img
        className={`icon`}
        src={ico}
        alt=""
      />
    </div>
    <div className={'advice-result-title'}>
      <div className={'light-small-title'}>
        {header}
      </div>
    </div>

    <div className={'advice-result-container'}>

    {
        isImageValueType
          ? <img className={'icon'} src={value} alt=""/>
          : <div>
          <span className={isValueCondensed ? "advice-result-value" : "advice-result-value NonCondensedValue"}>
            {value}
          </span>
          <span className={'advice-result-unit'}>
            {units}
          </span>
          </div>
      }
    </div>
  </div>
}

export default AdviceResultItem;
