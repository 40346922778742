import React, {Component} from 'react';
import {connect} from 'react-redux';

import {showHelp} from '../store/actions/app';
import {
  fetchPdf,
  fetchResult,
  newAdvice,
  updateFieldWithoutReset,
  useKit
} from '../store/actions/currentadvice';
import * as selectors from '../store/selectors';
import * as constants from '../store/constants';

import Modal from '../components/modal';
import ResultOverall from '../components/resultoverall';
import ResultAdvice from '../components/resultadvice';
import ResultBoundary from '../components/resultboundary';

import './scss/results.scss';
import Advicebar from '../components/advicebar';
import IconButton from '../components/iconbutton';
import localIcons from '../components/img/icons';
import {favouriteStoreAdvice} from '../store/actions/history';
import InputModal from '../components/inputmodal';
import favourites from '../store/reducers/favourites';

 const objectKeysToEmailQueryParams = (obj) =>
   Object.entries(obj).reduce((acc, [key, value], index) =>
       (value)
         ? `${acc}${index !== 0 ? '&' : ''}${key}=${value}`
         : acc
     , '');

class Results extends Component {
    state = {
      kind:null,
      isSaveAdviceModalOpen: false,
      isSaved: false,
      saveAdviceTitle: ''
    };

  componentDidMount() {
    const {fineDosage} = this.props;
    if(fineDosage===false) {
      this.fetchResult();
    }
  }

  componentWillReceiveProps(props) {
    if(props.pdfUrl!==null && this.props.pdfUrl===null) {
      setTimeout(()=> {
        if(this.state.kind==='download') {
          if(props.platform === constants.PLATFORM_DESKTOP) {
            window.open(props.pdfUrl, '_blank');
          } else {
            window.location.href = props.pdfUrl;
          }
        } else {
          this.sendEmail();
        }
        this.setState({kind: null});
      });
    }
    if(props.kitUsed !== null && this.props.kitUsed === null) {
      this.fetchResult();
    }
  }

  fetchResult = () => {
    const {result} = this.props;
    if(!result) {
      this.props.fetchResult();
    } else {
      const {x,y} = this.props.tablePos;
      this.setState({x,y});
    }
  };

  fetchPdfUrl = () => {
    const { pdfUrl, fetchPdf } = this.props;
    if(pdfUrl===null) {
      this.setState({kind:'email'}, ()=>
        fetchPdf());
    } else {
      this.sendEmail();
    }
  };

  saveFavorite = () => {
    const { application } = this.props;
    this.setState({
      ...this.state,
      isSaveAdviceModalOpen: true,
      saveAdviceTitle: application.Name
    })
  };

  getDirectUrl = () => {
    const {machine, fertiliserId} = this.props;
    const {
      WorkingWidth, ApplicationRate, applicationType, validField, shapeId,
      granuleSizeMinus2, granuleSizeB23, granuleSizeB34, granuleSize4, adviceDensity,      
      fineDosage, kitUsed, applicationId, vanesetId, DrivingSpeed
    } = this.props.currentAdvice;
    const urlBase = window.location.href.split('advice/5')[0];

    const url = `${urlBase}shared?${objectKeysToEmailQueryParams({
      machine: machine.Id,
      fertilizer: fertiliserId,
      DrivingSpeed: DrivingSpeed,
      WorkingWidth: WorkingWidth,
      ApplicationRate: ApplicationRate,
      applicationType: applicationType,
      validField,
      shapeId: shapeId.join('-'),
      distribution: `${granuleSizeMinus2}-${granuleSizeB23}-${granuleSizeB34}-${granuleSize4}`,
      density: adviceDensity ? adviceDensity : '0',
      fineDosage: fineDosage ? 'true' : 'false',
      kitUsed,
      applicationId,
      vanes: vanesetId
    })}`;

    return url;
  }

   sendEmail = () => {
     const {t} = this.props;

     const url = this.getDirectUrl();
  
     const body = `${t('email_body')}%0D%0A %0D%0A ${encodeURIComponent(url)}`;
  
     const composed_mail = `mailto:?subject=${t('email_subject')}&body=${body}`;
     window.location.href = composed_mail;
   };

  downloadPdf = ( )=> {
    const {pdfUrl, fetchPdf, platform} = this.props;
    if(pdfUrl===null) {
      this.setState({kind: 'download'}, () =>
        fetchPdf());
    } else {
      if (platform === constants.PLATFORM_DESKTOP) {
        window.open(pdfUrl, '_blank');
      } else {
        window.location.href = pdfUrl;
      }
    }
  };

  goBack = () => {
    const {match, history} = this.props;
    history.push(`/${match.params.brand}/advice/2/`);
  };

  goToPreviousStep = () => {
    const {match, history} = this.props;
    history.push(`/${match.params.brand}/advice/4/`);
  };

  updateSpeedAndFetch = () => {
    const {updateFieldWithoutReset, adviceDrivingSpeed} = this.props;
    updateFieldWithoutReset('DrivingSpeed', adviceDrivingSpeed);
    this.fetchResult();
  };

  onSubmitSaveFavorite = () => {
    this.props.favouriteStoreAdvice(this.state.saveAdviceTitle);
    this.setState({ isSaveAdviceModalOpen: false, isSaved: true });
  };

  handleSaveTitleChange = event => {
    event.preventDefault();
    this.setState({
      ...this.state,
      saveAdviceTitle: event.target.value
    })
  };
  
  historyContainsAdvice = () => {
    const {adviceHistory, currentAdvice} = this.props;
    if(!adviceHistory)
      return false;

    let matched = adviceHistory.filter((ite)=> ite.fertiliserId === currentAdvice.fertiliserId && ite.vanesetId === currentAdvice.vanesetId)
    return (matched.length===1);
  };

  render() {
    const {
      result, t, showHelp, kitUsed, fineDosage,
      machine, application, advicePossible, warningTranslationKey,
      adviceDrivingSpeed, useKit, currentAdvice, icons
    } = this.props;
    const { isSaveAdviceModalOpen } = this.state;
    
    const isSaved = this.state.isSaved || this.historyContainsAdvice();

    if (fineDosage === true && kitUsed === null) {
      return <Modal hideButton={true} onClose={() => useKit(false)}>
        <h2>{t('popup_title_attention')}</h2>
        <p>{t('popup_message_fineapplication')}</p>
        <button
          onClick={() => useKit(true)}
          className='btn-primary'
        >{t('button_yes')}</button>
        <button
          onClick={() => useKit(false)}
          className='btn-primary'
        >{t('button_no')}</button>
      </Modal>
    }

    if (advicePossible === false) {
      if (warningTranslationKey && warningTranslationKey === 'ADVICE_SPEED_OUT_OF_RANGE') {
        return <Modal hideButton={true} onClose={this.goBack.bind(this)}>
          <h2>{t('popup_title_attention')}</h2>
          <p>{t('advice_speed_out_of_range')}</p>
          <button
            onClick={this.updateSpeedAndFetch}
            className='btn-primary'
          >
            {t('advice_continue_with')} {adviceDrivingSpeed} {t('unit_speed')}
          </button>
          <button
            onClick={this.goBack.bind(this)}
            className='btn-primary'
          >
            {t('button_back')}
          </button>
        </Modal>
      }
      return <Modal hideButton={true} onClose={this.goBack.bind(this)}>
        <h2>{t('popup_title_error')}</h2>
        <p>{t('advice_no_options')}</p>
        <button
          onClick={this.goBack.bind(this)}
          className='btn-primary'
        >
          {t('button_back')}
        </button>
      </Modal>
    }

    if(!result) {
      return null;
    }

    var shareData = {
      url: this.getDirectUrl(),
      title: t('page_title_advice'),
      text: t('email_body')
    };

    var canShare = window.navigator.share;

    return <div className='container machines'>
      {
        isSaveAdviceModalOpen &&
          <InputModal
            placeholder={application.Name}
            onClose={() =>  this.setState({ isSaveAdviceModalOpen: false })}
            onChange={this.handleSaveTitleChange}
            onSubmit={this.onSubmitSaveFavorite}
            t={t}
          />
      }
        <a
          onClick={this.goToPreviousStep}
          className="machines__breadcrumb"
        >
          &lt; {t('button_back')}
        </a>
      <h2 className='advice__title' style={{ marginBottom: 36 }} >
        {t('page_title_advice')}
        <a
          className='advice__title__help'
          onClick={()=> showHelp(constants.HELP_PAGES.ADVICE)}
        ><svg viewBox="0 0 30 30"><use xlinkHref="#help" /></svg></a>
      </h2>
      <div className={"flex flex-center flex-between mb-15"}>
        <IconButton
          gaCategory="Advice"
          gaAction="Save"
          gaLabel={currentAdvice.fertiliserId}

          textColor={isSaved ? "#FFFFFF" : null}
          bgColor={isSaved ? "#FFC107" : null}
          iconName={isSaved ? 'starBigWhite' : 'starBig'}
          title={isSaved ? t('save_favorite_button') : t('save_favorite_button')}
          width={'25vw'}
          onClick={this.saveFavorite}
          disabled={isSaved}
        />
        {
          !canShare && 
            <IconButton              
              gaCategory="Advice"
              gaAction="Email"
              gaLabel={currentAdvice.fertiliserId}

              iconName={'email'}
              title={t('send_email_button')}
              width={'25vw'}
              onClick={this.fetchPdfUrl}
            />
        }
        {
          canShare && 
            <IconButton
              gaCategory="Advice"
              gaAction="Share"
              gaLabel={currentAdvice.fertiliserId}

              iconName={'share'}
              title="Share"
              width={'25vw'}
              onClick={() => navigator.share(shareData)}
            />
        }
          <IconButton
            gaCategory="Advice"
            gaAction="DownloadPdf"
            gaLabel={currentAdvice.fertiliserId}

            iconName={'file'}
            title={t('download_chart_button')}
            width={'25vw'}
            onClick={this.downloadPdf}
          />
      </div>
      <ResultOverall
        machine={machine}
        t={t}
        icons={icons}
        application={application}
        result={result}
        currentAdvice={currentAdvice}
      />
      {
        (result.Warning) && <div className="result__warning truncate-text">
          <img width={32} height={30} src={localIcons.alert} alt="" />
          <div className={'truncate-text'}>
            <div className={'result__warning__title truncate-text'}>
              {`${t('results-attention-title')}:`}
            </div>
            <div className={'result__warning__msg truncate-text'}>
              {result.Warning}
            </div>
          </div>
        </div>
      }
      <ResultAdvice machine={machine} />
      <ResultBoundary
        result={result}
        machine={machine} />
      <Advicebar currentStep={5}/>
    </div>;
  }
}

const mapStateToProps = (state) => {
  const isHistory = selectors.isHistoryAdvice(state);
  const machines = selectors.getMachines(state);
  const machineId = selectors.getCurrentMachineId(state);
  let machine = machines[machineId];
    
    /*machine = selectors.getCurrentHistoryMachine(state);*/

  let units = selectors.getCurrentUnits(state);
  return {
    field: selectors.getCurrentField(state),
    units,
    lang: selectors.getCurrentLanguageCode(state),
    result: selectors.getCurrentResult(state),
    adviceHistory: selectors.getFavourites(state, units),
    spreaderVideos: selectors.getSpreaderVideos(state),
    t: selectors.getTranslations(state),
    icons: selectors.getIcons(state),
    tablePos: selectors.getCurrentTablePos(state),
    pdfUrl: selectors.getCurrentPdf(state),
    machine,
    application: selectors.getCurrentApplication(state),
    fineDosage: selectors.getFineDosage(state),
    kitUsed: selectors.isCurrentKitUsed(state),
    isHistory,
    platform: selectors.getPlatform(state),
    advicePossible: state.currentAdvice.advicePossible,
    warningTranslationKey: state.currentAdvice.warningTranslationKey,
    adviceDrivingSpeed: state.currentAdvice.adviceDrivingSpeed,
    currentAdvice: state.currentAdvice,
    fertiliserId: selectors.getCurrentFertilizerId(state),
  }
};

const actions = {
  fetchResult,
  showHelp,
  useKit,
  fetchPdf,
  newAdvice,
  updateFieldWithoutReset,
  favouriteStoreAdvice,
};

Results = connect(mapStateToProps, actions)(Results);


export default Results;
