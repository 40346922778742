import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as utils from '../utils';
import * as selectors from '../store/selectors';
import {fetchAutosetapp} from '../store/actions/autosetapp';

import HeaderImage from '../components/headerimage';

import './scss/autosetapp.scss';


class Autosetapp extends Component {

  componentDidMount() {
    if(this.props.autosetapp===null) {
      this.props.fetchAutosetapp();
    }
    utils.bodyClassList().add('whiteBackground');
  }
  componentWillUnmount() {
    utils.bodyClassList().remove('whiteBackground');
  }

  formatDate(dt) {
    return utils.formatDate(dt);
  }

  render() {
    let {t, autosetapp, match} = this.props;

    if(autosetapp===null){
      return null;
    }

    return <div className='autosetapp'>
      <HeaderImage t={t} title="menu_autosetapp" brand={match.params.brand} />
      <div className='container autosetapp__content'>
        <div className='autosetapp__text'>
          <p dangerouslySetInnerHTML={{__html: t('autosetapp_page_text')}}></p>

          <img src={`${window['DATA'].api_url}/Images/Website/Content/AutoSetApp.jpg`} alt="" />

          <h2 dangerouslySetInnerHTML={{__html: t('update_method_list_header')}}></h2>
          <ul>
            <li dangerouslySetInnerHTML={{__html: t('update_method_list_item1')}}></li>
            <li dangerouslySetInnerHTML={{__html: t('update_method_list_item2')}}></li>
          </ul>
          <p dangerouslySetInnerHTML={{__html: t('update_method_usb')}}></p>
        </div>
        <div className='autosetapp__sidebar'>
          <div className='autosetapp__sidebar__box'>
            <h2>{t('version_title_autosetapp')}</h2>
            <ul>
              <li><strong>{t('current_version_title_autosetapp')}</strong>
                {autosetapp.DBVersion}
              </li>
              <li><strong>{t('created_title_autosetapp')}</strong>
                {this.formatDate(autosetapp.DateCreated)}
              </li>
            </ul>
          </div>
          <button
            onClick={()=> {
              let exp = /http/g;
              if(exp.exec(autosetapp.DownloadURL)!==null) {
                window.location = autosetapp.DownloadURL
              } else {
                alert(autosetapp.DownloadURL);
              }
            }}
            className='btn-primary autosetapp__button'
          >{t('download_button_autosetapp')}</button>

          {/*second button*/}
          <div>
            <p dangerouslySetInnerHTML={{__html: t('oldautosetapp_label_html')}}></p>
          </div>
          <button
            onClick={()=> {
                window.location = t('oldautosetapp_download_url')
            }}
            className='btn-primary autosetapp__button'
          >{t('oldautosetapp_button')}</button>
        </div>
      </div>
    </div>
  }
}

const mapStateToProps = (state) => {
  return {
    t: selectors.getTranslations(state),
    autosetapp: selectors.getAutosetapp(state),
  };
}
const actions = {
  fetchAutosetapp
};
Autosetapp = connect(mapStateToProps, actions)(Autosetapp);
export default Autosetapp;
