import React, {Component} from 'react';

import './scss/disclaimer.scss';


class Disclaimer extends Component {

  state = {bottomReached: false, windowHeight:null};

  componentDidMount() {
    let {scrollHeight} = this.refs.scrolling;
    let {height} = this.refs.scrolling.getBoundingClientRect();
    if(scrollHeight<=height) {
      this.setState({bottomReached: true})
    } else {
      this.refs.scrolling.addEventListener('scroll', this.trackScrolling);
    }
    document.getElementsByTagName("body")[0].classList.add('hideScroll');
    this.setState({ windowHeight: window.innerHeight })
  }
  componentWillUnmount() {
    this.refs.scrolling.removeEventListener('scroll', this.trackScrolling);
    document.getElementsByTagName("body")[0].classList.remove('hideScroll');
  }

  trackScrolling = (e) => {
    let {scrollHeight, scrollTop} = e.target;
    let {height} = e.target.getBoundingClientRect();
    let scrollable = scrollHeight-height;
    if(scrollable-10<scrollTop) {
      this.setState({bottomReached: true})
      this.refs.scrolling.removeEventListener('scroll', this.trackScrolling);
    }
  };

  onAccepted() {
    let {bottomReached} = this.state;
    if(bottomReached) {
      this.props.acceptDisclaimer();
    }
  }

  render() {
    let {t} = this.props;
    let {bottomReached, windowHeight} = this.state;
    let styles = {}
    if(windowHeight) {
      styles['height'] = windowHeight*.6;
    }
    return <div className='disclaimer'>
      <div className='disclaimer__overlay'></div>
      <div className='disclaimer__box'>
        <h2>{t('disclaimer_title')}</h2>
        <div
          ref='scrolling'
          className='disclaimer__box__scroll'
          style={styles}
        >
          <p dangerouslySetInnerHTML={{__html: t('disclaimer_body')}}></p>
        </div>
        <button
          onClick={()=> this.onAccepted()}
          className={'btn-primary' + ((bottomReached) ? '' : ' btn-primary--disabled')}
        >{t('accept_terms_button')}</button>
      </div>
    </div>
  }
}

export default Disclaimer;
